import * as React from "react";

interface IKitchenProps {
  color?: string;
  className?: string;
}

function Kitchen({ color = "currentcolor", className }: IKitchenProps): JSX.Element {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.28443 6.28284V3.92682H7.85511V6.28284H6.28443ZM6.28443 13.3509V9.4242H7.85511V13.3509H6.28443ZM4.71375 17.2776C4.28181 17.2776 3.91205 17.1238 3.60445 16.8162C3.29686 16.5086 3.14307 16.1389 3.14307 15.7069V3.14148C3.14307 2.70954 3.29686 2.33978 3.60445 2.03219C3.91205 1.7246 4.28181 1.5708 4.71375 1.5708H14.1378C14.5698 1.5708 14.9395 1.7246 15.2471 2.03219C15.5547 2.33978 15.7085 2.70954 15.7085 3.14148V15.7069C15.7085 16.1389 15.5547 16.5086 15.2471 16.8162C14.9395 17.1238 14.5698 17.2776 14.1378 17.2776H4.71375ZM4.71375 15.7069H14.1378V8.63886H4.71375V15.7069ZM4.71375 7.06818H14.1378V3.14148H4.71375V7.06818Z"
        fill={color}
      />
    </svg>
  );
}

export default Kitchen;
