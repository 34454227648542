import * as React from "react";

interface ICoffeeCupProps {
  color?: string;
  className?: string;
}

function CoffeeCup({
  color = "currentcolor",
  className,
}: ICoffeeCupProps): JSX.Element {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.42427 2.39134L3.79306 1.65377C4.05915 1.12159 4.60307 0.785431 5.19806 0.785431H13.6522C14.2472 0.785431 14.7911 1.12159 15.0572 1.65377L15.426 2.39134C16.0019 2.5152 16.4718 2.95645 16.6192 3.54611L17.0119 5.11695C17.2223 5.95859 16.7106 6.81145 15.8689 7.02186C15.7822 7.04354 15.6939 7.05776 15.6049 7.06441L14.0334 18.0646H4.81688L3.24544 7.06448C2.43248 7.00467 1.7915 6.32616 1.7915 5.49793C1.7915 5.3695 1.80726 5.24155 1.83841 5.11695L2.23111 3.54611C2.37853 2.95645 2.8484 2.5152 3.42427 2.39134ZM14.0174 7.06876H4.8328L6.17922 16.4937H12.671L14.0174 7.06876ZM3.75504 3.9271H4.41263L5.19805 2.35626H13.6522L14.4376 3.9271H15.0952L15.4879 5.49793H3.36233L3.75504 3.9271Z"
        fill={color}
      />
    </svg>
  );
}

export default CoffeeCup;
