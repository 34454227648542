import * as React from "react";

interface IVendiiSymbolProps {
  className?: string;
}

function VendiiSymbol ({ className }: IVendiiSymbolProps): JSX.Element {
  return (
    <svg
      width="33"
      height="30"
      viewBox="0 0 33 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.38975 8.14606C7.12087 6.55456 9.03896 5.90416 10.5873 6.72273C12.1929 7.57158 12.7057 9.62668 11.687 11.1303L7.42383 17.4232C6.75564 18.4095 5.46949 18.7636 4.39061 18.2583C3.18525 17.6938 2.66107 16.2626 3.2167 15.0531L6.38975 8.14606Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M19.7113 20.4481C20.8993 22.5338 20.1076 25.1891 17.9714 26.2836C15.8352 27.3782 13.2176 26.4699 12.2185 24.2874L6.26546 11.2822C5.46803 9.54013 6.18324 7.47901 7.88836 6.60533C9.59347 5.73164 11.6842 6.35501 12.6324 8.01982L19.7113 20.4481Z"
        fill="url(#paint1_linear)"
      />
      <circle cx="12.8129" cy="2.84733" r="2.84733" fill="#9AA0FA" />
      <circle cx="2.1355" cy="22.0668" r="2.1355" fill="#9AA0FA" />
      <path
        d="M19.4193 6.66713C20.9714 3.69511 24.6983 2.6322 27.5846 4.33838C30.4709 6.04456 31.3365 9.82216 29.481 12.6148L21.1167 25.2035C19.6284 27.4436 16.647 28.1267 14.3318 26.7581C12.0166 25.3896 11.1777 22.4482 12.4227 20.0643L19.4193 6.66713Z"
        fill="url(#paint2_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="10.6658"
          y1="6.76424"
          x2="4.49148"
          y2="18.4431"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#343CAA" />
          <stop offset="1" stopColor="#444CB6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="14.4154"
          y1="18.4686"
          x2="7.83207"
          y2="6.63664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A73FB" />
          <stop offset="1" stopColor="#3038A4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="27.5846"
          y1="4.33838"
          x2="14.3318"
          y2="26.7581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3B9FF" />
          <stop offset="1" stopColor="#6770FA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default VendiiSymbol;
