// extracted by mini-css-extract-plugin
var _1 = "Amount--ARZOMJQxXz";
var _2 = "ChildTextContainer--Np4a7GQ8S0";
var _3 = "DisplayText--BDA9Y8B91T";
var _4 = "ExpandFadeIn--iUZVcdrWEn";
var _5 = "HighlightedUnderline--HSPA_xRg82";
var _6 = "HorizontalExpand--wefIUxq8pl";
var _7 = "OverlaidText--gyRK5XnyFd";
var _8 = "TabButton--MEI2TmekjY";
export { _1 as "Amount", _2 as "ChildTextContainer", _3 as "DisplayText", _4 as "ExpandFadeIn", _5 as "HighlightedUnderline", _6 as "HorizontalExpand", _7 as "OverlaidText", _8 as "TabButton" }
