import * as React from "react";

interface IInboxProps {
  color?: string;
  className?: string;
}

function Inbox({ color = "currentcolor", className }: IInboxProps): JSX.Element {
  return (
    <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.5 19C22.5 20.1046 21.6046 21 20.5 21H4.5C3.39543 21 2.5 20.1046 2.5 19V11.7947L2.58085 11.6061L5.60236 4.55926C6.05303 3.64142 6.62144 3.07301 7.5 3H17.5L17.6308 3.00859C18.3837 3.10794 18.9351 3.65935 19.4191 4.60608L22.5 11.7947V19ZM4.5 13V19H20.5V13H16.5C16.5 14.1046 15.6046 15 14.5 15H10.5C9.39543 15 8.5 14.1046 8.5 13H4.5ZM5.01654 11H10.5V13H14.5V11H19.984L17.6107 5.45729C17.4987 5.23944 17.3953 5.08654 17.3118 4.99993L7.67592 4.99623C7.5987 5.07946 7.50333 5.22529 7.41915 5.39392L5.01654 11Z" fill={color}/>
    </svg>

  );
}

export default Inbox;
