import * as React from "react";
import { Menu, MenuItem } from "@mui/material";
import { clsx } from "clsx";
import { useTranslation } from "react-i18next";
import { useIsTechnician } from "utils/user-role";
import { ActivateMachineConfirmationModal } from "./ActivateMachineConfirmationModal";
import { DeactivateMachineConfirmationModal } from "./DeactivateMachineConfirmationModal";
import { RestartMachineConfirmationModal } from "./RestartMachineConfirmationModal";
import { SelfTestConfirmationModal } from "./SelfTestConfirmationModal";
import Icon from "../icon/Icon";

export function MachineActionDropdown({
  machineId,
  iconClassName,
}: {
  machineId: string;
  iconClassName?: string;
}) {
  const { t } = useTranslation();

  const isTechnician = useIsTechnician();

  const [selfTestModalOpen, setSelfTestModalOpen] = React.useState(false);
  const [restartModalOpen, setRestartModalOpen] = React.useState(false);
  const [activateMachineOpen, setActivateMachineOpen] = React.useState(false);
  const [deactiveMachineOpen, setDeactivateMachineOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <SelfTestConfirmationModal
        isOpen={selfTestModalOpen}
        setIsOpen={setSelfTestModalOpen}
        machineId={machineId}
      />
      <RestartMachineConfirmationModal
        isOpen={restartModalOpen}
        setIsOpen={setRestartModalOpen}
        machineId={machineId}
      />
      <ActivateMachineConfirmationModal
        isOpen={activateMachineOpen}
        setIsOpen={setActivateMachineOpen}
        machineId={machineId}
      />
      <DeactivateMachineConfirmationModal
        isOpen={deactiveMachineOpen}
        setIsOpen={setDeactivateMachineOpen}
        machineId={machineId}
      />

      <button
        className={clsx(
          " cursor-pointer flex items-center justify-center group rounded hover:bg-primary-50/70 transition-colors p-1",
          open ? "bg-primary-50/70" : "bg-transparent"
        )}
        onClick={handleClick}
      >
        <Icon
          name="Settings"
          color="currentColor"
          className={clsx(
            "group-hover:rotate-180 transition-transform duration-300 text-[#828282]",
            iconClassName,
            open ? "rotate-180" : ""
          )}
        />
      </button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            setSelfTestModalOpen(true);
            setAnchorEl(null);
          }}
        >
          <div className="font-kanit flex items-center gap-[5px] w-full cursor-pointer text-primary-500">
            <Icon
              name="RefreshAlert"
              color="primary500"
              className="shrink-0 size-6"
            />
            <span className="text-button uppercase">
              {t("label_self_test")}
            </span>
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setActivateMachineOpen(true);
            setAnchorEl(null);
          }}
          disabled={isTechnician}
        >
          <div className="font-kanit flex items-center gap-[5px] w-full cursor-pointer text-primary-500">
            <Icon name="Play" color="primary500" className="shrink-0 size-6" />
            <span className="text-button uppercase">
              {t("label_activate_machine")}
            </span>
          </div>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setDeactivateMachineOpen(true);
            setAnchorEl(null);
          }}
          disabled={isTechnician}
        >
          <div className="font-kanit flex items-center gap-[5px] w-full cursor-pointer text-primary-500">
            <Icon name="Pause" color="primary500" className="shrink-0 size-6" />
            <span className="text-button uppercase">
              {t("label_deactivate_machine")}
            </span>
          </div>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setRestartModalOpen(true);
            setAnchorEl(null);
          }}
        >
          <div className="font-kanit flex items-center gap-[5px] w-full cursor-pointer text-primary-500">
            <Icon
              name="RotateCW"
              color="primary500"
              className="shrink-0 size-6"
            />
            <span className="text-button uppercase">
              {t("label_restart_machine")}
            </span>
          </div>
        </MenuItem>
      </Menu>
    </>
  );
}
