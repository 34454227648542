import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceOverviewDocument,
  GetMaintenanceOverviewQuery,
  GetMaintenanceOverviewQueryVariables,
} from "gql/generated";

export async function getErrorOverviews({
  serviceZoneIds,
  refillZoneIds,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
}) {
  return fetcher<
    GetMaintenanceOverviewQuery,
    GetMaintenanceOverviewQueryVariables
  >(GetMaintenanceOverviewDocument, {
    filter: {
      serviceZoneIds,
      refillZoneIds,
    },
  })();
}
