import * as React from "react";

interface ISlidersProps {
  color?: string,
  className?: string
}

function Sliders ({
  color = "currentcolor",
  className
}: ISlidersProps): JSX.Element {
  return (
    <svg className={className} width='24' height='26' viewBox='0 0 24 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M4 22.5V15' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M4 10.7143V3.21429' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M12 22.5V12.8571' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M12 8.57144V3.21429' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M20 22.5V17.1429' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M20 12.8572V3.21429' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M1 15H7' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M9 8.57144H15' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M17 17.1429H23' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  );
}

export default Sliders;
