import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardNoTransactionTodayDocument,
  GetMaintenanceBoardNoTransactionTodayQuery,
  GetMaintenanceBoardNoTransactionTodayQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";


export async function getNoTransactionToday({
  serviceZoneIds, refillZoneIds, cursor, sort
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardNoTransactionTodayQuery,
    GetMaintenanceBoardNoTransactionTodayQueryVariables
  >(GetMaintenanceBoardNoTransactionTodayDocument, {
    after: cursor,
    refillZoneIds: refillZoneIds,
    serviceZoneIds: serviceZoneIds,
    sort: sort
  })();
}
