import * as React from "react";

interface ISnackProps {
  color?: string,
  className?: string
}

function Snack ({
  color = "currentcolor",
  className
}: ISnackProps): JSX.Element {
  return (
    <svg className={className} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0820313 1.70703C0.105469 3.88672 0.121094 3.96484 0.71875 5.16406L0.96875 5.66406V10V14.3359L0.71875 14.8359C0.121094 16.0352 0.105469 16.1133 0.0820313 18.293L0.0625 20H8H15.9375L15.918 18.2891C15.8945 16.1133 15.8789 16.0352 15.2813 14.8359L15.0313 14.3359V10.0039V5.67188L15.3242 5.07031C15.875 3.95312 15.8906 3.85156 15.918 1.70703L15.9375 0H8H0.0625L0.0820313 1.70703ZM14.7188 1.75781V2.34375H8H1.28125V1.75781V1.17188H8H14.7188V1.75781ZM14.6563 3.62109C14.6016 3.85547 14.4414 4.22656 14.1563 4.79297L13.8594 5.39062V7.83203V10.2695L13.5352 10.5469C12.5586 11.3906 11.2305 12.0586 9.95313 12.3594C9.23828 12.5273 8.92578 12.5586 8 12.5586C7.08594 12.5586 6.78516 12.5273 6.06641 12.3633C4.78906 12.0664 3.44922 11.3906 2.46484 10.5469L2.14063 10.2695V7.83203V5.39062L1.84375 4.79297C1.55859 4.22656 1.39844 3.85547 1.34375 3.62109L1.32031 3.51562H8H14.6797L14.6563 3.62109ZM3.20703 12.5C4.16797 13.0508 5.25 13.4336 6.41797 13.6367C7.08594 13.7539 8.49219 13.7812 9.17188 13.6953C10.8008 13.4805 12.3047 12.9062 13.5391 12.0273L13.8594 11.7969V13.2031V14.6094L14.1563 15.207C14.4414 15.7734 14.6016 16.1445 14.6563 16.375L14.6797 16.4844H8H1.32031L1.34375 16.375C1.39844 16.1445 1.55859 15.7734 1.84375 15.207L2.14063 14.6094V13.2031V11.7969L2.46484 12.0273C2.64063 12.1523 2.97656 12.3633 3.20703 12.5ZM4.95313 18.2422V18.8281H3.11719H1.28125V18.2422V17.6562H3.11719H4.95313V18.2422ZM9.83594 18.2422V18.8281H8H6.16406V18.2422V17.6562H8H9.83594V18.2422ZM14.7188 18.2422V18.8281H12.8828H11.0469V18.2422V17.6562H12.8828H14.7188V18.2422Z" fill={color}/>
      <path d="M4.85935 6.29297C4.16404 6.45703 3.54295 7.09766 3.37107 7.82031C3.19529 8.56641 3.40623 9.21094 4.03123 9.82422C4.7656 10.5547 5.76951 11.082 6.86716 11.3164C7.33982 11.418 8.66013 11.418 9.13279 11.3164C9.59373 11.2188 10.1992 11.0117 10.5664 10.8242C11.3672 10.4258 12.207 9.71094 12.4492 9.23047C12.8633 8.39844 12.7187 7.47656 12.0664 6.83594C11.6445 6.41797 11.2304 6.25 10.6367 6.25C10.0625 6.25 9.67576 6.40234 9.21873 6.80859C8.76951 7.21094 8.28904 7.37109 7.75779 7.29688C7.42185 7.25 7.09373 7.08594 6.77341 6.80078C6.48826 6.54688 6.14841 6.35938 5.85154 6.29297C5.6406 6.24219 5.07029 6.24609 4.85935 6.29297ZM5.70701 7.47656C5.77732 7.50391 5.95701 7.63672 6.10545 7.76953C6.6406 8.24219 7.27341 8.48047 7.99998 8.48047C8.73435 8.48047 9.35154 8.24609 9.91013 7.75781C10.2422 7.46094 10.3789 7.40625 10.7148 7.42969C11.0195 7.44922 11.2304 7.57812 11.3828 7.83594C11.6406 8.27734 11.5234 8.69141 11.0117 9.12891C10.4531 9.60547 9.87107 9.91016 9.1367 10.1094C8.79685 10.1992 8.69138 10.2109 8.01951 10.2109C7.19138 10.2148 6.8867 10.1641 6.25779 9.90625C5.53904 9.61328 4.74998 9.02734 4.5781 8.65625C4.30076 8.0625 4.7031 7.42188 5.35545 7.42188C5.48045 7.42188 5.6367 7.44531 5.70701 7.47656Z" fill={color} />
    </svg>

  );
}

export default Snack;
