import * as React from "react";
import { ErrorBoundary } from "@sentry/react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { CO2OutTable } from "./CO2OutTable";
import { CoffeeBeansTable } from "./CoffeeBeansTable";
import { EspressoCupsTable } from "./EspressoCupsTable";
import { LidsTable } from "./LidsTable";
import { MilkTable } from "./MilkTable";
import { OtherPowderTable } from "./OtherPowderTable";
import { OtherSyrupTable } from "./OtherSyrupTable";
import { OtherTable } from "./OtherTable";
import { P10Table } from "./P10Table";
import { P11Table } from "./P11Table";
import { P1Table } from "./P1Table";
import { P4Table } from "./P4Table";
import { P5Table } from "./P5Table";
import { PaperCupsTable } from "./PaperCupsTable";
import { PlasticCupsTable } from "./PlasticCupsTable";
import { S2Table } from "./S2Table";
import { S3Table } from "./S3Table";
import { S5Table } from "./S5Table";
import { SM1Table } from "./SM1Table";
import { SM2Table } from "./SM2Table";
import { SugarTable } from "./SugarTable";
import { routes } from "../../../../routes";
import { Fallback } from "../../components/Fallback";
import { useSelectedZones } from "../../providers/SelectedZoneProvider";

export function InventoryTables() {
  const { serviceZoneIds, refillZoneIds } = useSelectedZones();
  const key = serviceZoneIds?.join("") + refillZoneIds?.join("");

  return (
    <Routes>
      <Route
        path="/milk"
        element={
          <ErrorBoundary fallback={Fallback} key={"milk" + key}>
            <MilkTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/sugar"
        element={
          <ErrorBoundary fallback={Fallback} key={"sugar" + key}>
            <SugarTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/coffee-beans"
        element={
          <ErrorBoundary fallback={Fallback} key={"coffee-beans" + key}>
            <CoffeeBeansTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/plastic-cups"
        element={
          <ErrorBoundary fallback={Fallback} key={"plastic-cups" + key}>
            <PlasticCupsTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/espresso-cups"
        element={
          <ErrorBoundary fallback={Fallback} key={"espresso-cups" + key}>
            <EspressoCupsTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/paper-cups"
        element={
          <ErrorBoundary fallback={Fallback} key={"paper-cups" + key}>
            <PaperCupsTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/lid"
        element={
          <ErrorBoundary fallback={Fallback} key={"lid" + key}>
            <LidsTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/co2"
        element={
          <ErrorBoundary fallback={Fallback} key={"co2" + key}>
            <CO2OutTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/p1"
        element={
          <ErrorBoundary fallback={Fallback} key={"p1" + key}>
            <P1Table />
          </ErrorBoundary>
        }
      />
      <Route
        path="/p4"
        element={
          <ErrorBoundary fallback={Fallback} key={"p4" + key}>
            <P4Table />
          </ErrorBoundary>
        }
      />
      <Route
        path="/p5"
        element={
          <ErrorBoundary fallback={Fallback} key={"p5" + key}>
            <P5Table />
          </ErrorBoundary>
        }
      />
      <Route
        path="/p10"
        element={
          <ErrorBoundary fallback={Fallback} key={"p10" + key}>
            <P10Table />
          </ErrorBoundary>
        }
      />
      <Route
        path="/p11"
        element={
          <ErrorBoundary fallback={Fallback} key={"p11" + key}>
            <P11Table />
          </ErrorBoundary>
        }
      />
      <Route
        path="/s2"
        element={
          <ErrorBoundary fallback={Fallback} key={"s2" + key}>
            <S2Table />
          </ErrorBoundary>
        }
      />
      <Route
        path="/s3"
        element={
          <ErrorBoundary fallback={Fallback} key={"s3" + key}>
            <S3Table />
          </ErrorBoundary>
        }
      />
      <Route
        path="/s5"
        element={
          <ErrorBoundary fallback={Fallback} key={"s5" + key}>
            <S5Table />
          </ErrorBoundary>
        }
      />
      <Route
        path="/sm1"
        element={
          <ErrorBoundary fallback={Fallback} key={"sm1" + key}>
            <SM1Table />
          </ErrorBoundary>
        }
      />
      <Route
        path="/sm2"
        element={
          <ErrorBoundary fallback={Fallback} key={"sm2" + key}>
            <SM2Table />
          </ErrorBoundary>
        }
      />
      <Route
        path="/other-powder"
        element={
          <ErrorBoundary fallback={Fallback} key={"other-powder" + key}>
            <OtherPowderTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/other-syrup"
        element={
          <ErrorBoundary fallback={Fallback} key={"other-syrup" + key}>
            <OtherSyrupTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="/other"
        element={
          <ErrorBoundary fallback={Fallback} key={"other" + key}>
            <OtherTable />
          </ErrorBoundary>
        }
      />
      <Route
        path="*"
        element={<Navigate to={routes.inventory.milk} replace />}
      />
    </Routes>
  );
}
