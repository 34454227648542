import * as React from "react";

interface IThumbsUpProps {
  className?: string;
  style?: React.CSSProperties;
  color?: string;
}

function ThumbsUp({ style, className, color }: IThumbsUpProps) {
  return (
    <svg
      className={className}
      style={style}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 11.999C22.5 10.3165 21.3696 8.99902 19.5 8.99902H16.427L16.4548 8.9233C16.4826 8.84822 16.5607 8.64219 16.6367 8.44192L16.637 8.44116L16.637 8.4411L16.6371 8.44079C16.7044 8.26347 16.7699 8.09087 16.7971 8.01793C16.9872 7.50758 17.126 7.09638 17.2382 6.68915C17.4079 6.07366 17.5 5.51914 17.5 4.99902C17.5 3.52702 16.5919 2.61274 15.2483 2.22151C14.6423 2.04505 14.1148 1.99893 13.5 1.99902H13.0205L12.7203 2.37291C12.3811 2.79526 11.715 3.55645 10.947 4.43403L10.947 4.43403L10.947 4.43405C9.53024 6.05296 7.7669 8.06793 7.07035 9.08242C6.88961 9.02874 6.69817 8.99992 6.5 8.99992H4.5C3.39543 8.99992 2.5 9.89535 2.5 10.9999V19.9999C2.5 21.1045 3.39543 21.9999 4.5 21.9999H6.5C7.18712 21.9999 7.79331 21.6534 8.15337 21.1256C8.45674 21.3218 8.79522 21.4856 9.16312 21.6172C10.0054 21.9186 10.8408 22.0111 11.5344 21.9984L18.5 21.999C21.2551 21.999 22.5 17.9426 22.5 11.999ZM9.83688 19.7341C8.95197 19.4175 8.5 18.9013 8.5 17.999V10.9999V10.999C8.5 10.7588 8.54443 10.5461 8.64171 10.319C8.81645 9.91102 10.4019 8.10216 11.9047 6.3877L11.9048 6.38753C12.6803 5.50275 13.4338 4.64311 13.96 4.01415C14.2114 4.03301 14.4484 4.07165 14.6891 4.14176C15.2622 4.30862 15.5 4.54805 15.5 4.99902C15.5 5.31561 15.4359 5.70134 15.3101 6.15763C15.2161 6.4986 15.0944 6.85939 14.9229 7.31969C14.8986 7.38488 14.8381 7.54443 14.7737 7.71425L14.7733 7.7153L14.7731 7.71565L14.7729 7.7162C14.6943 7.92334 14.6101 8.14527 14.579 8.22957C14.4489 8.58119 14.3633 8.84009 14.3047 9.07534C14.0555 10.0754 14.3055 10.999 15.5 10.999H19.5C20.1912 10.999 20.5 11.3589 20.5 11.999C20.5 16.7898 19.5151 19.999 18.5 19.999H11.5L11.4631 19.9996C11.0254 20.0075 10.4106 19.9394 9.83688 19.7341ZM4.5 19.9999V10.9999H6.5V19.9999H4.5Z"
        fill={color}
      />
    </svg>
  );
}

export default ThumbsUp;

