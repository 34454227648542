import * as React from "react";

interface IInfoOutlineProps {
  color?: string;
  className?: string;
}

function InfoOutline({
  color = "white",
  className,
}: IInfoOutlineProps): JSX.Element {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M4.5 7.5H5.5V4.5H4.5V7.5ZM5 3.5C5.14167 3.5 5.26042 3.45208 5.35625 3.35625C5.45208 3.26042 5.5 3.14167 5.5 3C5.5 2.85833 5.45208 2.73958 5.35625 2.64375C5.26042 2.54792 5.14167 2.5 5 2.5C4.85833 2.5 4.73958 2.54792 4.64375 2.64375C4.54792 2.73958 4.5 2.85833 4.5 3C4.5 3.14167 4.54792 3.26042 4.64375 3.35625C4.73958 3.45208 4.85833 3.5 5 3.5ZM5 10C4.30833 10 3.65833 9.86875 3.05 9.60625C2.44167 9.34375 1.9125 8.9875 1.4625 8.5375C1.0125 8.0875 0.65625 7.55833 0.39375 6.95C0.13125 6.34167 0 5.69167 0 5C0 4.30833 0.13125 3.65833 0.39375 3.05C0.65625 2.44167 1.0125 1.9125 1.4625 1.4625C1.9125 1.0125 2.44167 0.65625 3.05 0.39375C3.65833 0.13125 4.30833 0 5 0C5.69167 0 6.34167 0.13125 6.95 0.39375C7.55833 0.65625 8.0875 1.0125 8.5375 1.4625C8.9875 1.9125 9.34375 2.44167 9.60625 3.05C9.86875 3.65833 10 4.30833 10 5C10 5.69167 9.86875 6.34167 9.60625 6.95C9.34375 7.55833 8.9875 8.0875 8.5375 8.5375C8.0875 8.9875 7.55833 9.34375 6.95 9.60625C6.34167 9.86875 5.69167 10 5 10Z"
        fill={color}
      />
    </svg>
  );
}

export default InfoOutline;
