import * as React from "react";

interface ISpeedometerProps {
  color?: string,
  className?: string
}

function Speedometer ({
  color = "currentcolor",
  className
}: ISpeedometerProps): JSX.Element {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path fillRule="evenodd" clipRule="evenodd" d="M5.89835 21.7995C3.15046 19.735 1.5 16.5037 1.5 13C1.5 6.92487 6.42487 2 12.5 2C18.5751 2 23.5 6.92487 23.5 13C23.5 16.5037 21.8495 19.735 19.1017 21.7995L18.8348 22H6.16522L5.89835 21.7995ZM18.1575 20C20.253 18.305 21.5 15.757 21.5 13C21.5 8.02944 17.4706 4 12.5 4C7.52944 4 3.5 8.02944 3.5 13C3.5 15.757 4.74698 18.305 6.84253 20H18.1575ZM17.8192 7.57346L16.1808 6.42654L13.5477 10.188C13.2216 10.0665 12.8685 10 12.5 10C10.8431 10 9.5 11.3431 9.5 13C9.5 14.6569 10.8431 16 12.5 16C14.1569 16 15.5 14.6569 15.5 13C15.5 12.4445 15.349 11.9243 15.0859 11.4782L17.8192 7.57346ZM13.5 13C13.5 13.5523 13.0523 14 12.5 14C11.9477 14 11.5 13.5523 11.5 13C11.5 12.4477 11.9477 12 12.5 12C13.0523 12 13.5 12.4477 13.5 13Z" fill={color}/>
    </svg>
  );
}

export default Speedometer;
