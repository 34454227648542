import * as React from "react";
import { format } from "date-fns";
import { RealtimeFailBoardEventType } from "gql/generated";
import { Trans, useTranslation } from "react-i18next";
import { Event } from "./useRealTimeQuery";

export function EventStatus({ event }: { event: Event }) {
  const { t, i18n } = useTranslation("maintenance", {
    keyPrefix: "rtFail",
  });

  switch (event.type) {
    case RealtimeFailBoardEventType.Online: {
      return (
        <div className="text-subtitle2 leading-5 text-on-surface-high">
          {t("eventType.online")}
        </div>
      );
    }

    case RealtimeFailBoardEventType.MaintenanceExit: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-on-surface-high">
            {t("eventType.maintenanceExit")}
          </div>

          <div className="text-body2 leading-4 text-on-surface-high mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.ActiveCommand: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-success">
            {t("eventType.activeCommand")}
          </div>

          <div className="text-body2 leading-4 text-success mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.DeactiveCommand: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-error">
            {t("eventType.deactiveCommand")}
          </div>

          <div className="text-body2 leading-4 text-error mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.Open:
    case RealtimeFailBoardEventType.ArriveBy:
    case RealtimeFailBoardEventType.OpenRefill:
    case RealtimeFailBoardEventType.OpenService:
    case RealtimeFailBoardEventType.OpenTank: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-primary-500">
            {t("eventType.arriveBy")}
          </div>

          <div className="text-body2 leading-4 text-primary-500 mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.Information: {
      return !event.description ? (
        <div className="text-subtitle2 leading-5 text-on-surface-high">
          {t("eventType.information")}
        </div>
      ) : (
        <div className="text-body2 leading-5 text-on-surface-high">
          {event.description}
        </div>
      );
    }

    case RealtimeFailBoardEventType.Alarm: {
      let description =
        i18n.language === "th"
          ? event.errorDetail?.descriptionTh
          : event.errorDetail?.descriptionEn;
      if (!description) {
        description = event.errorDetail?.raw;
      }

      const lastRefillInfoExist =
        event.slotDetail?.lastRefillBy || event.slotDetail?.lastRefillTimestamp;

      return (
        <>
          <div className="text-subtitle2 leading-5 text-error">
            {t("eventType.alarm")} {`(${event.errorCode})`}{" "}
            <span className="text-body2">{description}</span>
          </div>

          {event.slotDetail?.lastRemain ||
          event.slotDetail?.lastRemain === 0 ? (
            <div className="text-caption text-on-surface-disabled leading-4">
              <Trans
                i18nKey="rtFail.slotRemaining"
                ns="maintenance"
                values={{
                  remaining: "" + event.slotDetail.lastRemain.toFixed(2) + " g",
                }}
                components={{
                  bold: <span className="text-caption text-on-surface-high" />,
                }}
              />
            </div>
          ) : null}

          {event.slotDetail?.usageSinceLastRefill ||
          event.slotDetail?.usageSinceLastRefill === 0 ? (
            <div className="text-caption text-on-surface-disabled leading-4">
              <Trans
                i18nKey="rtFail.slotUsage"
                ns="maintenance"
                values={{
                  usage:
                    "" +
                    event.slotDetail.usageSinceLastRefill.toFixed(2) +
                    " g",
                }}
                components={{
                  bold: <span className="text-caption text-on-surface-high" />,
                }}
              />
            </div>
          ) : null}

          {lastRefillInfoExist ? (
            <div className="text-caption text-on-surface-disabled leading-4">
              {t("lastRefill")}

              {event.slotDetail?.lastRefillBy ? (
                <>
                  {" "}
                  {t("by")}{" "}
                  <span className="text-caption text-on-surface-high">
                    {event.slotDetail.lastRefillBy}
                  </span>
                </>
              ) : null}

              {event.slotDetail?.lastRefillTimestamp ? (
                <>
                  {" "}
                  {t("on")}{" "}
                  <span className="text-caption text-on-surface-high">
                    {format(
                      new Date(event.slotDetail?.lastRefillTimestamp),
                      "dd/MM/yyyy"
                    )}
                  </span>
                </>
              ) : null}
          </div>
          ) : null}
        </>
      );
    }
    default: {
      return (
        <div className="text-subtitle2 leading-5 text-on-surface-high">
          {String(event.type)}
        </div>
      );
    }
  }
}
