import * as React from "react";
import Icon from "common/components/icon/Icon";
import Typography from "components/Typography";

import * as styles from "./NoData.module.scss";

export function NoData({
  className = "",
  text,
}: {
  className?: string;
  text: string;
}) {
  return (
    <div className={[styles.NoData, className].join(" ")}>
      <div className={styles.TextContainer}>
        <Icon
          name="ThumbsUp"
          color="onSurfaceDisabled"
          style={{ animationDelay: "150ms", animationFillMode: "backwards" }}
          className={styles.FadeInMoveUp}
        />

        <Typography
          type="headline-6"
          color="onSurfaceDisabled"
          className={styles.FadeInMoveUp}
        >
          {text}
        </Typography>
      </div>
    </div>
  );
}
