import * as React from "react";
import { clsx } from "clsx";
import { Route, Routes, Navigate } from "react-router-dom";

import * as styles from "./ErrorBoard.module.scss";
import { ErrorBoardTabs } from "./ErrorBoardNav";
import { ErrorOverviews } from "./ErrorOverviews";
import { LastUpdatedIndicator } from "./LastUpdatedIndicator";
import { SelectedZonesProvider } from "./providers/SelectedZoneProvider";
import { RefillZoneDropDown } from "./RefillZoneDropDown";
import { RefreshButton } from "./RefreshButton";
import { SelectedZoneChips } from "./SelectedZoneChips";
import { routes } from "../../routes";
import { ErrorBoardProvider } from "./providers/ErrorBoardProvider";
import { ServiceZoneDropDown } from "./ServiceZoneDropDown";
import { InventoryView } from "./views/InventoryView";
import { MajorTroubleView } from "./views/MajorTroubleView";
import { TransactionsView } from "./views/TransactionsView";

export function ErrorBoard({ className }: { className?: string }) {
  return (
    <SelectedZonesProvider>
      <ErrorBoardProvider>
        <div className={clsx(styles.ErrorBoardContainer, className)}>
          <div className="overflow-auto hide-scroll-bar flex -mx-4 px-4 md:-mx-10 md:px-10">
            <div className="grow">
              <div className="flex justify-between gap-2.5 mt-2 md:mt-4">
                <div className="grid [grid-template-columns:210px_210px] gap-2 md:gap-4 grow">
                  <ServiceZoneDropDown />
                  <RefillZoneDropDown />
                </div>

                <div className="items-center gap-1 shrink-0 flex">
                  <LastUpdatedIndicator />
                  <RefreshButton />
                </div>
              </div>

              <div className="mt-1 md:mt-2">
                <SelectedZoneChips />
              </div>
            </div>
          </div>

          <div className="mt-1 md:mt-2">
            <ErrorOverviews />
          </div>

          <div className={clsx(styles.ErrorBoard, "mt-2 md:mt-4")}>
            <div>
              <ErrorBoardTabs />
            </div>

            <div className={styles.SubNavAndTableContainer}>
              <Routes>
                <Route path="/major-trouble/*" element={<MajorTroubleView />} />
                <Route path="/inventory/*" element={<InventoryView />} />
                <Route path="/transactions/*" element={<TransactionsView />} />
                <Route
                  path="*"
                  element={<Navigate to={routes.majorTrouble.root} replace />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </ErrorBoardProvider>
    </SelectedZonesProvider>
  );
}
