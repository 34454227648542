import { fetcher } from "gql/fetcher";
import { GetMaintenanceBoardP4ErrorDocument, GetMaintenanceBoardP4ErrorQuery, GetMaintenanceBoardP4ErrorQueryVariables, MaintenanceBoardSortInput } from "gql/generated";

export async function getP4({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardP4ErrorQuery,
    GetMaintenanceBoardP4ErrorQueryVariables
  >(GetMaintenanceBoardP4ErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
