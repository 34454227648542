import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardOtherPowderErrorDocument,
  GetMaintenanceBoardOtherPowderErrorQuery,
  GetMaintenanceBoardOtherPowderErrorQueryVariables,
  MaintenanceBoardSortInput,
} from "gql/generated";

export async function getOtherPowderError({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardOtherPowderErrorQuery,
    GetMaintenanceBoardOtherPowderErrorQueryVariables
  >(GetMaintenanceBoardOtherPowderErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
