// extracted by mini-css-extract-plugin
var _1 = "Typography--BsilXo6rXy";
var _2 = "body-1--FceJOvOquM";
var _3 = "body-2--qzJWxcrfEU";
var _4 = "button--oXh0fsFgRH";
var _5 = "caption--DdKjltdDhx";
var _6 = "headline-1--Kj38SRPYbz";
var _7 = "headline-2--OsxiwLtNLG";
var _8 = "headline-3--rv_i8pyZP6";
var _9 = "headline-4--_R5tajhjyT";
var _a = "headline-5--qeOiUZHGW4";
var _b = "headline-6--a5Lv_emtSh";
var _c = "overline--hqIibWYz0s";
var _d = "subtitle-1--Pb0N2SPJVg";
var _e = "subtitle-2--ErtzinlWbu";
export { _1 as "Typography", _2 as "body-1", _3 as "body-2", _4 as "button", _5 as "caption", _6 as "headline-1", _7 as "headline-2", _8 as "headline-3", _9 as "headline-4", _a as "headline-5", _b as "headline-6", _c as "overline", _d as "subtitle-1", _e as "subtitle-2" }
