import * as React from "react";

interface IFilterProps {
  color?: string,
  className?: string
}

function Filter ({
  color = "currentcolor",
  className
}: IFilterProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z' fill={color} />
    </svg>
  );
}

export default Filter;
