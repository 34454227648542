/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { Skeleton, CircularProgress } from "@mui/material";
import Icon from "common/components/icon/Icon";
import SideNavButton from "common/components/sidenavbutton/SideNavButton";
import Button from "components/Button";
import { Fallback } from "components/Fallback";
import Typography from "components/Typography";
import { useLocation } from "react-router-dom";

import * as styles from "./Components.module.scss";


export function Container({ children }: { children: React.ReactNode }) {
  return <div className={styles.Container}>{children}</div>;
}

export function Header({ children }: { children: React.ReactNode }) {
  return <div className={styles.Header}>{children}</div>;
}

export function TextButtonsContainer({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className={styles.TextButtonsContainer}>{children}</div>;
}

interface OperationTableProps<T, S extends string> {
  data: Array<T> | undefined;
  isLoading: boolean;
  isSelected?: (dataPoint: T) => boolean;
  getKey: (dataPoint: T) => string;
  columns: Array<{
    id: S;
    style: React.CSSProperties;
    headerCell: (columnId: S) => JSX.Element;
    cell: (dataPoint: T) => JSX.Element;
  }>;
  tableDataRowClassName?: string;
  tableDataRowSelectedClassName?: string;
}
export function OperationTable<T, S extends string>({
  isLoading,
  data,
  isSelected = () => false,
  getKey,
  columns,
  tableDataRowClassName = styles.TableRow,
  tableDataRowSelectedClassName = styles.TableRowSelected,
}: OperationTableProps<T, S>): JSX.Element {
  const noData = !isLoading && Array.isArray(data) && data?.length === 0;

  return (
    <div className={styles.TableWrapper}>
      <div
        className={styles.TableScroll}
        style={{
          height: noData ? "100%" : undefined,
        }}
      >
        <div className={styles.TableContainer}>
          <div className={styles.TableHead}>
            <div className={styles.TableRow}>
              {columns.map((column) => (
                <div style={column.style} key={column.id}>
                  {column.headerCell(column.id)}
                </div>
              ))}
            </div>
          </div>

          {isLoading ? (
            new Array(10).fill(null).map((_, index) => (
              <div key={index} className={styles.TableRow}>
                {columns.map((column) => (
                  <div key={column.id} style={column.style}>
                    <div
                      style={{
                        width: "100%",
                        padding: "3px",
                      }}
                    >
                      <Skeleton width={"100%"} />
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : noData ? (
            <div className={styles.TableNoData}>
              <div className={styles.Info}>
                <Icon name="Critical" color="disabled" />
                <Typography type="body-2" translate color="disabled">
                  label_no_data
                </Typography>
              </div>
            </div>
          ) : (
            <div className={styles.TableBody}>
              {data.map((element) => (
                <div
                  key={getKey(element)}
                  className={[
                    styles.TableRow,
                    tableDataRowClassName,
                    isSelected(element) ? styles.TableRowSelected : "",
                    isSelected(element) ? tableDataRowSelectedClassName : "",
                  ]
                    .filter(Boolean)
                    .join(" ")}
                >
                  {columns.map((column) => (
                    <div style={column.style} key={column.id}>
                      {column.cell(element)}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const RefillPriority = {
  Urgent: "URGENT",
  Overdue: "OVERDUE",
  VeryHigh: "VERY_HIGH",
  High: "HIGH",
  Medium: "MEDIUM",
  Low: "LOW",
  Lowest: "LOWEST",
} as const;

export type RefillPriorityString =
  (typeof RefillPriority)[keyof typeof RefillPriority];
export const refillPriorityValueMap: Record<RefillPriorityString, number> = {
  LOWEST: 0,
  LOW: 1,
  MEDIUM: 2,
  HIGH: 3,
  VERY_HIGH: 4,
  URGENT: 5,
  OVERDUE: 6,
};

interface Props {
  priority: RefillPriorityString;
}

export function PriorityCell({ priority }: Props) {
  return (
    <div className={styles.PriorityContainer}>
      <div className={styles.PriorityIcon}>
        <Icon name={iconNames[priority] as any} />
      </div>

      <Typography type="body-2" translate color="onSurfaceHigh">
        {translationKeys[priority]}
      </Typography>
    </div>
  );
}

const iconNames: Record<RefillPriorityString, string> = {
  URGENT: "PriorityUrgent",
  VERY_HIGH: "PriorityVeryHigh",
  HIGH: "PriorityHigh",
  MEDIUM: "PriorityMedium",
  LOW: "PriorityLow",
  OVERDUE: "PriorityOverdue",
  LOWEST: "PriorityLowest",
};

const translationKeys: Record<RefillPriorityString, string> = {
  URGENT: "label_urgent",
  VERY_HIGH: "label_highest",
  HIGH: "label_high",
  MEDIUM: "label_medium",
  LOW: "label_low",
  OVERDUE: "label_overdue",
  LOWEST: "label_lowest",
};

export function isValidRefillPriority(
  unknownPriority: unknown
): unknownPriority is RefillPriorityString {
  const validPriorities = Object.values(RefillPriority);
  return (
    typeof unknownPriority === "string" &&
    validPriorities.includes(unknownPriority as RefillPriorityString)
  );
}

export function warnIfUnknownPriority(
  unknownString: string | undefined | null
) {
  if (
    unknownString !== undefined &&
    unknownString !== null &&
    !isValidRefillPriority(unknownString)
  ) {
    console.warn(`Unknown priority of ${unknownString}`);
  }
  return unknownString;
}

const InventoryStatus = {
  Available: "AVAILABLE",
  Disable: "DISABLE",
  Error: "ERROR",
} as const;

export type InventoryStatusString =
  (typeof InventoryStatus)[keyof typeof InventoryStatus];

export const STATUS_COLOR_MAP: Record<InventoryStatusString, string> = {
  AVAILABLE: "onSurfaceHigh",
  DISABLE: "onSurfaceDisabled",
  ERROR: "error",
};
export const STATUS_TRANSLATION_KEY_MAP: Record<InventoryStatusString, string> =
  {
    AVAILABLE: "status_available",
    DISABLE: "status_disable",
    ERROR: "status_error",
  };
export function InventoryStatusCell({
  status,
}: {
  status: InventoryStatusString | null;
}) {
  return (
    <Typography type="body-2" translate color={STATUS_COLOR_MAP[status]}>
      {STATUS_TRANSLATION_KEY_MAP[status]}
    </Typography>
  );
}

export function LoadingScreen(): JSX.Element {
  return (
    <div className={styles.LoadingIndicatorContainer}>
      <CircularProgress size={50} className={styles.LoadingIndicator} />
    </div>
  );
}

export function ModalFallback({
  error,
  metaData,
  onClose,
}: {
  error: Error;
  metaData: string;
  onClose: () => void;
}) {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#ffd8d4",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          maxWidth: "80%",
        }}
      >
        <Typography color="error" translate type="headline-6">
          label_an_error_has_occured
        </Typography>

        <div style={{ marginTop: "-5px" }}>
          <Typography color="error" translate type="caption">
            {metaData}
          </Typography>
        </div>

        <div style={{ marginTop: "4px", lineHeight: "0.9" }}>
          <Typography color="error" translate type="caption">
            {error.message}
          </Typography>
        </div>

        <div style={{ marginTop: "20px" }}>
          <Button type="status" onClick={onClose}>
            label_close_window
          </Button>
        </div>
      </div>
    </div>
  );
}

export function FallBackWrapper({ error }: { error: Error }) {
  return (
    <div
      style={{
        marginLeft: "-35px",
        marginRight: "-35px",
        flex: "1 1 0",
        marginBottom: "-20px",
      }}
    >
      <Fallback error={error} />
    </div>
  );
}

export function NewOperationSideNav() {
  const { pathname } = useLocation();

  return (
    <SideNavButton
      to="/new-operations"
      translationKey="workspace_operations"
      icon="Truck"
      active={pathname.includes("/new-operations")}
    />
  );
}

