import * as React from "react";

interface ITransferProps {
  color?: string,
  className?: string
}

function Transfer ({
  color = "currentcolor",
  className
}: ITransferProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M6 22.0001C7.30622 22.0001 8.41746 21.1653 8.82929 20.0001H17.5C19.9853 20.0001 22 17.9854 22 15.5001C22 13.0148 19.9853 11.0001 17.5 11.0001H6.5C5.11929 11.0001 4 9.88079 4 8.50008C4 7.11936 5.11929 6.00008 6.5 6.00008H9.58579L8.29289 7.29297L9.70711 8.70718L13.4142 5.00008L9.70711 1.29297L8.29289 2.70718L9.58579 4.00008H6.5C4.01472 4.00008 2 6.01479 2 8.50008C2 10.9854 4.01472 13.0001 6.5 13.0001H17.5C18.8807 13.0001 20 14.1194 20 15.5001C20 16.8808 18.8807 18.0001 17.5 18.0001H8.82929C8.41746 16.8349 7.30622 16.0001 6 16.0001C4.34315 16.0001 3 17.3432 3 19.0001C3 20.6569 4.34315 22.0001 6 22.0001ZM5 19.0001C5 18.4478 5.44772 18.0001 6 18.0001C6.55228 18.0001 7 18.4478 7 19.0001C7 19.5524 6.55228 20.0001 6 20.0001C5.44772 20.0001 5 19.5524 5 19.0001ZM18 2.00008C19.6569 2.00008 21 3.34322 21 5.00008C21 6.65693 19.6569 8.00008 18 8.00008C16.3431 8.00008 15 6.65693 15 5.00008C15 3.34322 16.3431 2.00008 18 2.00008ZM17 5.00008C17 4.44779 17.4477 4.00008 18 4.00008C18.5523 4.00008 19 4.44779 19 5.00008C19 5.55236 18.5523 6.00008 18 6.00008C17.4477 6.00008 17 5.55236 17 5.00008Z' fill={color} />
    </svg>
  );
}

export default Transfer;
