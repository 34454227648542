import { httpClient } from "common/services/transportService";
import {
  IUser,
  IUsers,
  IInviteUser,
  IPatchUser,
  IOrganisation,
  IPatchOrganisation,
  IPatchUserRole,
} from "userandauth/interfacesUser";

export async function getUser(userId: string): Promise<IUser> {
  const response = await httpClient.get<IUser>(`/users/${userId}`);
  return response.data;
}

export async function getAllUsers(): Promise<IUsers[]> {
  const response = await httpClient.get<IUsers[]>(`/users`);
  return response.data;
}

export async function deleteUser(userId: string): Promise<string> {
  const response = await httpClient.delete<string>(`/users/${userId}`);
  return response.data;
}

export async function inviteUser(newUserData: IInviteUser): Promise<string> {
  const response = await httpClient.post<string>("/users", newUserData);
  return response.data;
}

export async function patchUser(
  newUserData: IPatchUser,
  userId: string
): Promise<string> {
  const response = await httpClient.patch<string>(
    `/users/${userId}`,
    newUserData
  );
  return response.data;
}

export async function patchUserRole(
  newUserData: IPatchUserRole,
  userId: string
): Promise<string> {
  const response = await httpClient.patch<string>(
    `/users/changeRole/${userId}`,
    newUserData
  );
  return response.data;
}

export async function getAllOrganisation(): Promise<IOrganisation[]> {
  const response = await httpClient.get<IOrganisation[]>("/organisation");
  return response.data;
}

export async function patchOrganisation(
  newOrgData: IPatchOrganisation,
  orgId: string
): Promise<IOrganisation> {
  const response = await httpClient.patch<IOrganisation>(
    `/organisation/${orgId}`,
    newOrgData
  );
  return response.data;
}
