import { fetcher } from "gql/fetcher";
import { GetMaintenanceBoardSm2ErrorDocument, GetMaintenanceBoardSm2ErrorQuery, GetMaintenanceBoardSm2ErrorQueryVariables, MaintenanceBoardSortInput } from "gql/generated";

export async function getSM2({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardSm2ErrorQuery,
    GetMaintenanceBoardSm2ErrorQueryVariables
  >(GetMaintenanceBoardSm2ErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
