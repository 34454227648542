import * as React from "react";

interface IRightCircularChevronProps {
  color?: string;
  className?: string;
}

function RightCircularChevron({
  color = "currentcolor",
  className,
}: IRightCircularChevronProps): JSX.Element {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.99996 15.5236C3.94987 15.5236 0.666626 12.2404 0.666626 8.19027C0.666626 4.14018 3.94987 0.856934 7.99996 0.856934C12.05 0.856934 15.3333 4.14018 15.3333 8.19027C15.3333 12.2404 12.05 15.5236 7.99996 15.5236ZM8 14.1904C11.3137 14.1904 14 11.5041 14 8.19043C14 4.87672 11.3137 2.19043 8 2.19043C4.68629 2.19043 2 4.87672 2 8.19043C2 11.5041 4.68629 14.1904 8 14.1904ZM7.13804 5.05241L6.19523 5.99522L8.39049 8.19048L6.19523 10.3857L7.13804 11.3286L10.2761 8.19048L7.13804 5.05241Z"
        fill={color}
      />
    </svg>
  );
}

export default RightCircularChevron;
