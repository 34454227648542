import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { IOrganisation } from "userandauth/interfacesUser";
import { getAllOrganisation } from "userandauth/servicesUser";

import { userKeys } from "./userKeys";

function useGetOrganisations({ enabled = true } = {}) {
  return useQuery<IOrganisation[], AxiosError>(
    userKeys.allOrganisation(),
    () => getAllOrganisation(),
    {
      staleTime: Infinity,
      retry: 0,
      enabled,
    }
  );
}

export { useGetOrganisations };
