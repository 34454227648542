import { IAction } from "common/interfaces";
import { IMachineInventoryTemplate } from "machines/interfaces";

interface IMachineInventoryTemplatesState {
  [templateId: string]: Partial<IMachineInventoryTemplate>
}

const initialState: IMachineInventoryTemplatesState = {};

function machineInventoryTemplatesReducer (
  state = initialState,
  action: IAction
): IMachineInventoryTemplatesState {
  const [ domain, request, result ] = action.type.split("/");

  if (domain === "MACHINE_INVENTORY_TEMPLATES") {
    if (request.includes("GET_") && result === "SUCCESS") {
      return { ...state, ...action.payload };
    }
  }

  return state;
}

export default machineInventoryTemplatesReducer;
