import * as React from "react";

interface IRefreshProps {
  color?: string;
  className?: string;
}

function Refresh({
  color = "currentcolor",
  className,
  ...rest
}: IRefreshProps): JSX.Element {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 7H6.19306C7.55354 5.0787 9.60638 4 12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2C9.19577 2 6.72511 3.18251 5 5.27035V2H3V9H10V7ZM14 17H17.8069C16.4465 18.9213 14.3936 20 12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22C14.8042 22 17.2749 20.8175 19 18.7297V22H21V15H14V17Z"
        fill={color}
      />
    </svg>
  );
}

export default Refresh;