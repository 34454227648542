import * as React from "react";

interface IFolderOpenProps {
  color?: string;
  className?: string;
}

function FolderOpen({
  color = "currentcolor",
  className,
}: IFolderOpenProps): JSX.Element {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 5C20.1046 5 21 5.89543 21 7V8C22.1046 8 23 8.89543 23 10L22.9762 10.2169L20.996 19.1279C20.93 20.1729 20.0616 21 19 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3H9C10.12 3 10.8329 3.47545 11.549 4.37885C11.5688 4.40385 11.6171 4.46608 11.6706 4.535L11.6707 4.53515C11.7461 4.63236 11.8318 4.74283 11.8625 4.78082C11.8935 4.81925 11.9196 4.85103 11.9412 4.8773L11.9412 4.87732C12.0292 4.98428 12.0419 4.99978 12.0018 5H19ZM19 7V8H5C3.8334 8 3.3102 8.62146 3.02913 9.76041L3 9.89146V5H9C9.38424 5 9.60702 5.14857 9.9816 5.62116C9.99336 5.636 10.0271 5.67944 10.0691 5.73357L10.0692 5.73375L10.0692 5.73376C10.1496 5.83737 10.2603 5.98006 10.3058 6.03654C10.8087 6.65991 11.2765 6.99614 11.9946 6.99999L19 7ZM19 19H3.02439L4.97619 10.2169C4.99992 10.1213 5.02068 10.0496 5.03631 10H20.9756L19.0238 18.7831L19 19Z"
        fill={color}
      />
    </svg>
  );
}

export default FolderOpen;
