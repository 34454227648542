import * as React from "react";
import { useTranslation } from "react-i18next";

import { routes } from "../../../../routes";
import { ScrollContainer } from "../../components/ScrollContainer";
import { TabButton } from "../../components/TabButton";
import { useErrorBoard } from "../../hooks/useErrorBoard";

export function InventorySubNav() {
  return (
    <ScrollContainer>
      <InventoryTabs />
    </ScrollContainer>
  );
}

export function InventoryTabs() {
  
  const { t } = useTranslation("maintenance");

  const { state } = useErrorBoard();
  const inventorySummary = state.data.summary?.inventory;

  return (
    <>
      <TabButton
        path={routes.inventory.plasticCups}
        activePath={routes.inventory.plasticCups}
        amount={inventorySummary?.plasticCups ?? 0}
      >
        {t("inventoryTabs.plasticCups")}
      </TabButton>

      <TabButton
        path={routes.inventory.paperCups}
        activePath={routes.inventory.paperCups}
        amount={inventorySummary?.paperCups ?? 0}
      >
        {t("inventoryTabs.paperCups")}
      </TabButton>

      <TabButton
        path={routes.inventory.espressoCups}
        activePath={routes.inventory.espressoCups}
        amount={inventorySummary?.espressoCups ?? 0}
      >
        {t("inventoryTabs.espressoCups")}
      </TabButton>

      <TabButton
        path={routes.inventory.milk}
        activePath={routes.inventory.milk}
        amount={inventorySummary?.milk ?? 0}
      >
        {t("inventoryTabs.milk")}
      </TabButton>

      <TabButton
        path={routes.inventory.sugar}
        activePath={routes.inventory.sugar}
        amount={inventorySummary?.sugar ?? 0}
      >
        {t("inventoryTabs.sugar")}
      </TabButton>

      <TabButton
        path={routes.inventory.p1}
        activePath={routes.inventory.p1}
        amount={inventorySummary?.p1 ?? 0}
      >
        {t("inventoryTabs.p1")}
      </TabButton>

      <TabButton
        path={routes.inventory.p4}
        activePath={routes.inventory.p4}
        amount={inventorySummary?.p4 ?? 0}
      >
        {t("inventoryTabs.p4")}
      </TabButton>
    
      <TabButton
        path={routes.inventory.p5}
        activePath={routes.inventory.p5}
        amount={inventorySummary?.p5 ?? 0}
      >
        {t("inventoryTabs.p5")}
      </TabButton>

      <TabButton
        path={routes.inventory.p10}
        activePath={routes.inventory.p10}
        amount={inventorySummary?.p10 ?? 0}
      >
        {t("inventoryTabs.p10")}
      </TabButton>

      <TabButton
        path={routes.inventory.p11}
        activePath={routes.inventory.p11}
        amount={inventorySummary?.p11 ?? 0}
      >
        {t("inventoryTabs.p11")}
      </TabButton>


      <TabButton
        path={routes.inventory.otherPowder}
        activePath={routes.inventory.otherPowder}
        amount={inventorySummary?.otherPowder ?? 0}
      >
        {t("inventoryTabs.otherPowder")}
      </TabButton>

      <TabButton
        path={routes.inventory.coffeeBeans}
        activePath={routes.inventory.coffeeBeans}
        amount={inventorySummary?.coffeeBeans ?? 0}
      >
        {t("inventoryTabs.coffeeBeans")}
      </TabButton>

      <TabButton
        path={routes.inventory.s2}
        activePath={routes.inventory.s2}
        amount={inventorySummary?.s2 ?? 0}
      >
        {t("inventoryTabs.s2")}
      </TabButton>

      <TabButton
        path={routes.inventory.s3}
        activePath={routes.inventory.s3}
        amount={inventorySummary?.s3 ?? 0}
      >
        {t("inventoryTabs.s3")}
      </TabButton>

      <TabButton
        path={routes.inventory.s5}
        activePath={routes.inventory.s5}
        amount={inventorySummary?.s5 ?? 0}
      >
        {t("inventoryTabs.s5")}
      </TabButton>

      <TabButton
        path={routes.inventory.sm1}
        activePath={routes.inventory.sm1}
        amount={inventorySummary?.sm1 ?? 0}
      >
        {t("inventoryTabs.sm1")}
      </TabButton>

      <TabButton
        path={routes.inventory.sm2}
        activePath={routes.inventory.sm2}
        amount={inventorySummary?.sm2 ?? 0}
      >
        {t("inventoryTabs.sm2")}
      </TabButton>

      <TabButton
        path={routes.inventory.otherSyrup}
        activePath={routes.inventory.otherSyrup}
        amount={inventorySummary?.otherSyrup ?? 0}
      >
        {t("inventoryTabs.otherSyrup")}
      </TabButton>

      <TabButton
        path={routes.inventory.co2}
        activePath={routes.inventory.co2}
        amount={inventorySummary?.co2Out ?? 0}
      >
        {"CO"}<sub>{"2"}</sub>
      </TabButton>

      <TabButton
        path={routes.inventory.lid}
        activePath={routes.inventory.lid}
        amount={inventorySummary?.lid ?? 0}
      >
        {t("inventoryTabs.lids")}
      </TabButton>

      <TabButton
        path={routes.inventory.other}
        activePath={routes.inventory.other}
        amount={inventorySummary?.other ?? 0}
      >
        {t("inventoryTabs.other")}
      </TabButton>
    </>
  );
}
