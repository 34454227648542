import {
  getPermission,
  setPermissionCache,
  useGetOwnPermission,
} from "hooks/permission";
import { useTranslation } from "react-i18next";

const contractFeaturePermission = {
  action: "MANAGE",
  resourceType: "CONTRACT",
} as const;

export function useCanAccessContractFeature({
  useErrorBoundary = true,
}: {
  useErrorBoundary?: boolean;
} = {}) {
  const permissionResult = useGetOwnPermission({
    action: "MANAGE",
    resourceType: "CONTRACT",
  }, {
    useErrorBoundary: useErrorBoundary,
  });
  const { t } = useTranslation();

  if (!useErrorBoundary) {
    return {
      isLoading: permissionResult.isLoading,
      allowed: permissionResult.allowed,
    };
  }

  const isDataLoaded = !permissionResult.isLoading;
  const isNotAllowed = !permissionResult.allowed;
  if (isDataLoaded && isNotAllowed) {
    throw new Error(t("label_no_permission_to_view_this_page"));
  }
  return permissionResult;
}

export async function prefetchCanAccessContractFeaturePermission(
  userId: string
) {
  try {
    const result = await getPermission({
      userId,
      action: contractFeaturePermission.action,
      resourceType: contractFeaturePermission.resourceType,
    });

    setPermissionCache({
      userId,
      action: contractFeaturePermission.action,
      resourceType: contractFeaturePermission.resourceType,
      allowed: result,
    });
  } catch { /* empty */ }
}
