import * as React from "react";

interface IDownloadProps {
  color?: string;
  className?: string;
}

function Download ({ color = "currentcolor", className }: IDownloadProps): JSX.Element {
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7 7V4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V7V11H22L12 19.5847L2 11H7V7ZM15 4V7V11V13H16.2967L12 16.9505L7.70326 13H9V11V7V4H15ZM19 22C20.1046 22 21 21.1046 21 20H3C3 21.1046 3.89543 22 5 22H19Z'
        fill={color}
      />
    </svg>
  );
}

export default Download;
