/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import withSuspense from "common/hoc/withSuspense";
import { useTranslation } from "react-i18next";


import * as styles from "./Typography.module.scss";

type ITypographyType = "headline-1" | "headline-2" | "headline-3" | "headline-4" | "headline-5" | "headline-6" | "subtitle-1" | "subtitle-2" | "body-1" | "body-2" | "button" | "caption" | "overline";

interface ITypographyProps {
  translationKey?: string | string[],
  text?: string | number,
  type: ITypographyType,
  className?: string
}

function Typography ({
  translationKey,
  text,
  type,
  className
}: ITypographyProps): JSX.Element {
  const { t } = useTranslation();

  let textToDisplay: string;
  if (translationKey === undefined) {
    textToDisplay = text as any;
  } else if (Array.isArray(translationKey)) {
    textToDisplay = translationKey?.map(key => key.includes("_") ? t(key) : key).join("");
  } else if (typeof translationKey === "string"){
    textToDisplay = translationKey?.includes("_") ? t(translationKey) : translationKey;
  } else {
    textToDisplay = translationKey;
  }

  return (
    <span
      className={[
        styles.Typography,
        styles[type],
        className
      ].join(" ")}
    >
      {textToDisplay}
    </span>
  );
}

export default React.memo(withSuspense(Typography));
