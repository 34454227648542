import * as React from "react";

interface IReactionsNeutralProps {
  color?: string;
  className?: string;
}

function ReactionsNeutral({
  color = "currentcolor",
  className,
}: IReactionsNeutralProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.99996 15.3334C3.94987 15.3334 0.666626 12.0502 0.666626 8.00008C0.666626 3.94999 3.94987 0.666748 7.99996 0.666748C12.05 0.666748 15.3333 3.94999 15.3333 8.00008C15.3333 12.0502 12.05 15.3334 7.99996 15.3334ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM5.33333 9.33333V10.6667H10.6667V9.33333H5.33333ZM9.33333 7.33333V6H11.3333V7.33333H9.33333ZM4.66667 6V7.33333H6.66667V6H4.66667Z"
        fill={color}
      />
    </svg>
  );
}

export default ReactionsNeutral;
