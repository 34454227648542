import * as React from "react";
import { Skeleton, Tooltip } from "@mui/material";
import { clsx } from "clsx";
import Icon from "common/components/icon/Icon";
import { useTranslation } from "react-i18next";

import { Board } from "./useRealTimeQuery";

export function FrequentErrorAccordion({
  board,
  title,
  isLoading,
}: {
  board: Board;
  title: string;
  isLoading;
}) {
  const [isOpen, setIsOpen] = React.useState(true);

  const { t } = useTranslation("maintenance", {
    keyPrefix: "rtFail",
  });

  return (
    <button
      onClick={() => setIsOpen(!isOpen)}
      className="rounded bg-primary-600 border border-outline p-2 font-kanit cursor-pointer"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-[6px]">
          <span className="text-h6 leading-6 font-normal text-on-primary-high">
            {title}
          </span>
          {isLoading ? (
            <Skeleton
              width={20}
              height={20}
              style={{
                backgroundColor: "rgba(129, 145, 217, 0.4)",
                borderRadius: "100%",
                transform: "scale(1,1)",
              }}
            />
          ) : board?.machines && board.machines.length > 0 ? (
            <span
              key={board.machines.length}
              className="size-5 text-overline tracking-[0.15px] bg-error text-white flex items-center justify-center rounded-full animate-fade-in-move-up"
            >
              {board?.machines.length}
            </span>
          ) : null}
        </div>
        <Icon
          name="DropDownChevron"
          style={{
            transition: "transform 0.2s ease-in-out",
            transform: isOpen ? "rotate(180deg)" : null,
            transformOrigin: "center",
          }}
        />
      </div>

      <div
        className={clsx(
          "grid overflow-hidden transition-all ease-out duration-150",
          isOpen ? "[grid-template-rows:1fr]" : "[grid-template-rows:0fr]"
        )}
      >
        <div
          className={clsx(
            "min-h-0 transition-all w-full min-w-0",
            isOpen ? "visible" : "invisible"
          )}
        >
          <div className="flex mt-2 gap-1 items-center">
            <span className="text-body3 font-medium leading-4 text-on-primary-high">
              {t("frequentError")}
            </span>

            <Tooltip
              placement="right"
              arrow={true}
              slotProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "rgba(0,0,0,0.6)",
                  },
                },
                arrow: {
                  sx: {
                    color: "rgba(0,0,0,0.6)",
                  },
                },
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [10, 0],
                      },
                    },
                  ],
                },
              }}
              title={
                <div className="font-kanit text-overline  leading-4 tracking-[0.4px] text-on-primary-high max-w-48">
                  {t("frequentErrorDescription")}
                </div>
              }
            >
              <div className="flex items-center justify-center">
                <Icon name="InfoOutline" color="onPrimaryMedium" />
              </div>
            </Tooltip>
          </div>

          <div className="whitespace-nowrap overflow-auto flex hide-scroll-bar text-subtitle1 leading-6 text-on-primary-high -mt-px">
            {isLoading ? (
              <Skeleton
                width={180}
                style={{
                  backgroundColor: "rgba(129, 145, 217, 0.4)",
                }}
              />
            ) : (
              <span
                className="animate-fade-in"
                key={
                  board?.frequentErrors[0]
                    ? board.frequentErrors[0].errorCode +
                      board.frequentErrors[0].count
                    : undefined
                }
              >
                {!board?.frequentErrors || board.frequentErrors.length === 0
                  ? "-"
                  : board?.frequentErrors
                      .map((error) => `${error.errorCode} (${error.count})`)
                      .join(", ")}
              </span>
            )}
          </div>
        </div>
      </div>
    </button>
  );
}
