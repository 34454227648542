import { IAction } from "common/interfaces";

interface ILastRequestState {
  [key: string]: number
}

const initialState: ILastRequestState = {};

function lastRequestReducer (
  state: ILastRequestState = initialState,
  action: IAction
): ILastRequestState {
  const [ domain, request, result ] = action.type.split("/");

  if (result === "SUCCESS") {
    return {
      ...state,
      [`${domain}/${request}`]: Date.now()
    };
  }

  return state;
}

export default lastRequestReducer;
