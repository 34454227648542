import * as React from "react";

interface ICheckProps {
  color?: string;
  className?: string;
}

function Check({
  color = "currentcolor",
  className,
  ...rest
}: ICheckProps): JSX.Element {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={color}
      width="18px"
      height="18px"
      {...rest}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
    </svg>
  );
}

export default Check;