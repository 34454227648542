import * as React from "react";

interface IErrorProps {
  color?: string;
  className?: string;
}

function Error ({ color = "currentcolor", className }: IErrorProps): JSX.Element {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd" 
        d="M8.16675 11.5H9.83342V13.1667H8.16675V11.5ZM8.16675 4.83332H9.83342V9.83332H8.16675V4.83332ZM8.99175 0.666656C4.39175 0.666656 0.666748 4.39999 0.666748 8.99999C0.666748 13.6 4.39175 17.3333 8.99175 17.3333C13.6001 17.3333 17.3334 13.6 17.3334 8.99999C17.3334 4.39999 13.6001 0.666656 8.99175 0.666656ZM9.00008 15.6667C5.31675 15.6667 2.33341 12.6833 2.33341 8.99999C2.33341 5.31666 5.31675 2.33332 9.00008 2.33332C12.6834 2.33332 15.6667 5.31666 15.6667 8.99999C15.6667 12.6833 12.6834 15.6667 9.00008 15.6667Z" 
        fill={color}
      />
    </svg>
  );
}




export default Error;
