// extracted by mini-css-extract-plugin
var _1 = "Body_1--p96JsnerWx";
var _2 = "Body_2--k0HG2FgG0j";
var _3 = "Body_3--p95WpAhRiE";
var _4 = "Button--Amdz9uP2bX";
var _5 = "Caption--HTwsXVRvKX";
var _6 = "Emphasized--sg0b4WJ41t";
var _7 = "Headline_1--h8LVbSchu5";
var _8 = "Headline_2--wgiU_ZCvs8";
var _9 = "Headline_3--UiwBQSmbDQ";
var _a = "Headline_4--PTOCaPHomm";
var _b = "Headline_5--COm4lGJsvN";
var _c = "Headline_6--sdu4Du_d8S";
var _d = "Overline--QNN5aVDSjm";
var _e = "Subtitle_1--LUvRnp9x_c";
var _f = "Subtitle_2--br0PsUHHNg";
export { _1 as "Body_1", _2 as "Body_2", _3 as "Body_3", _4 as "Button", _5 as "Caption", _6 as "Emphasized", _7 as "Headline_1", _8 as "Headline_2", _9 as "Headline_3", _a as "Headline_4", _b as "Headline_5", _c as "Headline_6", _d as "Overline", _e as "Subtitle_1", _f as "Subtitle_2" }
