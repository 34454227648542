import * as React from "react";

interface IReverseProps {
  color?: string,
  className?: string
}

function Reverse ({
  color = "currentcolor",
  className
}: IReverseProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M18.5858 10.0001L14.2929 5.70718L15.7071 4.29297L22.4142 11.0001L15.7071 17.7072L14.2929 16.293L18.5858 12.0001H8C5.79086 12.0001 4 13.7909 4 16.0001C4 18.2092 5.79086 20.0001 8 20.0001H10.5V22.0001H8C4.68629 22.0001 2 19.3138 2 16.0001C2 12.6864 4.68629 10.0001 8 10.0001H18.5858Z' fill={color} />
    </svg>
  );
}

export default Reverse;
