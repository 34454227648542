import * as React from "react";

interface IBuildingProps {
  color?: string;
  className?: string;
}

function Building({ color = "currentcolor", className }: IBuildingProps): JSX.Element {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Vector">
        <path
          id="Vector_2"
          d="M8.5001 5.39998V0.599976H2.5001V2.99998H0.100098V11.4H4.9001V8.99998H6.1001V11.4H10.9001V5.39998H8.5001ZM2.5001 10.2H1.3001V8.99998H2.5001V10.2ZM2.5001 7.79998H1.3001V6.59998H2.5001V7.79998ZM2.5001 5.39998H1.3001V4.19998H2.5001V5.39998ZM4.9001 7.79998H3.7001V6.59998H4.9001V7.79998ZM4.9001 5.39998H3.7001V4.19998H4.9001V5.39998ZM4.9001 2.99998H3.7001V1.79998H4.9001V2.99998ZM7.3001 7.79998H6.1001V6.59998H7.3001V7.79998ZM7.3001 5.39998H6.1001V4.19998H7.3001V5.39998ZM7.3001 2.99998H6.1001V1.79998H7.3001V2.99998ZM9.7001 10.2H8.5001V8.99998H9.7001V10.2ZM9.7001 7.79998H8.5001V6.59998H9.7001V7.79998Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default Building;
