import * as React from "react";

interface IHardwareVersionProps {
  color?: string;
  className?: string;
}

function HardwareVersion({
  color = "currentcolor",
  className,
}: IHardwareVersionProps): JSX.Element {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.14331 4.71234C3.14331 3.84479 3.8466 3.14151 4.71414 3.14151H14.1391C15.0067 3.14151 15.71 3.8448 15.71 4.71234V14.1373C15.71 15.0049 15.0067 15.7082 14.1391 15.7082H4.71414C3.84659 15.7082 3.14331 15.0049 3.14331 14.1373V4.71234Z"
        stroke={color}
        strokeWidth="1.57083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.07031 7.0686H11.7828V11.7811H7.07031V7.0686Z"
        stroke={color}
        strokeWidth="1.57083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.07031 0.785278V3.14153"
        stroke={color}
        strokeWidth="1.57083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7827 0.785278V3.14153"
        stroke={color}
        strokeWidth="1.57083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.07031 15.7082V18.0644"
        stroke={color}
        strokeWidth="1.57083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7827 15.7082V18.0644"
        stroke={color}
        strokeWidth="1.57083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.71 7.0686H18.0662"
        stroke={color}
        strokeWidth="1.57083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.71 10.9957H18.0662"
        stroke={color}
        strokeWidth="1.57083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.786865 7.0686H3.14312"
        stroke={color}
        strokeWidth="1.57083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.786865 10.9957H3.14312"
        stroke={color}
        strokeWidth="1.57083"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default HardwareVersion;
