import { httpClient } from "common/services/transportService";
import { prefetchCanAccessContractFeaturePermission } from "containers/contract/hooks/useCanAccessContractFeature";
import { queryClient } from "index";
import { unstable_batchedUpdates } from "react-dom";
import analytics from "utils/analytics";
import { updateDefaultTimezone } from "utils/date";
import { setSentryUser } from "utils/sentryUserTracking";
import { userTracking } from "utils/userTracking";

import { userKeys } from "./hooks/userKeys";
import { IAuthEndPoint } from "./interfacesAuth";
import {
  IPostLogInWithGoogle,
  IPostLogInWithPassword,
  IPostSignUpWithGoogle,
  IPostSignUpWithPassword,
  postLoginWithGoogle,
  postLoginWithPassword,
  postLogOut,
  postRefreshToken,
  postSignUpWithGoogle,
  postSignUpWithPassword
} from "./servicesAuth";
import { useAuthStore } from "./useAuthStore";



export const ERROR_FAILED_TO_REFRESH_TOKEN = "Failed to refresh token";
/**
 * Note: Will not throw
 */
export async function refreshToken () {
  try {
    const { accessToken, userInfo } = await postRefreshToken();
    useAuthStore.getState().setAccessToken(accessToken); 
    await prefetchCanAccessContractFeaturePermission(userInfo.userId);
    queryClient.setQueryData(userKeys.user(userInfo.userId), userInfo);
    useAuthStore.getState().logIn(userInfo.userId);
    updateDefaultTimezone(userInfo.timezone);
    userTracking(userInfo);
    setSentryUser(userInfo);
    return accessToken;
  } catch {
    throw new Error(ERROR_FAILED_TO_REFRESH_TOKEN);
  }
}

export async function login (loginParams: ILoginWithGoogle | ILoginWithPassword) {
  let authData: IAuthEndPoint | undefined;
  if (loginParams.loginWith === "google") {
    authData = await postLoginWithGoogle(loginParams.postBody);
  } else if (loginParams.loginWith === "password") {
    authData = await postLoginWithPassword(loginParams.postBody);
  }
  const { accessToken, expiresIn, userInfo } = authData;

  useAuthStore.getState().setAccessToken(accessToken); 
  await prefetchCanAccessContractFeaturePermission(userInfo.userId);
  queryClient.setQueryData(userKeys.user(userInfo.userId), userInfo);
  useAuthStore.getState().logIn(userInfo.userId);
  updateDefaultTimezone(userInfo.timezone);
  return { accessToken, expiresIn, userInfo };
}

interface ILoginWithGoogle {
  loginWith: "google";
  postBody: IPostLogInWithGoogle;
}

interface ILoginWithPassword {
  loginWith: "password";
  postBody: IPostLogInWithPassword;
}

interface ISignupWithGoogle {
  loginWith: "google";
  postBody: IPostSignUpWithGoogle;
}

interface ISignupWithPassword {
  loginWith: "password";
  postBody: IPostSignUpWithPassword;
}

export async function signup (signupParams: ISignupWithGoogle | ISignupWithPassword) {
  if (signupParams.loginWith === "google") {
    await postSignUpWithGoogle(signupParams.postBody);
  } else if (signupParams.loginWith === "password") {
    await postSignUpWithPassword(signupParams.postBody);
  }
}

/**
 * Clears
 * 1. Auth store - isLoggedIn false and sets userId to null
 * 2. Http client - Remove Authorization Beaerer <token>
 * 3. React query store - Removes all cache data
 *
 * Note : Can throw
 */
export async function logout () {
  await postLogOut();
  unstable_batchedUpdates(() => {
    useAuthStore.getState().logOut(); // Batch update otherwise TopNav useGetCurrentUser rerender first
  });
  delete httpClient.defaults.headers.common["Authorization"];
  queryClient.removeQueries();
  analytics.reset(); // Reset the Amplitude user ID and set a new user ID again once users log in.
}
