import { IAction } from "common/interfaces";
import { omit } from "lodash";
import { IMachineTransactionData } from "machines/interfaces";

interface IMachineTransactionState {
  [machineId: string]: IMachineTransactionData[]
}

const initialState: IMachineTransactionState = {};

function machineTransactionReducer (
  state: IMachineTransactionState = initialState,
  action: IAction
): IMachineTransactionState {
  const [ domain, request, result ] = action.type.split("/");

  if (domain === "MACHINE_TRANSACTION") {
    if (request.includes("GET_") && result === "SUCCESS") {
      const machineIds = Object.keys(action.payload);
      const newState = { ...state };

      for (const machineId of machineIds) {
        newState[machineId] = {
          ...newState[machineId],
          ...action.payload[machineId]
        };
      }

      return newState;
    }
  }

  if (action.type === "MACHINES/DELETE/SUCCESS") {
    return omit(state, [action.payload.id]);
  }

  return state;
}

export default machineTransactionReducer;
