import { fetcher } from "gql/fetcher";
import { 
  GetMaintenanceBoardCo2OutErrorDocument, 
  GetMaintenanceBoardCo2OutErrorQuery, 
  GetMaintenanceBoardCo2OutErrorQueryVariables, 
  MaintenanceBoardSortInput } from "gql/generated";

export async function getCO2OutError({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardCo2OutErrorQuery,
    GetMaintenanceBoardCo2OutErrorQueryVariables
  >(GetMaintenanceBoardCo2OutErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
