import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardCoffeeMaterialErrorDocument,
  GetMaintenanceBoardCoffeeMaterialErrorQuery,
  GetMaintenanceBoardCoffeeMaterialErrorQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";


export async function getCoffeeBeans({
  serviceZoneIds, refillZoneIds, cursor, sort
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardCoffeeMaterialErrorQuery,
    GetMaintenanceBoardCoffeeMaterialErrorQueryVariables
  >(GetMaintenanceBoardCoffeeMaterialErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
