import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardLidErrorDocument,
  GetMaintenanceBoardLidErrorQuery,
  GetMaintenanceBoardLidErrorQueryVariables,
  MaintenanceBoardSortInput,
} from "gql/generated";

export async function getLidError({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardLidErrorQuery,
    GetMaintenanceBoardLidErrorQueryVariables
  >(GetMaintenanceBoardLidErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
