/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import analytics from "utils/analytics";

export function AnalyticClickElementTracking () {
  React.useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      let target = event.target as HTMLElement | null;
      while (target) {
        
        const trackEventName = target.getAttribute("data-tracking-event");
        
        if (trackEventName) {
          const eventProperties = {};
          for (const attr of target.attributes as any) {
            // Check if the attribute name starts with the specified prefix
            if (attr.name.startsWith("data-tracking-property-")) {
              // Extract the property name by removing the prefix
              const propName = attr.name.replace("data-tracking-property-", "");
              // Add the property to the properties object
              eventProperties[propName] = attr.value;
            }
          }

          analytics.track({
            name: trackEventName,
            properties: eventProperties,
          });
          break; // Stop the loop 
        }
        target = target.parentElement; // Move up the DOM tree
      }
    };

    
    document.addEventListener("click", handleClick);

    
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);
  return <></>;
}