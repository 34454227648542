import { fetcher } from "gql/fetcher";
import {
  RealtimeFailBoardDocument,
  RealtimeFailBoardQuery,
  RealtimeFailBoardQueryVariables,
} from "gql/generated";
import moment from "moment";
import { useQuery, useQueryClient, UseQueryOptions } from "react-query";

export type RealBoardWithLastUpdated = RealtimeFailBoardQuery & {
  lastUpdated: Date;
};
export type Board =
  | RealBoardWithLastUpdated["inventoryFailBoard"]
  | RealBoardWithLastUpdated["softwareHardwareFailBoard"];
export type Machine = Board["machines"][number];
export type Event = Machine["events"][number];

export function useRealTimeQuery(
  variables: RealtimeFailBoardQueryVariables,
  options?: UseQueryOptions<RealBoardWithLastUpdated, unknown>
) {
  const queryKey = ["RealtimeFailBoard", variables];
  const cachedData = useQueryClient().getQueryData<
    RealBoardWithLastUpdated | undefined
  >(queryKey);

  return useQuery(queryKey, () => fetchData(variables), {
    useErrorBoundary: () => false,
    structuralSharing: false,
    isDataEqual: () => false,
    staleTime: shouldBeStale(cachedData, variables.date),
    refetchInterval: (data) => shouldRevalidate(data, variables.date),
    ...options,
  });
}

const POLL_INTERVAL = 15_000;

function shouldRevalidate(
  data: RealBoardWithLastUpdated,
  selectedDate: string
) {
  if (!data) {
    return false;
  }
  return moment(data.lastUpdated).isSame(moment(selectedDate), "D")
    ? POLL_INTERVAL
    : false;
}

function shouldBeStale(data: RealBoardWithLastUpdated, selectedDate: string) {
  if (!data) {
    return 0;
  }
  return moment(data.lastUpdated).isSame(moment(selectedDate), "D")
    ? 0
    : Infinity;
}

async function fetchData(variable: RealtimeFailBoardQueryVariables) {
  const response = await fetcher<
    RealtimeFailBoardQuery,
    RealtimeFailBoardQueryVariables
  >(RealtimeFailBoardDocument, variable)();
  const castedResponse = response as RealBoardWithLastUpdated;
  castedResponse.lastUpdated = new Date();
  return castedResponse;
}
