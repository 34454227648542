import * as React from "react";

interface IVendiiProps {
  color?: string,
  className?: string
}

function Vendii ({
  color = "currentcolor",
  className
}: IVendiiProps): JSX.Element {
  return (
    <svg
      width="312"
      height="82"
      viewBox="0 0 312 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.9138 24.1126C21.0779 19.4017 26.7556 17.4765 31.3387 19.8996C36.0913 22.4122 37.6091 28.4954 34.5939 32.9461L21.9747 51.5734C19.9968 54.493 16.1898 55.5412 12.9962 54.0455C9.4283 52.3745 7.8767 48.138 9.52139 44.5578L18.9138 24.1126Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M58.3466 60.5275C61.8631 66.7012 59.5196 74.5609 53.1964 77.8009C46.8732 81.0408 39.1249 78.3521 36.1677 71.8918L18.5463 33.3959C16.1859 28.2393 18.303 22.1383 23.3502 19.5521C28.3974 16.966 34.586 18.8112 37.3928 23.7391L58.3466 60.5275Z"
        fill="url(#paint1_linear)"
      />
      <circle cx="37.927" cy="8.42823" r="8.42823" fill="#9AA0FA" />
      <circle cx="6.32117" cy="65.3188" r="6.32117" fill="#9AA0FA" />
      <path
        d="M57.4817 19.7351C62.076 10.9377 73.1078 7.79147 81.6515 12.8418C90.1952 17.8922 92.7573 29.0741 87.2648 37.3405L62.5063 74.6036C58.1007 81.2343 49.2757 83.2564 42.4226 79.2054C35.5694 75.1543 33.0863 66.4478 36.7716 59.3912L57.4817 19.7351Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M138.644 31.1845C138.982 30.3978 139.515 30.0045 140.246 30.0045C140.695 30.0045 141.089 30.1731 141.426 30.5102C141.763 30.7911 141.932 31.1845 141.932 31.6902C141.932 31.8587 141.875 32.1116 141.763 32.4487L124.654 70.2072C124.148 71.2186 123.361 71.7243 122.294 71.7243C121.788 71.7243 121.339 71.6119 120.945 71.3871C120.552 71.1062 120.243 70.7129 120.018 70.2072L102.993 32.4487L102.825 31.7744C102.825 31.2687 103.021 30.8473 103.414 30.5102C103.808 30.1169 104.257 29.9202 104.763 29.9202C105.493 29.9202 105.971 30.2574 106.196 30.9316L122.378 67.5101L138.644 31.1845Z"
        fill={color}
      />
      <path
        d="M181.376 63.6331C181.769 63.6331 182.106 63.8017 182.387 64.1388C182.668 64.4198 182.808 64.7569 182.808 65.1502C182.808 65.7121 182.584 66.274 182.134 66.8359C181.741 67.3416 181.01 67.9315 179.943 68.6058C175.897 71.0219 171.711 72.2299 167.385 72.2299C161.26 72.2299 156.428 70.3476 152.888 66.583C149.405 62.8184 147.663 57.6491 147.663 51.0751C147.663 46.861 148.449 43.1525 150.023 39.9498C151.596 36.7471 153.815 34.2748 156.681 32.533C159.547 30.735 162.834 29.8359 166.542 29.8359C171.711 29.8359 175.785 31.5216 178.763 34.8929C181.741 38.2642 183.23 42.8716 183.23 48.7152C183.23 49.7266 183.033 50.457 182.64 50.9065C182.247 51.356 181.544 51.5808 180.533 51.5808H151.54C151.596 57.0872 152.972 61.3294 155.67 64.3074C158.423 67.2854 162.272 68.7744 167.216 68.7744C169.913 68.7744 172.133 68.4091 173.874 67.6787C175.673 66.9483 177.414 65.9931 179.1 64.8131C180.28 64.0265 181.038 63.6331 181.376 63.6331ZM166.542 33.123C162.272 33.123 158.816 34.4715 156.175 37.1685C153.591 39.8094 152.073 43.5178 151.624 48.2938H179.859C179.746 43.4054 178.538 39.6689 176.234 37.0842C173.931 34.4434 170.7 33.123 166.542 33.123Z"
        fill={color}
      />
      <path
        d="M212.258 29.8359C222.147 29.8359 227.092 35.0053 227.092 45.3439V70.1229C227.092 71.4152 226.502 72.0614 225.322 72.0614C224.76 72.0614 224.31 71.8928 223.973 71.5557C223.692 71.2186 223.552 70.741 223.552 70.1229V45.681C223.552 41.3545 222.681 38.208 220.939 36.2414C219.197 34.2186 216.388 33.2072 212.511 33.2072C208.184 33.2072 204.701 34.5558 202.06 37.2528C199.475 39.8936 198.183 43.4335 198.183 47.8724V70.1229C198.183 70.741 198.014 71.2186 197.677 71.5557C197.396 71.8928 196.975 72.0614 196.413 72.0614C195.851 72.0614 195.401 71.8928 195.064 71.5557C194.783 71.2186 194.643 70.741 194.643 70.1229V31.943C194.643 30.6507 195.233 30.0045 196.413 30.0045C196.975 30.0045 197.396 30.1731 197.677 30.5102C197.958 30.8473 198.098 31.3249 198.098 31.943V38.1799C199.335 35.4829 201.189 33.432 203.661 32.0273C206.19 30.5664 209.055 29.8359 212.258 29.8359Z"
        fill={color}
      />
      <path
        d="M272.75 11.7995C273.312 11.7995 273.733 11.9681 274.014 12.3052C274.352 12.6424 274.52 13.12 274.52 13.738V70.0386C274.52 70.6567 274.352 71.1343 274.014 71.4714C273.733 71.8085 273.312 71.9771 272.75 71.9771C271.57 71.9771 270.98 71.3309 270.98 70.0386V63.3803C269.744 66.2459 267.89 68.4372 265.418 69.9543C263.001 71.4714 260.08 72.2299 256.652 72.2299C253.056 72.2299 249.882 71.359 247.128 69.6172C244.375 67.8192 242.24 65.3188 240.723 62.1161C239.206 58.8572 238.447 55.0925 238.447 50.8222C238.447 46.6081 239.206 42.9278 240.723 39.7813C242.24 36.6347 244.375 34.1905 247.128 32.4487C249.882 30.7069 253.056 29.8359 256.652 29.8359C260.08 29.8359 263.001 30.5945 265.418 32.1116C267.89 33.6286 269.744 35.7919 270.98 38.6013V13.738C270.98 12.4457 271.57 11.7995 272.75 11.7995ZM256.652 68.7744C261.203 68.7744 264.715 67.2292 267.188 64.1388C269.716 61.0485 270.98 56.6658 270.98 50.9908C270.98 45.372 269.744 41.0174 267.272 37.927C264.799 34.8367 261.288 33.2915 256.736 33.2915C252.185 33.2915 248.617 34.8367 246.033 37.927C243.504 40.9612 242.24 45.2596 242.24 50.8222C242.24 56.4411 243.504 60.8518 246.033 64.0546C248.617 67.2011 252.157 68.7744 256.652 68.7744Z"
        fill={color}
      />
      <path
        d="M290.654 72.0614C290.092 72.0614 289.643 71.8928 289.306 71.5557C289.025 71.2186 288.884 70.741 288.884 70.1229V31.8587C288.884 31.2968 289.025 30.8473 289.306 30.5102C289.643 30.1731 290.092 30.0045 290.654 30.0045C291.216 30.0045 291.637 30.1731 291.918 30.5102C292.256 30.8473 292.424 31.2968 292.424 31.8587V70.1229C292.424 70.741 292.256 71.2186 291.918 71.5557C291.637 71.8928 291.216 72.0614 290.654 72.0614ZM290.654 19.8906C289.811 19.8906 289.081 19.6097 288.463 19.0478C287.845 18.4859 287.536 17.7555 287.536 16.8565C287.536 15.9013 287.817 15.1427 288.379 14.5808C288.997 14.019 289.755 13.738 290.654 13.738C291.553 13.738 292.284 14.019 292.846 14.5808C293.464 15.1427 293.773 15.9013 293.773 16.8565C293.773 17.7555 293.464 18.4859 292.846 19.0478C292.227 19.6097 291.497 19.8906 290.654 19.8906Z"
        fill={color}
      />
      <path
        d="M308.515 72.0614C307.953 72.0614 307.503 71.8928 307.166 71.5557C306.885 71.2186 306.745 70.741 306.745 70.1229V31.8587C306.745 31.2968 306.885 30.8473 307.166 30.5102C307.503 30.1731 307.953 30.0045 308.515 30.0045C309.077 30.0045 309.498 30.1731 309.779 30.5102C310.116 30.8473 310.285 31.2968 310.285 31.8587V70.1229C310.285 70.741 310.116 71.2186 309.779 71.5557C309.498 71.8928 309.077 72.0614 308.515 72.0614ZM308.515 19.8906C307.672 19.8906 306.942 19.6097 306.323 19.0478C305.705 18.4859 305.396 17.7555 305.396 16.8565C305.396 15.9013 305.677 15.1427 306.239 14.5808C306.857 14.019 307.616 13.738 308.515 13.738C309.414 13.738 310.144 14.019 310.706 14.5808C311.324 15.1427 311.633 15.9013 311.633 16.8565C311.633 17.7555 311.324 18.4859 310.706 19.0478C310.088 19.6097 309.358 19.8906 308.515 19.8906Z"
        fill={color}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="31.571"
          y1="20.0224"
          x2="13.2947"
          y2="54.5923"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#343CAA" />
          <stop offset="1" stopColor="#444CB6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="42.6705"
          y1="54.668"
          x2="23.1836"
          y2="19.6448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6A73FB" />
          <stop offset="1" stopColor="#3038A4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="81.6514"
          y1="12.8418"
          x2="42.4225"
          y2="79.2053"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B3B9FF" />
          <stop offset="1" stopColor="#6770FA" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Vendii;
