import * as React from "react";

interface IArrowPriorityUrgentProps {
  color?: string;
  className?: string;
}

function ArrowPriorityUrgent({ className }: IArrowPriorityUrgentProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Priority / Urgent">
        <path
          id="Vector"
          d="M7.98444 3.20078L3.48444 5.90078C2.98444 6.20078 2.38444 6.00078 2.18444 5.50078C1.98444 5.00078 1.98444 4.40078 2.48444 4.20078L7.48444 1.20078C7.78444 1.00078 8.18444 1.00078 8.48444 1.20078L13.4844 4.20078C13.9844 4.50078 14.0844 5.10078 13.7844 5.60078C13.4844 6.10078 12.8844 6.20078 12.3844 5.90078L7.98444 3.20078Z"
          fill="#E95D50"
        />
        <path
          id="Vector_2"
          d="M3.48444 10.2011C2.98444 10.5011 2.38444 10.3011 2.18444 9.90107C1.98444 9.50107 1.98444 8.80107 2.48444 8.50107L7.48444 5.50107C7.78444 5.30107 8.18444 5.30107 8.48444 5.50107L13.4844 8.50107C13.9844 8.80107 14.0844 9.40107 13.7844 9.90107C13.4844 10.4011 12.8844 10.5011 12.3844 10.2011L7.98444 7.50107L3.48444 10.2011Z"
          fill="#F89389"
        />
        <path
          id="Vector_3"
          d="M3.48444 14.5009C2.98444 14.8009 2.38444 14.7009 2.18444 14.2009C1.98444 13.7009 1.98444 13.1009 2.48444 12.8009L7.48444 9.80088C7.78444 9.60088 8.18444 9.60088 8.48444 9.80088L13.4844 12.8009C13.9844 13.1009 14.0844 13.7009 13.7844 14.2009C13.4844 14.7009 12.8844 14.8009 12.3844 14.5009L7.98444 11.8009L3.48444 14.5009Z"
          fill="#F2CDCA"
        />
      </g>
    </svg>
  );
}

export default ArrowPriorityUrgent;
