import React from "react";
import { Skeleton } from "@mui/material";

export function SkeletonPage() {
  return (
    <div className="flex flex-col flex-1 basis-0 min-h-0">
      <div className="flex">
        <div className="flex-1 flex gap-3 mb-4">
          <div className="flex-1">
            <Skeleton className="h-[50px] w-2/3 scale-100 !bg-white" />
          </div>
          <Skeleton className="h-[50px] w-1/4 scale-100 !bg-white" />
        </div>
      </div>
      <div className="flex-1 space-y-3 overflow-hidden bg-white p-2 rounded-lg">
        <div className="flex gap-3">
          <div className="flex-1">
            <Skeleton className="h-[40px] flex-1 md:flex-initial md:w-[500px] scale-100 bg-gray-100" />
          </div>
          <div className="flex gap-3">
            <Skeleton className="hidden md:block h-[40px] w-[240px] scale-100 bg-gray-100" />
            <Skeleton className="hidden md:block h-[40px] w-[240px] scale-100 bg-gray-100" />
            <Skeleton className="h-[40px] w-[40px] shrink-0 scale-100 bg-gray-100" />
          </div>
        </div>
        {Array(20)
          .fill("")
          .map((_, index) => (
            <div key={index} className="flex gap-2 overflow-x-hidden">
              <Skeleton key={index} className="h-[40px] shrink-0 w-10 scale-100 bg-gray-100" />
              <Skeleton key={index} className="col-span-3 h-[40px] min-w-[200px] flex-1 scale-100 bg-gray-100" />
              <Skeleton key={index} className="h-[40px] w-[90px] shrink-0 scale-100 bg-gray-100" />
              <Skeleton key={index} className="col-span-2 h-[40px] w-[90px] shrink-0 scale-100 bg-gray-100" />
              <Skeleton key={index} className="h-[40px] w-[150px] shrink-0 scale-100 bg-gray-100" />
              <Skeleton key={index} className="h-[40px] w-[150px] shrink-0 scale-100 bg-gray-100" />
              <Skeleton key={index} className="h-[40px] w-[90px] shrink-0 scale-100 bg-gray-100" />
              <Skeleton key={index} className="h-[40px] w-[90px] shrink-0 scale-100 bg-gray-100" />
            </div>
          ))}
      </div>
    </div>
  );
}
