import * as React from "react";

interface IBoxProps {
  color?: string;
  className?: string;
}

function Box({ color = "currentcolor", className }: IBoxProps): JSX.Element {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 19V9C21.1046 9 22 8.10457 22 7V5C22 3.89543 21.1046 3 20 3H4C2.89543 3 2 3.89543 2 5V7C2 8.10457 2.89543 9 4 9V19C4 20.1046 4.89543 21 6 21H18C19.1046 21 20 20.1046 20 19ZM6 19V9H18V19H6ZM9 12V10H15V12H9ZM4 7V5H20V7H4Z"
        fill={color}
      />
    </svg>
  );
}

export default Box;
