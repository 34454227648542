import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardMachineErrorDocument,
  GetMaintenanceBoardMachineErrorQuery,
  GetMaintenanceBoardMachineErrorQueryVariables,
  MaintenanceBoardSortInput,
} from "gql/generated";

export async function getMachineUnsellableError({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardMachineErrorQuery,
    GetMaintenanceBoardMachineErrorQueryVariables
  >(GetMaintenanceBoardMachineErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort,
  })();
}
