import { fetcher } from "gql/fetcher";
import { GetMaintenanceBoardSm1ErrorDocument, GetMaintenanceBoardSm1ErrorQuery, GetMaintenanceBoardSm1ErrorQueryVariables, MaintenanceBoardSortInput } from "gql/generated";

export async function getSM1({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardSm1ErrorQuery,
    GetMaintenanceBoardSm1ErrorQueryVariables
  >(GetMaintenanceBoardSm1ErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
