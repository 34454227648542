import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardEspressoCupErrorDocument,
  GetMaintenanceBoardEspressoCupErrorQuery,
  GetMaintenanceBoardEspressoCupErrorQueryVariables,
  MaintenanceBoardSortInput,
} from "gql/generated";

export async function getEspressoCups({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardEspressoCupErrorQuery,
    GetMaintenanceBoardEspressoCupErrorQueryVariables
  >(GetMaintenanceBoardEspressoCupErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
