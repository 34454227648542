import * as React from "react";
import { Popover, Transition } from "@headlessui/react";
import { clsx } from "clsx";
import moment from "moment";
import { DayPicker as ReactDayPicker, PropsSingle } from "react-day-picker";
import { th, enUS } from "react-day-picker/locale";
import { useTranslation } from "react-i18next";
import "react-day-picker/src/style.css";
import "./style.css";


export function DayPicker({
  selected,
  onSelect,
}: Pick<PropsSingle, "selected"> & {
  onSelect: (selected: PropsSingle["selected"]) => void;
}) {
  const [_selected, _setSelected] =
    React.useState<PropsSingle["selected"]>(selected);

  function onClose() {
    if (!_selected) {
      _setSelected(selected);
    } else {
      onSelect(_selected);
    }
  }

  const { i18n, t } = useTranslation();

  return (
    <Popover className="relative z-20 inline-block">
      {({ open }) => (
        <>
          <Popover.Button
            className={clsx(
              "font-kanit w-40 py-3 flex px-4 text-subtitle1 leading-6 text-on-surface-medium rounded-[4px] bg-transparent border border-outline hover:border-black cursor-pointer",
              open ? "border-black" : ""
            )}
          >
            <span>
              {!open || !_selected
                ? moment(selected).isSame(moment(), "D")
                  ? t("label_today")
                  : moment(selected).format("DD/MM/YYYY")
                : moment(_selected).format("DD/MM/YYYY")}
            </span>
          </Popover.Button>

          <Transition.Child
            enter="ease-out duration-200 origin-top-right"
            enterFrom="opacity-0 scale-75"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-100 origin-top-right "
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-75"
          >
            <Popover.Panel className="absolute top-[calc(100%+3px)] bg-white font-kanit right-0 shadow-xl rounded-lg ring-1 ring-outline p-5">
              <ReactDayPicker
                classNames={{
                  selected: "bg-primary-500 !text-white rounded",
                }}
                locale={i18n.language === "th" ? th : enUS}
                mode="single"
                defaultMonth={selected}
                onSelect={_setSelected}
                selected={_selected}
                styles={{
                  day: {
                    fontWeight: 400,
                    fontSize: "14px",
                    fontFamily: "Kanit",
                  },

                  selected: {
                    backgroundColor: "rgb(75, 77, 155)",
                    color: "white",
                  },
                  weekday: {
                    opacity: 0.5,
                    fontWeight: 400,
                    fontSize: "smaller",
                    fontFamily: "Kanit",
                  },
                  month_caption: {
                    fontWeight: 400,
                    fontSize: "16px",
                    fontFamily: "Kanit",
                  },
                }}
                disabled={{
                  after: new Date(),
                  before: moment().subtract(30, "days").toDate(),
                }}
              />
            </Popover.Panel>
          </Transition.Child>
          <OnClose open={open} callback={onClose} />
        </>
      )}
    </Popover>
  );
}

function OnClose({ open, callback }: { open: boolean; callback: () => void }) {
  React.useLayoutEffect(() => {
    if (!open) {
      callback();
    }
  }, [open]);
  return null;
}
