import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardPlasticCupErrorDocument,
  GetMaintenanceBoardPlasticCupErrorQuery,
  GetMaintenanceBoardPlasticCupErrorQueryVariables,
  MaintenanceBoardSortInput,
} from "gql/generated";

export async function getPlasticCups({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardPlasticCupErrorQuery,
    GetMaintenanceBoardPlasticCupErrorQueryVariables
  >(GetMaintenanceBoardPlasticCupErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
