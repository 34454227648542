import React, { Suspense } from "react";
import SideNav from "common/components/sidenav/SideNav";
import TopNav from "common/components/topnav/TopNav";
import { SkeletonPage } from "containers/splash/SkeletonPage";
import { FallbackUI } from "error-handling-ui/FallbackUI";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Routes, Route } from "react-router-dom";

// import Route from "common/components/route/Route";

import { useIsHideNavigation } from "./isHideNavigation";
import * as styles from "./Portal.module.scss";


// Lazy-loaded components for code splitting
const Dashboard = React.lazy(() => import("containers/dashboard/Dashboard"));
const MachineDetail = React.lazy(() => import("containers/machinedetail/MachineDetail"));
const Service = React.lazy(() => import("containers/service/Service"));
const Settings = React.lazy(() => import("containers/settings/Settings"));
const Location = React.lazy(() => import("containers/location/Location"));
const NewOperations = React.lazy(() => import("containers/new-operations/NewOperations"));
const Warehousing = React.lazy(() => import("containers/warehousing"));
const Contract = React.lazy(() => import("containers/contract/Contract"));
const TransactionRoute = React.lazy(() => import("containers/transactions"));
const DownloadReportPage = React.lazy(() => import("containers/transactions/downloadReport"));
const Maintenance = React.lazy(() => import("containers/maintenance/Maintenance"));
const MachineV2 = React.lazy(() => import("containers/machinesV2"));

function Portal(): JSX.Element {
  const isHide = useIsHideNavigation();
  return (
    <div className={styles.Portal}>
      {!isHide && (
        <>
          <TopNav />
          <SideNav />
        </>
      )}
      <div
        className={[styles.content, !isHide ? styles.contentWithNav : ""].join(
          " "
        )}
      >
        <ErrorBoundary
          FallbackComponent={FallbackUI}
          resetKeys={[window.location.href]}
        >
          <Suspense fallback={<SkeletonPage />}>
            <Routes>
              <Route path="/machines-v2/*" element={<Navigate to="/machines" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/machines/detail/:machineId/*"
                element={<MachineDetail />}
              />
              <Route path="/machines/*" element={<MachineV2 />} />

              <Route path="/transactions/*" element={<TransactionRoute />} />
              <Route path="/warehousing/*" element={<Warehousing />} />
              <Route path="/contract/*" element={<Contract />} />
              <Route path="/maintenance/*" element={<Maintenance />} />
              <Route path="/service/*" element={<Service />} />
              <Route path="/new-operations/*" element={<NewOperations />} />
              <Route path="/settings/*" element={<Settings />} />
              <Route path="/location/*" element={<Location />} />
              <Route
                path="/download-report/:jobId"
                element={<DownloadReportPage />}
              />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </div>
    </div>
  );
}

export default Portal;
