import * as React from "react";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

import * as styles from "./MaintenanceTable.module.scss";
import { NoData } from "./NoData";

interface MaintenanceTableProps<T, S extends string> {
  data: Array<T> | undefined;
  isLoading?: boolean;
  getKey: (dataPoint: T) => string;
  isPaginating?: boolean;
  onScrollNearEnd?: () => void;
  containerStyles: React.CSSProperties;
  columns: Array<{
    id: S;
    commonStyle: React.CSSProperties;
    headerStyle?: React.CSSProperties;
    rowStyle: React.CSSProperties;
    loadingRowStyle?: React.CSSProperties;
    headerCell: (columnId: S) => JSX.Element;
    cell: (dataPoint: T) => JSX.Element;
  }>;
}

export function MaintenanceTable<T, S extends string>({
  isLoading = false,
  isPaginating = false,
  data,
  getKey,
  columns,
  containerStyles,
  onScrollNearEnd = () => {},
}: MaintenanceTableProps<T, S>): JSX.Element {
  const { t } = useTranslation("maintenance");
  const scrollContainer = React.useRef<HTMLDivElement>(null);
  const endOfTableRef = React.useRef<HTMLDivElement>(null);

  // Scroll to top when data finishes loading
  React.useEffect(() => {
    if (isLoading) {
      return;
    }

    if (scrollContainer.current) {
      scrollContainer.current.scrollTop = 0;
    }
  }, [isLoading]);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            onScrollNearEnd();
          }
        });
      },
      { root: scrollContainer.current, threshold: 1, rootMargin: "100px" }
    );

    if (endOfTableRef.current) {
      observer.observe(endOfTableRef.current);
    }

    return () => {
      if (endOfTableRef.current) {
        observer.unobserve(endOfTableRef.current);
      }
      observer.disconnect();
    };
  }, [endOfTableRef.current, onScrollNearEnd]);

  return (
    <div
      ref={scrollContainer}
      className={styles.TableContainer}
      style={containerStyles}
    >
      <div className={styles.MinWidthContainer}>
        <div className={styles.HeaderRow}>
          {columns.map((column) => (
            <div
              key={column.id}
              style={{ ...column.commonStyle, ...(column.headerStyle ?? {}) }}
            >
              {column.headerCell(column.id)}
            </div>
          ))}
        </div>

        {isLoading ? <LoadingRows columns={columns} numberOfRows={10} /> : null}

        {data?.map((element) => (
          <div
            key={getKey(element)}
            className={[styles.FadeIn, styles.Row].join(" ")}
          >
            {columns.map((column) => (
              <div
                key={column.id}
                style={{ ...column.commonStyle, ...(column.rowStyle ?? {}) }}
              >
                {column.cell(element)}
              </div>
            ))}
          </div>
        ))}

        {isPaginating ? (
          <LoadingRows columns={columns} numberOfRows={10} />
        ) : null}
      </div>

      <div ref={endOfTableRef} />
      {!isLoading && data?.length === 0 ? <NoData text={t("noErrorAndFailNow")} /> : null}
    </div>
  );
}

function LoadingRows<T, S extends string>({
  columns,
  numberOfRows,
}: {
  columns: MaintenanceTableProps<T, S>["columns"];
  numberOfRows: number;
}) {
  return (
    <>
      {new Array(numberOfRows).fill(null).map((_, index) => (
        <div key={index} className={styles.Row}>
          {columns.map((column) => (
            <div
              key={column.id}
              className={styles.LoadingCell}
              style={{
                ...column.commonStyle,
                ...(column.loadingRowStyle ?? {}),
                scrollSnapAlign: "start",
              }}
            >
              <div className={styles.SkeletonContainer}>
                <Skeleton width={"100%"} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </>
  );
}

