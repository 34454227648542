import * as React from "react";

interface IPaginationDoubleChevron {
  color?: string;
  className?: string;
}

function PaginationDoubleChevron ({
  color = "currentcolor",
  className
}: IPaginationDoubleChevron): JSX.Element {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.04858 8L9 7.06L5.90958 4L9 0.94L8.04858 8.31755e-08L4 4L8.04858 8Z"
        fill={color}
      />
      <path
        d="M4.04858 8L5 7.06L1.90958 4L5 0.94L4.04858 8.31755e-08L1.27146e-07 4L4.04858 8Z"
        fill={color}
      />
    </svg>
  );
}

export default PaginationDoubleChevron;
