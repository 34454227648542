import * as React from "react";
import SideNavButton from "common/components/sidenavbutton/SideNavButton";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { routes } from "../routes";


export function MaintenaceSideNav() {
  const { pathname } = useLocation();
  const { t } = useTranslation("maintenance");

  return (
    <SideNavButton
      to={routes.majorTrouble.machineUnsellable}
      translationKey={t("maintenance")}
      icon="Service"
      active={pathname.startsWith(routes.root)}
    />
  );
}
