import { IAction } from "common/interfaces";
import { omit } from "lodash";
import { IMachineInventoryData } from "machines/interfaces";

interface IMachineInventoryState {
  [machineId: string]: IMachineInventoryData[],
}

const initialState: IMachineInventoryState = {};

function machineInventoryReducer (
  state: IMachineInventoryState = initialState,
  action: IAction
): IMachineInventoryState {
  const [ domain, request, result ] = action.type.split("/");

  if (domain === "MACHINE_INVENTORY") {
    if (request.includes("GET_") && result === "SUCCESS") {
      return { ...state, ...action.payload };
    }
    if (request.includes("REFILL_") && result === "SUCCESS") {
      return { ...state, ...action.payload };
    }
    if (request.includes("PATCH_") && result === "SUCCESS") {
      return {
        ...state,
        [action.payload.machineId]: {
          ...state[action.payload.machineId],
          ...action.payload.updatedInventory
        }
      };
    }
    if (request.includes("CREATE_") && result === "SUCCESS") {
      return {
        ...state,
        [action.payload.machineId]: {
          ...(state[action.payload.machineId] || []),
          ...action.payload.inventories
        }
      };
    }
    if (request.includes("DELETE_") && result === "SUCCESS") {
      const { [action.payload.machineInventoryId]: value, ...withoutInventory } = state[action.payload.machineId];
      return {
        ...state,
        [action.payload.machineId]: withoutInventory
      };
    }
  }

  if (action.type === "MACHINES/DELETE/SUCCESS") {
    return omit(state, [action.payload.id]);
  }

  return state;
}

export default machineInventoryReducer;
