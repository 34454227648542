import * as React from "react";

interface IFrontBoardProps {
  color?: string;
  className?: string;
}

function FrontBoard({
  color = "currentcolor",
  className,
}: IFrontBoardProps): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.00524 5.35602L6.00524 12.4241L8.36126 12.4241L8.36126 5.35602L6.00524 5.35602ZM2.86387 4.57068C2.86387 4.13874 3.01767 3.76898 3.32526 3.46139C3.63285 3.1538 4.00262 3 4.43456 3L15.4293 3C15.8613 3 16.231 3.1538 16.5386 3.46139C16.8462 3.76898 17 4.13874 17 4.57068L17 15.5654C17 15.9974 16.8462 16.3671 16.5386 16.6747C16.231 16.9823 15.8613 17.1361 15.4293 17.1361L4.43456 17.1361C4.00262 17.1361 3.63285 16.9823 3.32526 16.6747C3.01767 16.3671 2.86387 15.9974 2.86387 15.5654L2.86387 4.57068ZM4.43456 4.57068L4.43456 15.5654L15.4293 15.5654L15.4293 4.57068L4.43456 4.57068Z"
        fill={color}
      />
      <rect
        x="8.35938"
        y="10.8535"
        width="3.9267"
        height="2.35602"
        transform="rotate(90 8.35938 10.8535)"
        fill={color}
      />
    </svg>
  );
}

export default FrontBoard;
