import * as React from "react";

interface IHealthProps {
  color?: string,
  className?: string
}

function Health ({
  color = "currentcolor",
  className
}: IHealthProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path id='Vector' d='M3.16714 14.4022L10.4502 21.0648C10.7729 21.3603 11.1239 21.6271 11.4986 21.8617C11.6493 21.952 11.8241 22 12.0026 22C12.1811 22 12.356 21.952 12.5067 21.8617C12.8811 21.6271 13.2318 21.3604 13.5542 21.0648L20.8372 14.4022C21.5205 13.7811 22.0642 13.0364 22.4358 12.2125C22.8074 11.3886 22.9993 10.5024 23 9.60687C23.0026 8.3987 22.6536 7.21307 21.991 6.17945C21.3284 5.14584 20.3778 4.30398 19.2429 3.74582C18.108 3.18765 16.8325 2.93463 15.5557 3.01438C14.2788 3.09414 13.0497 3.50361 12.0026 4.19808C10.6517 3.30615 9.01054 2.89338 7.36869 3.0326C5.72685 3.17182 4.18984 3.85408 3.02888 4.95901C1.86791 6.06393 1.1576 7.52051 1.02327 9.07173C0.888942 10.6229 1.33923 12.1691 2.29468 13.4374C2.5525 13.7844 2.84463 14.1074 3.16714 14.4022ZM4.441 6.06879C5.41076 5.19426 6.70069 4.70698 8.04173 4.70859C8.65346 4.7002 9.26095 4.80577 9.82942 5.01928C10.3979 5.23278 10.9162 5.55002 11.3547 5.95284C11.53 6.10904 11.762 6.19604 12.0031 6.19604C12.2441 6.19604 12.4761 6.10904 12.6515 5.95284C13.5981 5.12737 14.8457 4.67875 16.1341 4.70051C17.4224 4.72227 18.6522 5.21274 19.567 6.06965C20.4651 6.88386 21.0239 7.9754 21.1416 9.14573C21.2593 10.316 20.9282 11.4874 20.2085 12.4467C20.0167 12.7033 19.7994 12.942 19.5596 13.1597C19.5596 13.1597 12.0943 19.9832 12.0026 20.0584C11.911 19.9832 4.44192 13.1597 4.44192 13.1597C4.20214 12.9417 3.98488 12.7027 3.79307 12.4458C3.07136 11.4868 2.73964 10.3144 2.85862 9.14332C2.97761 7.97224 3.53935 6.88079 4.441 6.06879Z' fill={color} />
      <path id='Vector_2' d='M6.50394 12.4857H8.33684C8.48768 12.4857 8.63619 12.4505 8.7692 12.3834C8.90221 12.3162 9.01561 12.2191 9.09932 12.1006L10.1294 10.6426L12.1337 13.7965C12.2053 13.9093 12.3032 14.0051 12.4199 14.0768C12.5366 14.1484 12.6691 14.194 12.8073 14.2101C12.9455 14.2256 13.0856 14.2113 13.2171 14.1685C13.3487 14.1256 13.4683 14.0553 13.567 13.9627L16.0478 11.6204H17.5013C17.7444 11.6204 17.9775 11.5292 18.1493 11.367C18.3212 11.2047 18.4178 10.9846 18.4178 10.7551C18.4178 10.5256 18.3212 10.3056 18.1493 10.1433C17.9775 9.98101 17.7444 9.88985 17.5013 9.88985H15.6684C15.4254 9.8899 15.1923 9.98109 15.0205 10.1434L13.1024 11.9552L10.956 8.5807C10.8767 8.45524 10.765 8.3508 10.6313 8.27712C10.4977 8.20343 10.3464 8.1629 10.1917 8.15931C10.0365 8.15169 9.88192 8.18337 9.744 8.25107C9.60608 8.31876 9.48982 8.42002 9.40725 8.54436L7.84654 10.7551H6.50394C6.26088 10.7551 6.02778 10.8463 5.85592 11.0086C5.68405 11.1708 5.58749 11.3909 5.58749 11.6204C5.58749 11.8499 5.68405 12.07 5.85592 12.2322C6.02778 12.3945 6.26088 12.4857 6.50394 12.4857Z' fill={color} />
    </svg>
  );
}

export default Health;
