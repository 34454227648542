import { useAuthStore } from "userandauth/useAuthStore";
import { updateDefaultTimezone } from "utils/date";

import { useGetUser, useGetUserOptions } from "./useGetUser";

function useGetCurrentUser(options?: useGetUserOptions) {
  const userId = useAuthStore((state) => state.userId);
  return useGetUser(userId, {
    ...options,
    onSuccess: (user) => {
      updateDefaultTimezone(user.timezone);
    },
  });
}

export { useGetCurrentUser };
