import * as React from "react";

interface IDoubleChevronLeftProps {
  color?: string;
  className?: string;
}

function DoubleChevronLeft({
  color = "currentcolor",
  className,
}: IDoubleChevronLeftProps): JSX.Element {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.70714 11.2928L5.41424 7.99995L8.70714 4.70706L7.29292 3.29285L2.58582 7.99995L7.29292 12.7071L8.70714 11.2928ZM13.7071 11.2928L10.4142 7.99995L13.7071 4.70706L12.2929 3.29285L7.58582 7.99995L12.2929 12.7071L13.7071 11.2928Z"
        fill={color}
      />
    </svg>
  );
}

export default DoubleChevronLeft;
