import * as React from "react";
import Icon from "common/components/icon/Icon";
import { useTranslation } from "react-i18next";

export function ErrorPlaceHolder() {
  const { t } = useTranslation("maintenance", {
    keyPrefix: "rtFail",
  });

  return (
    <div className="h-full flex flex-col items-center justify-center bg-[#F2CDCA] rounded-lg border box-border border-error [box-shadow:0px_1px_4px_0px_rgba(0,0,0,0.13)] animate-fade-in">
      <Icon name="AlertOutline" color="error" className="w-8 h-8" />
      <div className="text-h6 text-on-surface-high font-normal">
        {t("failedToLoadData")}
      </div>
      <div className="text-subtitle2 leading-6 text-on-surface-medium text-center whitespace-pre">
        {t("failedToLoadDataDescription")}
      </div>
    </div>
  );
}
