import * as React from "react";
import { RealtimeFailBoardEventType } from "gql/generated";

export function getStatusIndicator(eventType: RealtimeFailBoardEventType) {
  return STATUS_TO_INDICATOR[eventType] ?? defaultIndicator;
}

const defaultIndicator = (
  <div className="size-[10px] rounded-full bg-on-surface-medium" />
);

const openIndicator = (
  <div className="relative flex items-center justify-center isolate">
    <div className="size-[10px] rounded-full bg-primary-500"></div>{" "}
    <div className="size-4 absolute -z-10 rounded-full bg-[rgba(52,71,173,0.24)]"></div>
  </div>
);

const STATUS_TO_INDICATOR: Record<RealtimeFailBoardEventType, JSX.Element> = {
  [RealtimeFailBoardEventType.Online]: (
    <div className="size-[10px] rounded-full bg-success" />
  ),

  [RealtimeFailBoardEventType.Alarm]: (
    <div className="size-[10px] rounded-full bg-error" />
  ),
  [RealtimeFailBoardEventType.ActiveCommand]: (
    <div className="size-[10px] rounded-full bg-success" />
  ),
  [RealtimeFailBoardEventType.DeactiveCommand]: (
    <div className="size-[10px] rounded-full bg-error" />
  ),
  [RealtimeFailBoardEventType.ArriveBy]: openIndicator,
  [RealtimeFailBoardEventType.Open]: openIndicator,
  [RealtimeFailBoardEventType.OpenRefill]: openIndicator,
  [RealtimeFailBoardEventType.OpenService]: openIndicator,
  [RealtimeFailBoardEventType.OpenTank]: openIndicator,
  [RealtimeFailBoardEventType.Information]: (
    <div className="size-[10px] rounded-full bg-on-surface-medium" />
  ),
  [RealtimeFailBoardEventType.MaintenanceExit]: (
    <div className="size-[10px] rounded-full bg-success" />
  ),
};
