import * as React from "react";

interface IPriorityOverdueProps {
  className?: string;
}

function PriorityOverdue({ className }: IPriorityOverdueProps): JSX.Element {
  return (
    <svg
      className={className}
      width='17'
      height='17'
      viewBox='0 0 17 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3851_35806)'>
        <path
          d='M6.67282 13.5339C4.08141 12.9784 2.20016 10.6483 2.20016 7.99304C2.20016 4.86838 4.74216 2.32639 7.86682 2.32639C10.5221 2.32639 12.8522 4.20763 13.4074 6.79919C13.4445 6.97212 13.5487 7.12326 13.6972 7.21935C13.8456 7.31544 14.0262 7.34862 14.1991 7.31159C14.372 7.27455 14.5232 7.17034 14.6193 7.02187C14.7154 6.8734 14.7485 6.69284 14.7115 6.51991C14.0252 3.31745 11.1464 0.993042 7.86682 0.993042C4.00713 0.993042 0.866821 4.1332 0.866821 7.99304C0.866821 11.2727 3.19104 14.1513 6.39354 14.8376C6.47914 14.8559 6.56751 14.8572 6.65362 14.8414C6.73972 14.8256 6.82186 14.793 6.89536 14.7454C6.96886 14.6979 7.03226 14.6363 7.08196 14.5642C7.13166 14.4921 7.16668 14.411 7.18502 14.3254C7.20336 14.2398 7.20466 14.1514 7.18884 14.0653C7.17302 13.9792 7.1404 13.8971 7.09284 13.8236C7.04528 13.7501 6.98371 13.6867 6.91164 13.637C6.83957 13.5873 6.75842 13.5522 6.67282 13.5339Z'
          fill='#FFD47C'
        />
        <path
          d='M7.20021 3.99306V7.32641H5.20021C5.02419 7.32761 4.85579 7.39838 4.73174 7.52327C4.6077 7.64817 4.53809 7.81705 4.53809 7.99308C4.53809 8.1691 4.6077 8.33799 4.73174 8.46288C4.85579 8.58778 5.02419 8.65855 5.20021 8.65975H7.86687C8.04368 8.65974 8.21325 8.5895 8.33828 8.46447C8.4633 8.33945 8.53354 8.16988 8.53355 7.99306V3.99306C8.53235 3.81703 8.46158 3.64863 8.33668 3.52459C8.21179 3.40055 8.04291 3.33093 7.86688 3.33093C7.69086 3.33093 7.52197 3.40055 7.39708 3.52459C7.27218 3.64863 7.20141 3.81703 7.20021 3.99306Z'
          fill='#FFD47C'
        />
        <path
          d='M11.8669 7.65979C9.47757 7.65979 7.53357 9.60354 7.53357 11.9931C7.53357 14.3827 9.47757 16.3265 11.8669 16.3265C14.2562 16.3265 16.2002 14.3827 16.2002 11.9931C16.2002 9.60351 14.2562 7.65979 11.8669 7.65979ZM11.8669 14.9931C11.2735 14.9931 10.6935 14.8172 10.2002 14.4875C9.70683 14.1579 9.32231 13.6893 9.09525 13.1412C8.86818 12.593 8.80877 11.9898 8.92453 11.4078C9.04028 10.8259 9.32601 10.2913 9.74557 9.87179C10.1651 9.45223 10.6997 9.1665 11.2816 9.05075C11.8636 8.93499 12.4668 8.9944 13.0149 9.22147C13.5631 9.44853 14.0317 9.83305 14.3613 10.3264C14.6909 10.8197 14.8669 11.3998 14.8669 11.9931C14.866 12.7885 14.5496 13.551 13.9872 14.1134C13.4248 14.6759 12.6623 14.9922 11.8669 14.9931Z'
          fill='#FFAB00'
        />
        <path
          d='M11.8669 9.65979C11.6901 9.6598 11.5205 9.73004 11.3955 9.85506C11.2705 9.98008 11.2002 10.1496 11.2002 10.3264V11.9931C11.1996 12.081 11.2164 12.1682 11.2496 12.2496C11.2829 12.331 11.3319 12.4051 11.3939 12.4675C11.4558 12.5299 11.5295 12.5794 11.6107 12.6132C11.6919 12.647 11.7789 12.6644 11.8669 12.6644C11.9548 12.6644 12.0419 12.647 12.1231 12.6132C12.2042 12.5794 12.2779 12.5299 12.3399 12.4675C12.4019 12.4051 12.4509 12.331 12.4841 12.2496C12.5173 12.1682 12.5341 12.081 12.5335 11.9931V10.3264C12.5335 10.1496 12.4633 9.98007 12.3383 9.85505C12.2132 9.73003 12.0437 9.65979 11.8669 9.65979Z'
          fill='#FFAB00'
        />
        <path
          d='M11.8669 14.3265C12.235 14.3265 12.5335 14.028 12.5335 13.6598C12.5335 13.2916 12.235 12.9932 11.8669 12.9932C11.4987 12.9932 11.2002 13.2916 11.2002 13.6598C11.2002 14.028 11.4987 14.3265 11.8669 14.3265Z'
          fill='#FFAB00'
        />
      </g>
      <defs>
        <clipPath id='clip0_3851_35806'>
          <rect
            width='16'
            height='16'
            fill='white'
            transform='translate(0.200195 0.326416)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PriorityOverdue;
