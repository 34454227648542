import { IAction } from "common/interfaces";

interface IErrorState {
  [actionType: string]: string
}

const initialState: IErrorState = {};

function errorReducer (
  state: IErrorState = initialState,
  action: IAction
): IErrorState {
  const [ domain, request, result ] = action.type.split("/");

  if (result === "ERROR") {
    return {
      ...state,
      [`${domain}/${request}`]: action.payload
    };
  }

  return state;
}

export default errorReducer;
