import * as React from "react";
import { Skeleton } from "@mui/material";
import Typography from "components/Typography";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { useErrorBoard } from "./hooks/useErrorBoard";
import * as styles from "./LastUpdatedIndicator.module.scss";

export function LastUpdatedIndicator() {
  const { t } = useTranslation("maintenance");
  const { state } = useErrorBoard();

  return (
    <div className={styles.LastUpdatedIndicator}>
      <Typography type="body-3" color="onSurfaceMedium">
        {t("lastUpdated")}
      </Typography>

      <Typography type="subtitle-1" color="onSurfaceHigh">
        {state.status === "error" && <span>-</span>}
        {state.status !== "error" && state.data.lastUpdated && (
          <span>
            {moment(state.data.lastUpdated).format("MMM DD, YYYY HH:mm")}
          </span>
        )}
        {state.status !== "error" && !state.data.lastUpdated && (
          <Skeleton width={130} />
        )}
      </Typography>
    </div>
  );
}
