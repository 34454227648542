import * as React from "react";
import { clsx } from "clsx";
import { DayPicker } from "components/DayPicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toasti18n } from "utils/toast";

import { ErrorPlaceHolder } from "./ErrorPlaceHolder";
import { FrequentErrorAccordion } from "./FrequentErrorAccordion";
import { LoadingPlaceHolder } from "./LoadingPlaceHolder";
import { MachineCard } from "./MachineCard";
import { NoDataPlaceHolder } from "./NoDataPlaceHolder";
import { useRealTimeQuery } from "./useRealTimeQuery";

export function RtFail({ className }: { className: string }) {
  const { t } = useTranslation("maintenance", {
    keyPrefix: "rtFail",
  });

  const [date, setDate] = React.useState<Date>(new Date());
  const dateString = moment(date).format("YYYY-MM-DD");
  const realTimeFailResult = useRealTimeQuery(
    {
      date: dateString,
    },
    {
      onError: (error: Error) => {
        toasti18n.error(error);
      },
    }
  );

  return (
    <div className={clsx("w-full h-full flex flex-col", className)}>
      <div className={clsx("flex items-center justify-between mt-2 md:mt-6")}>
        <div>
          <div
            className="text-h5 text-primary-500 leading-6 animate-fade-in-move-up"
            key={dateString}
          >
            {moment(date).format("MMM DD, YYYY")}
          </div>
          <div className="text-body2 leading-5">
            {t("lastUpdated")}{" "}
            {realTimeFailResult.data &&
            moment(date).isSame(realTimeFailResult.data.lastUpdated, "day")
              ? moment(realTimeFailResult.data.lastUpdated).format("HH:mm:ss")
              : "-"}
          </div>
        </div>
        <DayPicker selected={date} onSelect={setDate} />
      </div>
      <div className="flex flex-grow gap-4 mt-2 md:mt-6 min-h-0 overflow-auto snap-x snap-mandatory">
        <div className="shrink-0 md:shrink w-[calc(100%-46px)] md:w-auto md:flex-grow md:basis-0 md:min-w-0 flex flex-col snap-start snap-always">
          <FrequentErrorAccordion
            title={t("swHw")}
            board={realTimeFailResult.data?.softwareHardwareFailBoard}
            isLoading={realTimeFailResult.isLoading}
          />
          <div
            className="min-h-0 overflow-auto grow flex flex-col gap-4 pt-4 hide-scroll-bar"
            key={dateString}
          >
            {realTimeFailResult.data?.softwareHardwareFailBoard.machines
              .length === 0 ? (
              <NoDataPlaceHolder />
            ) : null}

            {realTimeFailResult.isError && !realTimeFailResult.data ? (
              <ErrorPlaceHolder />
            ) : null}

            {realTimeFailResult.isLoading ? <LoadingPlaceHolder /> : null}

            {realTimeFailResult.data?.softwareHardwareFailBoard.machines.map(
              (machine) => (
                <MachineCard key={machine.machineId} machine={machine} />
              )
            )}
          </div>
        </div>
        <div className="shrink-0 md:shrink w-[calc(100%-46px)] md:w-auto md:flex-grow md:basis-0 md:min-w-0 flex flex-col snap-start snap-always hide-scroll-bar">
          <FrequentErrorAccordion
            title={t("inventory")}
            board={realTimeFailResult.data?.inventoryFailBoard}
            isLoading={realTimeFailResult.isLoading}
          />
          <div
            className="min-h-0 overflow-auto grow flex flex-col gap-4 pt-4 hide-scroll-bar"
            key={dateString}
          >
            {realTimeFailResult.isLoading ? <LoadingPlaceHolder /> : null}

            {realTimeFailResult.isError && !realTimeFailResult.data ? (
              <ErrorPlaceHolder />
            ) : null}

            {realTimeFailResult.data?.inventoryFailBoard.machines.length ===
            0 ? (
              <NoDataPlaceHolder />
            ) : null}

            {realTimeFailResult.data?.inventoryFailBoard.machines.map(
              (machine) => (
                <MachineCard key={machine.machineId} machine={machine} />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
