import * as React from "react";
import { Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";

export function LoadingPlaceHolder(): JSX.Element {
  return (
    <>
      <LoadingCard />
      <LoadingCard />
      <LoadingCard />
    </>
  );
}

function LoadingCard() {
  const { t } = useTranslation("maintenance", {
    keyPrefix: "rtFail",
  });

  return (
    <div className="bg-white p-2 [box-shadow:0px_1px_4px_0px_rgba(0,0,0,0.13)]  rounded-[4px] border border-outline animate-fade-in [animation-duration:300ms]">
      <div className="grid [grid-template-columns:70px_1fr] px-[6px] pb-[6px] border-b border-outline ">
        <div className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("machineId")}
        </div>
        <div className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("location")}
        </div>
        <div className="text-subtitle2 leading-6 ">
          <Skeleton width={30} />
        </div>
        <div className="text-subtitle2 leading-4 text-on-surface-high box-border pt-1">
          <Skeleton width={60} />
        </div>
      </div>

      <div className="grid [grid-template-columns:70px_1fr] mt-[6px] px-1 gap-y-[4px]">
        <div className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("time")}
        </div>
        <div className="text-overline tracking-[0.4px] leading-4 text-on-surface-medium">
          {t("status")}
        </div>

        {new Array(4).fill(null).map((_, index) => {
          return (
            <React.Fragment key={index}>
              <div className="flex items-center justify-between self-start">
                <div className="text-body2 leading-5 text-on-surface-medium">
                  <Skeleton width={25} />
                </div>
                <Skeleton
                  className="rounded-full scale-100 w-2.5 h-2.5"
                />
              </div>
              <div className="px-[10px]">
                <div className="text-subtitle2 leading-5 text-on-surface-high">
                  <Skeleton width={80} />
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
