import * as React from "react";
import Icon, { IIconProps } from "common/components/icon/Icon";
import Typography from "common/components/typography/Typography";
import ReactLoading from "react-loading";


import * as styles from "./Button.module.scss";

export interface IButtonProps {
  onClick: React.MouseEventHandler<HTMLDivElement>,
  type: "primary" | "secondary" | "status",
  translationKey?: string,
  className?: string,
  disabled?: boolean,
  preIcon?: IIconProps,
  postIcon?: IIconProps,
  loading?: boolean
}

function Button ({
  translationKey,
  onClick,
  type,
  className,
  disabled,
  preIcon,
  postIcon,
  loading
}: IButtonProps): JSX.Element {
  const loadingState = (
    <ReactLoading
      type='bars'
      height={20}
      width={20}
    />
  );

  const normalState = (
    <>
      {preIcon && (
        <Icon
          className={[
            styles.preIcon,
            !translationKey ? styles.onlyIcon : ""
          ].join(" ")}
          {...preIcon}
        />
      )}
      {translationKey && (
        <Typography
          translationKey={translationKey}
          type='button'
        />
      )}
      {postIcon && (
        <Icon
          className={[
            styles.postIcon,
            !translationKey ? styles.onlyIcon : ""
          ].join(" ")}
          {...postIcon}
        />
      )}
    </>
  );

  return (
    <div
      onClick={onClick}
      className={[
        styles.Button,
        styles[type],
        disabled ? styles.disabled : "",
        loading ? styles.loading : "",
        className
      ].join(" ")}
    >
      {loading && loadingState}
      {!loading && normalState}
    </div>
  );
}

export default Button;
