import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardIceErrorDocument,
  GetMaintenanceBoardIceErrorQuery,
  GetMaintenanceBoardIceErrorQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";


export async function getIceError({
  serviceZoneIds, refillZoneIds, cursor, sort
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardIceErrorQuery,
    GetMaintenanceBoardIceErrorQueryVariables
  >(GetMaintenanceBoardIceErrorDocument, {
    after: cursor,
    refillZoneIds: refillZoneIds,
    serviceZoneIds: serviceZoneIds,
    sort: sort
  })();
}
