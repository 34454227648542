import * as React from "react";

interface ICartProps {
  color?: string;
  className?: string;
}

function Cart ({ color = "currentcolor", className }: ICartProps): JSX.Element {
  return (
    <svg
      className={className}
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.79162 15H18.5417C18.8867 15 19.1667 14.72 19.1667 14.375V8.125C19.1667 7.78 18.8867 7.5 18.5417 7.5H18.3333V3.95818C18.3333 3.61318 18.0533 3.33318 17.7083 3.33318H13.125C12.78 3.33318 12.5 3.61318 12.5 3.95818V7.5H12.0833V0.625C12.0833 0.28 11.8033 0 11.4583 0H4.79162C4.44662 0 4.16662 0.28 4.16662 0.625V14.375C4.16662 14.72 4.44662 15 4.79162 15ZM10.8333 13.75H5.41661V1.25H10.8333V13.75ZM12.0834 13.75H17.9167V8.75H12.0834V13.75ZM17.0833 7.49985H13.75V4.58318H17.0833V7.49985Z'
          fill={color}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8.5992 17.0815L14.733 17.0826C14.3859 17.3882 14.1666 17.8357 14.1666 18.3335C14.1666 19.2527 14.9141 20.0002 15.8333 20.0002C16.7524 20.0002 17.4999 19.2527 17.4999 18.3335C17.4999 17.8359 17.2808 17.3886 16.934 17.0829L19.3167 17.0833C19.6617 17.0833 19.9417 16.8033 19.9417 16.4583C19.9417 16.1133 19.6617 15.8333 19.3167 15.8333L4.375 15.8308C3.80083 15.8308 3.33333 15.3633 3.33333 14.7892V6.45833C3.33333 5.65417 2.67917 5 1.875 5H0.625C0.28 5 0 5.28 0 5.625C0 5.97 0.28 6.25 0.625 6.25H1.875C1.99 6.25 2.08333 6.34333 2.08333 6.45833V14.7892C2.08333 16.0533 3.11083 17.0808 4.375 17.0808L6.40131 17.0812C6.05333 17.3868 5.83338 17.8349 5.83338 18.3335C5.83338 19.2527 6.58088 20.0002 7.50005 20.0002C8.41921 20.0002 9.16671 19.2527 9.16671 18.3335C9.16671 17.8351 8.94694 17.3872 8.5992 17.0815ZM7.08338 18.3335C7.08338 18.1035 7.27088 17.9168 7.50005 17.9168C7.72921 17.9168 7.91671 18.1035 7.91671 18.3335C7.91671 18.5635 7.72921 18.7502 7.50005 18.7502C7.27088 18.7502 7.08338 18.5635 7.08338 18.3335ZM15.8333 17.9168C15.6041 17.9168 15.4166 18.1035 15.4166 18.3335C15.4166 18.5635 15.6041 18.7502 15.8333 18.7502C16.0624 18.7502 16.2499 18.5635 16.2499 18.3335C16.2499 18.1035 16.0624 17.9168 15.8333 17.9168Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width='20' height='20' fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Cart;
