import * as React from "react";

interface IChevronRightProps {
  color?: string;
  className?: string;
}

function ChevronRight({ color = "currentcolor", className }: IChevronRightProps): JSX.Element {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58574 7.99995L4.29285 2.70706L5.70706 1.29285L12.4142 7.99995L5.70706 14.7071L4.29285 13.2928L9.58574 7.99995Z"
        fill={color}
      />
    </svg>
  );
}

export default ChevronRight;
