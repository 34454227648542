import * as React from "react";

interface IMapProps {
  color?: string,
  className?: string
}

function Map ({
  color = "currentcolor",
  className
}: IMapProps): JSX.Element {
  return (
    <svg className={className} width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0 18.7208L7.10794 21.0901L14.0769 17.1078L20 19.477V3.32298L13.9231 0.892212L6.89206 4.90994L0 2.61259V18.7208ZM12 15.9911L8 18.2768V6.58033L12 4.29462V15.9911ZM14 14.923L18 16.523V4.67704L14.0769 3.10781L14 3.15176V14.923ZM2 17.2793L6 18.6126V6.72077L2 5.38744V17.2793Z' fill={color} />
    </svg>
  );
}

export default Map;
