import * as React from "react";

import { MajorTroubleSubNav } from "./MajorTroubleSubNav";
import { MajorTroubleTables } from "./MajorTroubleTables";

export function MajorTroubleView() {
  return (
    <>
      <MajorTroubleSubNav />
      <MajorTroubleTables />
    </>
  );
}
