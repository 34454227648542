// extracted by mini-css-extract-plugin
var _1 = "Login--aa6mGi8TPx";
var _2 = "Warn--cbHFH2lmwi";
var _3 = "#f3f3fd";
var _4 = "#b3b3b3";
var _5 = "#b0b0b0";
var _6 = "card--Shw7f_FgZG";
var _7 = "#a38d80";
var _8 = "#698cc2";
var _9 = "#33568c";
var _a = "#b5c7d5";
var _b = "#f5dcb3";
var _c = "#f18989";
var _d = "content--kNb75l_Vee";
var _e = "#e95d50";
var _f = "#d7d7d7";
var _10 = "#e95d50";
var _11 = "#f89389";
var _12 = "errorMessage--YAhymxDBRd";
var _13 = "googleLogin--FeDo465D2p";
var _14 = "input--S8Z3PmfStF";
var _15 = "#d36b0b";
var _16 = "line--ctSDiEvNLJ";
var _17 = "link--QAnv1IWSA0";
var _18 = "logo--BtOneQ7Vqu";
var _19 = "logoWrapper--TSFgZvmwhL";
var _1a = "#1c1c1c";
var _1b = "#fff";
var _1c = "hsla(0,0%,100%,.74)";
var _1d = "rgba(0,0,0,.38)";
var _1e = "rgba(0,0,0,.87)";
var _1f = "rgba(0,0,0,.6)";
var _20 = "or--FK0w9hUZ5e";
var _21 = "orLine--SY05GrTHvs";
var _22 = "1px solid rgba(0,0,0,.12)";
var _23 = "rgba(0,0,0,.12)";
var _24 = "#4b4d9b";
var _25 = "#eeeef7";
var _26 = "#cbcce6";
var _27 = "#8191d9";
var _28 = "#8688c5";
var _29 = "#6466b4";
var _2a = "#4b4d9b";
var _2b = "#3a3c79";
var _2c = "#292b56";
var _2d = "#191a34";
var _2e = "#080911";
var _2f = "#292b56";
var _30 = "prompt--WQYRQ4iYAx";
var _31 = "#b8e7e3";
var _32 = "#3da49f";
var _33 = "#4cbdb7";
var _34 = "signInButton--uvrRuquzW3";
var _35 = "#27b6ba";
var _36 = "#b2d3d3";
var _37 = "#fff";
var _38 = "title--VIwTWE0fpR";
var _39 = "vendingcity--c65OnrUTND";
var _3a = "#ffb025";
var _3b = "#ffedcc";
export { _1 as "Login", _2 as "Warn", _3 as "background", _4 as "brainStormingBlackTint300", _5 as "captionDisable", _6 as "card", _7 as "chartCoffeeBrown", _8 as "chartCornflowerBlue", _9 as "chartIndigo", _a as "chartLightBlue", _b as "chartPeanutBrown", _c as "chartPink", _d as "content", _e as "critical", _f as "disabled", _10 as "error", _11 as "error2", _12 as "errorMessage", _13 as "googleLogin", _14 as "input", _15 as "investigate", _16 as "line", _17 as "link", _18 as "logo", _19 as "logoWrapper", _1a as "onBackground", _1b as "onPrimaryHigh", _1c as "onPrimaryMedium", _1d as "onSurfaceDisabled", _1e as "onSurfaceHigh", _1f as "onSurfaceMedium", _20 as "or", _21 as "orLine", _22 as "outline", _23 as "outlineColor", _24 as "primary", _25 as "primary050", _26 as "primary100", _27 as "primary200", _28 as "primary300", _29 as "primary400", _2a as "primary500", _2b as "primary600", _2c as "primary700", _2d as "primary800", _2e as "primary900", _2f as "primaryVariant", _30 as "prompt", _31 as "secondary", _32 as "secondary700", _33 as "secondaryVariant", _34 as "signInButton", _35 as "success", _36 as "successOverlay", _37 as "surface", _38 as "title", _39 as "vendingcity", _3a as "warning", _3b as "warningOverlay" }
