import create from "zustand";

interface IAuthStore {
  accessToken: string,
  isLoggedIn: boolean,
  userId: null | string,
  logIn: (userId : string) => void,
  logOut: () => void,
  setAccessToken: (accessToken: string) => void,
}

const useAuthStore = create<IAuthStore>(set => ({
  accessToken: "",
  isLoggedIn: false,
  userId: null,
  logIn: (userId) => set({ isLoggedIn: true, userId }),
  logOut: () => set({ isLoggedIn: false, userId: null }),
  setAccessToken: (accessToken) => set({ accessToken }),
}));

export { useAuthStore };
