import { fetcher } from "gql/fetcher";
import { GetMaintenanceBoardS3ErrorDocument, GetMaintenanceBoardS3ErrorQuery, GetMaintenanceBoardS3ErrorQueryVariables, MaintenanceBoardSortInput } from "gql/generated";

export async function getS3({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardS3ErrorQuery,
    GetMaintenanceBoardS3ErrorQueryVariables
  >(GetMaintenanceBoardS3ErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
