import * as React from "react";
import { Tooltip } from "@mui/material";
import Icon from "common/components/icon/Icon";

export function StaffAtMachineCell({
  staffAtMachine,
  staffName,
}: {
  staffAtMachine: boolean;
  staffName: string | null;
}) {
  if (!staffAtMachine) {
    return <Icon name="StaffNotAtMachine" color="outlineColor" />;
  }
  if (!staffName) {
    return (
      <Icon
        name="StaffAtMachine"
        style={{ marginLeft: "4px" }}
        color="onSurfaceMedium"
      />
    );
  }

  const openParenthesis = staffName.split("").findIndex((c) => c === "(");
  return (
    <Tooltip
      placement="top-start"
      arrow={true}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-12, 0],
              },
            },
          ],
        },
      }}
      title={
        openParenthesis !== -1 ? (
          <>
            <div className="font-kanit text-overline tracking-[0.4px] text-primary-50">
              {staffName.slice(0, openParenthesis)}
            </div>
            <div className="font-kanit text-overline tracking-[0.4px] text-primary-50">
              {staffName.slice(openParenthesis)}
            </div>
          </>
        ) : (
          <div className="font-kanit text-overline tracking-[0.4px] text-primary-50">
            {staffName}
          </div>
        )
      }
    >
      <div>
        <Icon
          name="StaffAtMachine"
          style={{ marginLeft: "4px" }}
          color="onSurfaceMedium"
        />
      </div>
    </Tooltip>
  );
}
