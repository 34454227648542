// extracted by mini-css-extract-plugin
var _1 = "Button--qEtOtbs9is";
var _2 = "#f3f3fd";
var _3 = "#b3b3b3";
var _4 = "#b0b0b0";
var _5 = "#a38d80";
var _6 = "#698cc2";
var _7 = "#33568c";
var _8 = "#b5c7d5";
var _9 = "#f5dcb3";
var _a = "#f18989";
var _b = "#e95d50";
var _c = "disabled--lJu0zMkuoa";
var _d = "#e95d50";
var _e = "#f89389";
var _f = "#d36b0b";
var _10 = "loading--GFXQHHzzJP";
var _11 = "#1c1c1c";
var _12 = "#fff";
var _13 = "hsla(0,0%,100%,.74)";
var _14 = "rgba(0,0,0,.38)";
var _15 = "rgba(0,0,0,.87)";
var _16 = "rgba(0,0,0,.6)";
var _17 = "onlyIcon--N1Uuucjvmb";
var _18 = "1px solid rgba(0,0,0,.12)";
var _19 = "rgba(0,0,0,.12)";
var _1a = "postIcon--WUIoDx9P6o";
var _1b = "preIcon--xU1yzykV7q";
var _1c = "primary--Pcvue_ONdr";
var _1d = "#eeeef7";
var _1e = "#cbcce6";
var _1f = "#8191d9";
var _20 = "#8688c5";
var _21 = "#6466b4";
var _22 = "#4b4d9b";
var _23 = "#3a3c79";
var _24 = "#292b56";
var _25 = "#191a34";
var _26 = "#080911";
var _27 = "#292b56";
var _28 = "secondary--wXKe400CVR";
var _29 = "#3da49f";
var _2a = "#4cbdb7";
var _2b = "status--jug7gT8QBj";
var _2c = "#27b6ba";
var _2d = "#b2d3d3";
var _2e = "#fff";
var _2f = "#ffb025";
var _30 = "#ffedcc";
export { _1 as "Button", _2 as "background", _3 as "brainStormingBlackTint300", _4 as "captionDisable", _5 as "chartCoffeeBrown", _6 as "chartCornflowerBlue", _7 as "chartIndigo", _8 as "chartLightBlue", _9 as "chartPeanutBrown", _a as "chartPink", _b as "critical", _c as "disabled", _d as "error", _e as "error2", _f as "investigate", _10 as "loading", _11 as "onBackground", _12 as "onPrimaryHigh", _13 as "onPrimaryMedium", _14 as "onSurfaceDisabled", _15 as "onSurfaceHigh", _16 as "onSurfaceMedium", _17 as "onlyIcon", _18 as "outline", _19 as "outlineColor", _1a as "postIcon", _1b as "preIcon", _1c as "primary", _1d as "primary050", _1e as "primary100", _1f as "primary200", _20 as "primary300", _21 as "primary400", _22 as "primary500", _23 as "primary600", _24 as "primary700", _25 as "primary800", _26 as "primary900", _27 as "primaryVariant", _28 as "secondary", _29 as "secondary700", _2a as "secondaryVariant", _2b as "status", _2c as "success", _2d as "successOverlay", _2e as "surface", _2f as "warning", _30 as "warningOverlay" }
