/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import createCache from "@emotion/cache";
import { CacheProvider, withEmotionCache } from "@emotion/react";
import { ClientStyleContext } from "components/EmotionProvider/ClientStyleContext";


interface ClientCacheProviderProps {
  children: React.ReactNode;
}
export function ClientEmotionCacheProvider({ children }: ClientCacheProviderProps) {
  const [cache, setCache] = React.useState(createEmotionCache());

  function reset() {
    setCache(createEmotionCache());
  }

  return (
    <ClientStyleContext.Provider value={{ reset }}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

interface DocumentProps {
  children: React.ReactNode;
}

export const EmotionCacheWrapper = withEmotionCache(({ children }: DocumentProps, emotionCache) => {
  const clientStyleData = React.useContext(ClientStyleContext);

  // Only executed on client
  React.useLayoutEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach((tag) => {
      (emotionCache.sheet as any)._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData.reset();
    
  }, []);

  return (
    <>
      {children}
    </>
  );
});


export default function createEmotionCache() {
  return createCache({ key: "custom", prepend: true });
}
