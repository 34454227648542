import React from "react";
import { ArrayElement } from "types/utils";

type SelectedZones = Array<{ id: string; type: "REFILL" | "SERVICE" }>;

type SelectedZoneContextValue = {
  selectedZones: SelectedZones;
  refillZoneIds: string[];
  serviceZoneIds: string[];

  clearSelection: (type?: "REFILL" | "SERVICE") => void;
  deselectZone: (ids: ArrayElement<SelectedZones>["id"]) => void;
  selectServiceZone: (ids: string[]) => void;
  selectRefillZone: (ids: string[]) => void;
};

const SelectedZonesContext =
  React.createContext<SelectedZoneContextValue | null>(null);

export function useSelectedZones() {
  const context = React.useContext(SelectedZonesContext);
  if (!context) {
    throw new Error("useSelectedZonesContext must be used within a provider");
  }
  return context;
}

export function SelectedZonesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [selectedZones, setSelectedZones] = React.useState<SelectedZones>(
    JSON.parse(localStorage.getItem("rt:selectedZones") ?? "[]")
  );
  React.useEffect(() => {
    localStorage.setItem("rt:selectedZones", JSON.stringify(selectedZones));
  }, [selectedZones]);

  const refillZoneIds = selectedZones
    .filter((zone) => zone.type === "REFILL")
    .map((zone) => zone.id);

  const refillZoneIdsMemoized = React.useMemo(
    () => refillZoneIds,
    [refillZoneIds.join("")]
  );

  const serviceZoneIds = selectedZones
    .filter((zone) => zone.type === "SERVICE")
    .map((zone) => zone.id);

  const serviceZoneIdsMemoized = React.useMemo(
    () => serviceZoneIds,
    [serviceZoneIds.join("")]
  );

  function deselectZone(ids: ArrayElement<SelectedZones>["id"]) {
    setSelectedZones((prev) => prev.filter((zone) => !ids.includes(zone.id)));
  }

  function selectRefillZone(ids: string[]) {
    setSelectedZones((prev) => {
      const oldZonesRemoved = prev.filter(
        (zone) => !(zone.type === "REFILL" && !ids.includes(zone.id))
      );
      const newZones = ids
        .filter((id) => !prev.some((zone) => zone.id === id))
        .map((id) => ({ id, type: "REFILL" as const }));

      return [...oldZonesRemoved, ...newZones];
    });
  }

  function selectServiceZone(ids: string[]) {
    setSelectedZones((prev) => {
      const oldZonesRemoved = prev.filter(
        (zone) => !(zone.type === "SERVICE" && !ids.includes(zone.id))
      );
      const newZones = ids
        .filter((id) => !prev.some((zone) => zone.id === id))
        .map((id) => ({ id, type: "SERVICE" as const }));
      return [...oldZonesRemoved, ...newZones];
    });
  }

  function clearSelection(type: "REFILL" | "SERVICE") {
    if (!type) {
      setSelectedZones([]);
      return;
    }
    setSelectedZones((prev) => prev.filter((zone) => zone.type !== type));
  }

  return (
    <SelectedZonesContext.Provider
      value={{
        selectedZones,
        refillZoneIds: refillZoneIdsMemoized,
        serviceZoneIds: serviceZoneIdsMemoized,
        deselectZone,
        selectServiceZone,
        selectRefillZone,
        clearSelection,
      }}
    >
      {children}
    </SelectedZonesContext.Provider>
  );
}
