import * as React from "react";

interface IAlertRefreshProps {
  color?: string;
  className?: string;
}

function AlertRefresh({
  color = "currentcolor",
  className,
}: IAlertRefreshProps): JSX.Element {
  return (
    <svg
      className={className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33331 4.99251H4.12869C5.03567 3.71164 6.40423 2.99251 7.99998 2.99251C10.9455 2.99251 13.3333 5.38033 13.3333 8.32585H14.6666C14.6666 4.64395 11.6819 1.65918 7.99998 1.65918C6.13049 1.65918 4.48338 2.44752 3.33331 3.83941V2.99251H1.99998V6.32585H5.33331V4.99251ZM10.6666 11.6592H11.8713C10.9643 12.94 9.59573 13.6592 7.99998 13.6592C5.05446 13.6592 2.66665 11.2714 2.66665 8.32585H1.33331C1.33331 12.0077 4.31808 14.9925 7.99998 14.9925C9.86947 14.9925 11.5166 14.2042 12.6666 12.8123V13.6592H14V10.3258H10.6666V11.6592ZM8.0002 10.9914C8.36851 10.9914 8.66708 10.6929 8.66708 10.3247C8.66708 9.95655 8.36851 9.65808 8.0002 9.65808C7.63189 9.65808 7.33331 9.95655 7.33331 10.3247C7.33331 10.6929 7.63189 10.9914 8.0002 10.9914ZM8.66905 4.99141H7.33528V8.99141H8.66905V4.99141Z"
        fill={color}
      />
    </svg>
  );
}

export default AlertRefresh;
