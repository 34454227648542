import * as React from "react";
import { Tooltip } from "@mui/material";
import Typography from "components/Typography";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";

export function ErrorCodeCell({
  errorCode,
  descriptionTh,
  descriptionEn,
}: {
  errorCode: string;
  descriptionTh: string | null;
  descriptionEn: string | null;
}) {
  const { i18n } = useTranslation();
  const description = i18n.language === "th" ? descriptionTh : descriptionEn;

  return (
    <Tooltip
      placement="top-start"
      arrow={true}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [-12, 0],
              },
            },
          ],
        },
      }}
      title={
        description ? (
          <div className="font-kanit text-overline tracking-[0.4px] text-primary-50">
            {capitalize(description)}
          </div>
        ) : null
      }
    >
      <div>
        <Typography type="subtitle-2" color="error">
          {errorCode}
        </Typography>
      </div>
    </Tooltip>
  );
}
