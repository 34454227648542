// extracted by mini-css-extract-plugin
var _1 = "Disabled--zwDsUKdCwL";
var _2 = "Icon--RMkDwbL7rw";
var _3 = "IconLoading--jhFPJocMwL";
var _4 = "Label--eTEvsa57fc";
var _5 = "LabelLoading--Inehfq9Eg5";
var _6 = "LoadingIcon--jHphAXtYjd";
var _7 = "Primary--QKm_WepUER";
var _8 = "Secondary--PX53MTq4P9";
var _9 = "Status--_zK2jlvEjF";
var _a = "#f3f3fd";
var _b = "#b3b3b3";
var _c = "#b0b0b0";
var _d = "#a38d80";
var _e = "#698cc2";
var _f = "#33568c";
var _10 = "#b5c7d5";
var _11 = "#f5dcb3";
var _12 = "#f18989";
var _13 = "#e95d50";
var _14 = "#d7d7d7";
var _15 = "#e95d50";
var _16 = "#f89389";
var _17 = "#d36b0b";
var _18 = "large--nA9RzZ11vF";
var _19 = "medium--GjSVqYk9Pt";
var _1a = "#1c1c1c";
var _1b = "#fff";
var _1c = "hsla(0,0%,100%,.74)";
var _1d = "rgba(0,0,0,.38)";
var _1e = "rgba(0,0,0,.87)";
var _1f = "rgba(0,0,0,.6)";
var _20 = "1px solid rgba(0,0,0,.12)";
var _21 = "rgba(0,0,0,.12)";
var _22 = "#4b4d9b";
var _23 = "#eeeef7";
var _24 = "#cbcce6";
var _25 = "#8191d9";
var _26 = "#8688c5";
var _27 = "#6466b4";
var _28 = "#4b4d9b";
var _29 = "#3a3c79";
var _2a = "#292b56";
var _2b = "#191a34";
var _2c = "#080911";
var _2d = "#292b56";
var _2e = "#b8e7e3";
var _2f = "#3da49f";
var _30 = "#4cbdb7";
var _31 = "small--DfILtDgwFq";
var _32 = "#27b6ba";
var _33 = "#b2d3d3";
var _34 = "#fff";
var _35 = "#ffb025";
var _36 = "#ffedcc";
export { _1 as "Disabled", _2 as "Icon", _3 as "IconLoading", _4 as "Label", _5 as "LabelLoading", _6 as "LoadingIcon", _7 as "Primary", _8 as "Secondary", _9 as "Status", _a as "background", _b as "brainStormingBlackTint300", _c as "captionDisable", _d as "chartCoffeeBrown", _e as "chartCornflowerBlue", _f as "chartIndigo", _10 as "chartLightBlue", _11 as "chartPeanutBrown", _12 as "chartPink", _13 as "critical", _14 as "disabled", _15 as "error", _16 as "error2", _17 as "investigate", _18 as "large", _19 as "medium", _1a as "onBackground", _1b as "onPrimaryHigh", _1c as "onPrimaryMedium", _1d as "onSurfaceDisabled", _1e as "onSurfaceHigh", _1f as "onSurfaceMedium", _20 as "outline", _21 as "outlineColor", _22 as "primary", _23 as "primary050", _24 as "primary100", _25 as "primary200", _26 as "primary300", _27 as "primary400", _28 as "primary500", _29 as "primary600", _2a as "primary700", _2b as "primary800", _2c as "primary900", _2d as "primaryVariant", _2e as "secondary", _2f as "secondary700", _30 as "secondaryVariant", _31 as "small", _32 as "success", _33 as "successOverlay", _34 as "surface", _35 as "warning", _36 as "warningOverlay" }
