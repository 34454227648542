import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardIceRateErrorDocument,
  GetMaintenanceBoardIceRateErrorQuery,
  GetMaintenanceBoardIceRateErrorQueryVariables,
  MaintenanceBoardSortInput
} from "gql/generated";


export async function getIceRateLow({
  serviceZoneIds, refillZoneIds, cursor, sort
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardIceRateErrorQuery,
    GetMaintenanceBoardIceRateErrorQueryVariables
  >(GetMaintenanceBoardIceRateErrorDocument, {
    after: cursor,
    refillZoneIds: refillZoneIds,
    serviceZoneIds: serviceZoneIds,
    sort: sort
  })();
}
