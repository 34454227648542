import { fetcher } from "gql/fetcher";
import { GetMaintenanceBoardP10ErrorDocument, GetMaintenanceBoardP10ErrorQuery, GetMaintenanceBoardP10ErrorQueryVariables, MaintenanceBoardSortInput } from "gql/generated";

export async function getP10({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardP10ErrorQuery,
    GetMaintenanceBoardP10ErrorQueryVariables
  >(GetMaintenanceBoardP10ErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
