import * as React from "react";

interface IGridProps {
  color?: string,
  className?: string
}

function Grid ({
  color = "currentcolor",
  className
}: IGridProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M3 3H10V10H3V3Z' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M14 3H21V10H14V3Z' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M14 14H21V21H14V14Z' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M3 14H10V21H3V14Z' stroke={color} strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  );
}

export default Grid;
