import * as React from "react";
import Icon from "common/components/icon/Icon";
import { useTranslation } from "react-i18next";

export function NoDataPlaceHolder() {
  const { t } = useTranslation("maintenance", {
    keyPrefix: "rtFail",
  });

  return (
    <div className="h-full flex flex-col items-center justify-center bg-white rounded-lg border box-border border-on-surface-disabled [box-shadow:0px_1px_4px_0px_rgba(0,0,0,0.13)] animate-fade-in">
      <Icon
        name="EyeNo"
        color="onSurfaceDisabled"
        className="w-[28x] h-[28px]"
      />
      <div className="text-h6 text-on-surface-high font-normal">
        {t("noDataToShow")}
      </div>
      <div className="text-subtitle2 leading-6 text-on-surface-medium text-center whitespace-pre">
        {t("noDataToShowDescription")}
      </div>
    </div>
  );
}
