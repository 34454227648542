import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardOtherErrorDocument,
  GetMaintenanceBoardOtherErrorQuery,
  GetMaintenanceBoardOtherErrorQueryVariables,
  MaintenanceBoardSortInput,
} from "gql/generated";

export async function getOtherError({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardOtherErrorQuery,
    GetMaintenanceBoardOtherErrorQueryVariables
  >(GetMaintenanceBoardOtherErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
