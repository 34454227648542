import { fetcher } from "gql/fetcher";
import { GetMaintenanceBoardS2ErrorDocument, GetMaintenanceBoardS2ErrorQuery, GetMaintenanceBoardS2ErrorQueryVariables, MaintenanceBoardSortInput } from "gql/generated";

export async function getS2({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardS2ErrorQuery,
    GetMaintenanceBoardS2ErrorQueryVariables
  >(GetMaintenanceBoardS2ErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
