import * as React from "react";

interface IPlusProps {
  color?: string,
  className?: string
}

function Plus ({
  color = "currentcolor",
  className
}: IPlusProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z' fill={color} />
    </svg>
  );
}

export default Plus;
