// extracted by mini-css-extract-plugin
var _1 = "App--IMqMrT2eGO";
var _2 = "#f3f3fd";
var _3 = "#b3b3b3";
var _4 = "#b0b0b0";
var _5 = "#a38d80";
var _6 = "#698cc2";
var _7 = "#33568c";
var _8 = "#b5c7d5";
var _9 = "#f5dcb3";
var _a = "#f18989";
var _b = "#e95d50";
var _c = "#d7d7d7";
var _d = "#e95d50";
var _e = "#f89389";
var _f = "#d36b0b";
var _10 = "#1c1c1c";
var _11 = "#fff";
var _12 = "hsla(0,0%,100%,.74)";
var _13 = "rgba(0,0,0,.38)";
var _14 = "rgba(0,0,0,.87)";
var _15 = "rgba(0,0,0,.6)";
var _16 = "1px solid rgba(0,0,0,.12)";
var _17 = "rgba(0,0,0,.12)";
var _18 = "#4b4d9b";
var _19 = "#eeeef7";
var _1a = "#cbcce6";
var _1b = "#8191d9";
var _1c = "#8688c5";
var _1d = "#6466b4";
var _1e = "#4b4d9b";
var _1f = "#3a3c79";
var _20 = "#292b56";
var _21 = "#191a34";
var _22 = "#080911";
var _23 = "#292b56";
var _24 = "#b8e7e3";
var _25 = "#3da49f";
var _26 = "#4cbdb7";
var _27 = "#27b6ba";
var _28 = "#b2d3d3";
var _29 = "#fff";
var _2a = "#ffb025";
var _2b = "#ffedcc";
export { _1 as "App", _2 as "background", _3 as "brainStormingBlackTint300", _4 as "captionDisable", _5 as "chartCoffeeBrown", _6 as "chartCornflowerBlue", _7 as "chartIndigo", _8 as "chartLightBlue", _9 as "chartPeanutBrown", _a as "chartPink", _b as "critical", _c as "disabled", _d as "error", _e as "error2", _f as "investigate", _10 as "onBackground", _11 as "onPrimaryHigh", _12 as "onPrimaryMedium", _13 as "onSurfaceDisabled", _14 as "onSurfaceHigh", _15 as "onSurfaceMedium", _16 as "outline", _17 as "outlineColor", _18 as "primary", _19 as "primary050", _1a as "primary100", _1b as "primary200", _1c as "primary300", _1d as "primary400", _1e as "primary500", _1f as "primary600", _20 as "primary700", _21 as "primary800", _22 as "primary900", _23 as "primaryVariant", _24 as "secondary", _25 as "secondary700", _26 as "secondaryVariant", _27 as "success", _28 as "successOverlay", _29 as "surface", _2a as "warning", _2b as "warningOverlay" }
