import * as React from "react";

interface ITimelineProps {
  color?: string;
  className?: string;
}

function Timeline({ color = "currentcolor", className }: ITimelineProps): JSX.Element {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.93605 13.2222C7.93605 12.2724 7.35158 11.4688 6.47486 11.1765V8.69252C7.35158 8.40028 7.93605 7.59663 7.93605 6.64686C7.93605 5.40485 6.98628 4.45508 5.74427 4.45508C4.50226 4.45508 3.55249 5.40485 3.55249 6.64686C3.55249 7.59663 4.13697 8.40028 5.01368 8.69252V11.1765C4.13697 11.4688 3.55249 12.2724 3.55249 13.2222C3.55249 14.172 4.13697 14.9756 5.01368 15.2679V17.7519C4.13697 18.0441 3.55249 18.8478 3.55249 19.7975C3.55249 21.0396 4.50226 21.9893 5.74427 21.9893C6.98628 21.9893 7.93605 21.0396 7.93605 19.7975C7.93605 18.8478 7.35158 18.0441 6.47486 17.7519V15.2679C7.35158 14.9756 7.93605 14.172 7.93605 13.2222ZM23.2785 7.37745H11.589C11.1507 7.37745 10.8584 7.08522 10.8584 6.64686C10.8584 6.2085 11.1507 5.91627 11.589 5.91627H23.2785C23.7169 5.91627 24.0091 6.2085 24.0091 6.64686C24.0091 7.08522 23.7169 7.37745 23.2785 7.37745ZM23.2785 13.9528H11.589C11.1507 13.9528 10.8584 13.6606 10.8584 13.2222C10.8584 12.7838 11.1507 12.4916 11.589 12.4916H23.2785C23.7169 12.4916 24.0091 12.7838 24.0091 13.2222C24.0091 13.6606 23.7169 13.9528 23.2785 13.9528ZM23.2785 20.5281H11.589C11.1507 20.5281 10.8584 20.2359 10.8584 19.7975C10.8584 19.3592 11.1507 19.067 11.589 19.067H23.2785C23.7169 19.067 24.0091 19.3592 24.0091 19.7975C24.0091 20.2359 23.7169 20.5281 23.2785 20.5281Z"
        fill={color}
      />
    </svg>
  );
}

export default Timeline;
