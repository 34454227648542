import * as React from "react";

interface ICircleCheckProps {
  color?: string,
  className?: string
}

function CircleCheck ({
  color = "currentcolor",
  className
}: ICircleCheckProps): JSX.Element {
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.99984 15.3334C3.94975 15.3334 0.666504 12.0502 0.666504 8.00008C0.666504 3.94999 3.94975 0.666748 7.99984 0.666748C12.0499 0.666748 15.3332 3.94999 15.3332 8.00008C15.3332 12.0502 12.0499 15.3334 7.99984 15.3334ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM10.1953 5.52865L6.66672 9.05724L5.13812 7.52865L4.19531 8.47146L6.66672 10.9429L11.1381 6.47146L10.1953 5.52865Z" fill={color} />
    </svg>
  );
}

export default CircleCheck;
