import * as React from "react";

interface IBottomBoardProps {
  color?: string;
  className?: string;
}

function BottomBoard({
  color = "currentcolor",
  className,
}: IBottomBoardProps): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.35602 13.9948L12.4241 13.9948V11.6387L5.35602 11.6387L5.35602 13.9948ZM4.57068 17.1361C4.13874 17.1361 3.76898 16.9823 3.46139 16.6747C3.1538 16.3671 3 15.9974 3 15.5654L3 4.57068C3 4.13874 3.1538 3.76898 3.46139 3.46139C3.76898 3.1538 4.13874 3 4.57068 3L15.5654 3C15.9974 3 16.3671 3.1538 16.6747 3.46139C16.9823 3.76898 17.1361 4.13874 17.1361 4.57068V15.5654C17.1361 15.9974 16.9823 16.3671 16.6747 16.6747C16.3671 16.9823 15.9974 17.1361 15.5654 17.1361H4.57068ZM4.57068 15.5654L15.5654 15.5654L15.5654 4.57068L4.57068 4.57068L4.57068 15.5654Z"
        fill={color}
      />
      <rect
        x="10.8516"
        y="11.6387"
        width="3.9267"
        height="2.35602"
        fill={color}
      />
    </svg>
  );
}

export default BottomBoard;
