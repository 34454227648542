import * as React from "react";
import { useTranslation } from "react-i18next";

import { Mode } from "../types";
import * as styles from "./ModeBadge.module.scss";

export function ModeBadge({ status }: { status: Mode | undefined }) {
  const { t } = useTranslation("maintenance");

  if (!status || !COLOR_AND_TRANSLATION_MAP[status]) {
    return <span className={styles.ModeBadge}>{status}</span>;
  }

  return (
    <span
      className={styles.ModeBadge}
      style={COLOR_AND_TRANSLATION_MAP[status].style}
    >
      {t(COLOR_AND_TRANSLATION_MAP[status].translation)}
    </span>
  );
}

const COLOR_AND_TRANSLATION_MAP: Record<
  Mode,
  { style: React.CSSProperties; translation: string }
> = {
  idle: {
    style: {
      color: "rgba(0, 0, 0, 0.87)",
      backgroundColor: "#FFEDCC",
    },
    translation: "modeStates.idle",
  },
  make_ice: {
    style: {
      color: "rgba(0, 0, 0, 0.60)",
      boxShadow: "0px 0px 0px 1px rgba(102, 102, 102, 0.16) inset",
    },

    translation: "modeStates.makeIce",
  },
  defrost: {
    style: {
      color: "rgba(0, 0, 0, 0.87)",
      backgroundColor: "rgba(0, 0, 0, 0.24)"
    },

    translation: "modeStates.defrost",
  },
  make_soda: {
    style: {
      color: "rgba(0, 0, 0, 0.87)",
      backgroundColor: "#B2D3D3",
    },
    translation: "modeStates.makeSoda",
  },
  closed: {
    style: {
      color: "#E95D50",
      boxShadow: "0px 0px 0px 1px #E95D50 inset",
    },
    translation: "modeStates.closed",
  },
};
