import * as React from "react";

interface IFolderPhotoProps {
  color?: string;
  className?: string;
}

function FolderPhoto ({
  color = "currentcolor",
  className
}: IFolderPhotoProps): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="24 / file / folder-photo">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21 5C22.1046 5 23 5.89543 23 7V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3H9C10.12 3 10.8329 3.47545 11.549 4.37885C11.5688 4.40385 11.6171 4.46608 11.6706 4.535L11.6707 4.53515C11.7461 4.63236 11.8318 4.74283 11.8625 4.78082C11.8935 4.81925 11.9196 4.85103 11.9412 4.8773L11.9412 4.87732C12.0292 4.98428 12.0419 4.99978 12.0018 5H21ZM21 19V7L11.9946 6.99999C11.2765 6.99614 10.8087 6.65991 10.3058 6.03654C10.2603 5.98006 10.1496 5.83737 10.0692 5.73376L10.0692 5.73375L10.0691 5.73357C10.0271 5.67944 9.99336 5.636 9.9816 5.62116C9.60702 5.14857 9.38424 5 9 5H3V19H21ZM15 13C15 14.6569 13.6569 16 12 16C10.3431 16 9 14.6569 9 13C9 11.3431 10.3431 10 12 10C13.6569 10 15 11.3431 15 13ZM7 13C7 15.7614 9.23858 18 12 18C14.7614 18 17 15.7614 17 13C17 10.2386 14.7614 8 12 8C9.23858 8 7 10.2386 7 13ZM18 9C18 9.55228 18.4477 10 19 10C19.5523 10 20 9.55228 20 9C20 8.44772 19.5523 8 19 8C18.4477 8 18 8.44772 18 9Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default FolderPhoto;
