import * as React from "react";

interface IChevronThickProps {
  color?: string,
  className?: string
}

function ChevronThick ({
  color = "currentcolor",
  className
}: IChevronThickProps): JSX.Element {
  return (
    <svg className={className} width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M19.2928 7.79289L20.7071 9.20711L12 17.9142L3.29285 9.20711L4.70706 7.79289L12 15.0858L19.2928 7.79289Z' fill={color} />
    </svg>
  );
}

export default ChevronThick;
