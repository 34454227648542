import * as React from "react";

interface ICreditCardProps {
  color?: string,
  className?: string
}

function CreditCard ({
  color = "currentcolor",
  className
}: ICreditCardProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M2.81818 3H21.1818C22.186 3 23 3.89543 23 5V17C23 18.1046 22.186 19 21.1818 19H2.81818C1.81403 19 1 18.1046 1 17V5C1 3.89543 1.81403 3 2.81818 3ZM3 5V17H21V5H3ZM5 13V15H7V13H5ZM8 15V13H11V15H8ZM17 13V15H19V13H17ZM14 15V13H16V15H14ZM8 8H5V11H8V8Z' fill={color} />
    </svg>
  );
}

export default CreditCard;
