/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from "react";
import * as React from "react";
import { GoogleCredentialResponse } from "@react-oauth/google";
import Button from "common/components/button/Button";
import GoogleLogin from "common/components/googlelogin/GoogleLogin";
import Icon from "common/components/icon/Icon";
import Input from "common/components/input/Input";
import Typography from "common/components/typography/Typography";
import config from "common/config";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useAuthStore } from "userandauth/useAuthStore";
import { signup } from "userandauth/utilsAuth";
import { toasti18n } from "utils/toast";

import * as styles from "./Signup.module.scss";

function SignUp(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const setupToken = params.get("setupToken");

  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  async function handlePasswordSignUp(): Promise<void> {
    try {
      setIsLoading(true);
      await signup({
        loginWith: "password",
        postBody: {
          password,
          setupToken,
        },
      });
      // should do something else here that let the user know that the account setup is successful
      // instead of redirecting them to the login page
      navigate((location as any).state?.from?.pathname ?? "/", {replace: true});
    } catch {
      toasti18n.error({ message: "toast_login_failed" });
      setIsLoading(false);
    }
  }

  async function handleGoogleSignUp(googleObject: GoogleCredentialResponse): Promise<void> {
    try {
      await signup({
        loginWith: "google",
        postBody: {
          accessToken: googleObject.credential,
          setupToken,
        },
      });
      // should do something else here that let the user know that the account setup is successful
      // instead of redirecting them to the login page
      navigate((location as any).state?.from?.pathname ?? "/", { replace: true });
    } catch {
      toasti18n.error({ message: "toast_login_failed" });
    }
  }

  function isCorrectFormat(password: string) {
    return password.length >= 8 && password.search(/[A-Z]/) >= 0;
  }

  return (
    <div className={styles.Login}>
      <div className={styles.content}>
        <div className={styles.logoWrapper}>
          <Icon name="Vendii" className="w-full h-auto block text-white" />
        </div>
        <div className={styles.card}>
          <Typography
            type="headline-6"
            translationKey="signup_title"
            className={styles.title}
          />

          {config.GOOGLE_CLIENT_ID && (
            <>
              <GoogleLogin
                onSuccess={handleGoogleSignUp}
                onError={() => alert(JSON.stringify("Something went wrong"))}
              />

              <div className={styles.orLine}>
                <div className={styles.line} />
                <Typography
                  type="body-1"
                  translationKey="label_or"
                  className={styles.or}
                />
                <div className={styles.line} />
              </div>
            </>
          )}

          <Input
            value={password}
            onChange={setPassword}
            type="password"
            label="login_password_placeholder"
            className={styles.input}
          />

          <Input
            value={confirmPassword}
            onChange={setConfirmPassword}
            type="password"
            label="login_confirm_password_placeholder"
            className={styles.input}
          />

          <Button
            className={styles.signInButton}
            translationKey="login_finish_sign_up"
            type="primary"
            onClick={handlePasswordSignUp}
            disabled={
              confirmPassword === "" ||
              password === "" ||
              confirmPassword !== password ||
              !isCorrectFormat(password) ||
              !isCorrectFormat(confirmPassword)
            }
            loading={isLoading}
          />
          {confirmPassword !== password && (
            <div>
              <Typography
                type="subtitle-1"
                translationKey="please_match_password"
                className={styles.Warn}
              />
            </div>
          )}
          {!isCorrectFormat(password) && !isCorrectFormat(confirmPassword) && (
            <div>
              <Typography
                type="subtitle-1"
                translationKey="password_condition"
                className={styles.Warn}
              />
            </div>
          )}
        </div>
      </div>

      <img alt="vending city" className={styles.vendingcity} src="/images/vendingcity.png" />
    </div>
  );
}

export default SignUp;
