// extracted by mini-css-extract-plugin
var _1 = "#f3f3fd";
var _2 = "#b3b3b3";
var _3 = "#b0b0b0";
var _4 = "#a38d80";
var _5 = "#698cc2";
var _6 = "#33568c";
var _7 = "#b5c7d5";
var _8 = "#f5dcb3";
var _9 = "#f18989";
var _a = "#e95d50";
var _b = "#d7d7d7";
var _c = "#e95d50";
var _d = "#f89389";
var _e = "#d36b0b";
var _f = "#1c1c1c";
var _10 = "#fff";
var _11 = "hsla(0,0%,100%,.74)";
var _12 = "rgba(0,0,0,.38)";
var _13 = "rgba(0,0,0,.87)";
var _14 = "rgba(0,0,0,.6)";
var _15 = "1px solid rgba(0,0,0,.12)";
var _16 = "rgba(0,0,0,.12)";
var _17 = "#4b4d9b";
var _18 = "#eeeef7";
var _19 = "#cbcce6";
var _1a = "#8191d9";
var _1b = "#8688c5";
var _1c = "#6466b4";
var _1d = "#4b4d9b";
var _1e = "#3a3c79";
var _1f = "#292b56";
var _20 = "#191a34";
var _21 = "#080911";
var _22 = "#292b56";
var _23 = "#b8e7e3";
var _24 = "#3da49f";
var _25 = "#4cbdb7";
var _26 = "#27b6ba";
var _27 = "#b2d3d3";
var _28 = "#fff";
var _29 = "#ffb025";
var _2a = "#ffedcc";
export { _1 as "background", _2 as "brainStormingBlackTint300", _3 as "captionDisable", _4 as "chartCoffeeBrown", _5 as "chartCornflowerBlue", _6 as "chartIndigo", _7 as "chartLightBlue", _8 as "chartPeanutBrown", _9 as "chartPink", _a as "critical", _b as "disabled", _c as "error", _d as "error2", _e as "investigate", _f as "onBackground", _10 as "onPrimaryHigh", _11 as "onPrimaryMedium", _12 as "onSurfaceDisabled", _13 as "onSurfaceHigh", _14 as "onSurfaceMedium", _15 as "outline", _16 as "outlineColor", _17 as "primary", _18 as "primary050", _19 as "primary100", _1a as "primary200", _1b as "primary300", _1c as "primary400", _1d as "primary500", _1e as "primary600", _1f as "primary700", _20 as "primary800", _21 as "primary900", _22 as "primaryVariant", _23 as "secondary", _24 as "secondary700", _25 as "secondaryVariant", _26 as "success", _27 as "successOverlay", _28 as "surface", _29 as "warning", _2a as "warningOverlay" }
