import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardSpiralErrorDocument,
  GetMaintenanceBoardSpiralErrorQuery,
  GetMaintenanceBoardSpiralErrorQueryVariables,
  MaintenanceBoardSortInput,
} from "gql/generated";

export async function getSpiralUnsellableError({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return await fetcher<
    GetMaintenanceBoardSpiralErrorQuery,
    GetMaintenanceBoardSpiralErrorQueryVariables
  >(GetMaintenanceBoardSpiralErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
