// extracted by mini-css-extract-plugin
var _1 = "TopNav--BXptTar1hV";
var _2 = "ViewAllContainer--W7N5AjT_h0";
var _3 = "avatar--kwDjZJw24A";
var _4 = "#f3f3fd";
var _5 = "#b3b3b3";
var _6 = "button--UlAUB7Bi6l";
var _7 = "#b0b0b0";
var _8 = "#a38d80";
var _9 = "#698cc2";
var _a = "#33568c";
var _b = "#b5c7d5";
var _c = "#f5dcb3";
var _d = "#f18989";
var _e = "#e95d50";
var _f = "#d7d7d7";
var _10 = "#e95d50";
var _11 = "#f89389";
var _12 = "iconContainer--kjgwJ2vauS";
var _13 = "image--UoiB0mjh8o";
var _14 = "#d36b0b";
var _15 = "#1c1c1c";
var _16 = "#fff";
var _17 = "hsla(0,0%,100%,.74)";
var _18 = "rgba(0,0,0,.38)";
var _19 = "rgba(0,0,0,.87)";
var _1a = "rgba(0,0,0,.6)";
var _1b = "orgName--tBzeWThjoK";
var _1c = "organisation--ILm29mlUzy";
var _1d = "organisationPicture--k9s7kXNDIT";
var _1e = "1px solid rgba(0,0,0,.12)";
var _1f = "rgba(0,0,0,.12)";
var _20 = "#4b4d9b";
var _21 = "#eeeef7";
var _22 = "#cbcce6";
var _23 = "#8191d9";
var _24 = "#8688c5";
var _25 = "#6466b4";
var _26 = "#4b4d9b";
var _27 = "#3a3c79";
var _28 = "#292b56";
var _29 = "#191a34";
var _2a = "#080911";
var _2b = "#292b56";
var _2c = "#b8e7e3";
var _2d = "#3da49f";
var _2e = "#4cbdb7";
var _2f = "select--AR8D0HAX3g";
var _30 = "#27b6ba";
var _31 = "#b2d3d3";
var _32 = "#fff";
var _33 = "toggleButtonText--S39f6ZsXIF";
var _34 = "user--gL2_Tsm87l";
var _35 = "#ffb025";
var _36 = "#ffedcc";
export { _1 as "TopNav", _2 as "ViewAllContainer", _3 as "avatar", _4 as "background", _5 as "brainStormingBlackTint300", _6 as "button", _7 as "captionDisable", _8 as "chartCoffeeBrown", _9 as "chartCornflowerBlue", _a as "chartIndigo", _b as "chartLightBlue", _c as "chartPeanutBrown", _d as "chartPink", _e as "critical", _f as "disabled", _10 as "error", _11 as "error2", _12 as "iconContainer", _13 as "image", _14 as "investigate", _15 as "onBackground", _16 as "onPrimaryHigh", _17 as "onPrimaryMedium", _18 as "onSurfaceDisabled", _19 as "onSurfaceHigh", _1a as "onSurfaceMedium", _1b as "orgName", _1c as "organisation", _1d as "organisationPicture", _1e as "outline", _1f as "outlineColor", _20 as "primary", _21 as "primary050", _22 as "primary100", _23 as "primary200", _24 as "primary300", _25 as "primary400", _26 as "primary500", _27 as "primary600", _28 as "primary700", _29 as "primary800", _2a as "primary900", _2b as "primaryVariant", _2c as "secondary", _2d as "secondary700", _2e as "secondaryVariant", _2f as "select", _30 as "success", _31 as "successOverlay", _32 as "surface", _33 as "toggleButtonText", _34 as "user", _35 as "warning", _36 as "warningOverlay" }
