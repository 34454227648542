import * as React from "react";

interface IAlarmClockProps {
  color?: string;
  className?: string;
}

function AlarmClock({
  color = "white",
  className,
}: IAlarmClockProps): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="24 / time / alarm-checked">
        <path
          id="icon"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.79285 1.29297L6.20706 2.70718L2.70706 6.20718L1.29285 4.79297L4.79285 1.29297ZM22.7071 4.79297L19.2071 1.29297L17.7928 2.70718L21.2928 6.20718L22.7071 4.79297ZM12 22.0001C6.47711 22.0001 1.99995 17.5229 1.99995 12.0001C1.99995 6.47723 6.47711 2.00008 12 2.00008C17.5228 2.00008 22 6.47723 22 12.0001C22 17.5229 17.5228 22.0001 12 22.0001ZM12 20.0001C16.4182 20.0001 20 16.4184 20 12.0001C20 7.5818 16.4182 4.00008 12 4.00008C7.58168 4.00008 3.99995 7.5818 3.99995 12.0001C3.99995 16.4184 7.58168 20.0001 12 20.0001ZM15.2928 8.29297L11 12.5859L8.70706 10.293L7.29285 11.7072L11 15.4143L16.7071 9.70718L15.2928 8.29297Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export default AlarmClock;
