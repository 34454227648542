/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useState } from "react";
import { GoogleCredentialResponse } from "@react-oauth/google";
import Button from "common/components/button/Button";
import GoogleLogin from "common/components/googlelogin/GoogleLogin";
import Icon from "common/components/icon/Icon";
import Typography from "common/components/typography/Typography";
import config from "common/config";
import i18n from "common/i18n";
import TextField from "components/TextField";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation, Navigate, Link } from "react-router-dom";
import {
  AUTH_ERROR_TYPE,
  AuthErrorType,
} from "userandauth/servicesAuth";
import { useAuthStore } from "userandauth/useAuthStore";
import { login } from "userandauth/utilsAuth";
import { setSentryUser } from "utils/sentryUserTracking";
import { toasti18n } from "utils/toast";
import { userTracking } from "utils/userTracking";

const errorTranslations: Record<AuthErrorType, string> = {
  [AUTH_ERROR_TYPE.InvalidUserOrPassword]:
    "toast_invalid_login_or_password_error",
  [AUTH_ERROR_TYPE.ServerError]: "toast_failed_signin_server_error",
  [AUTH_ERROR_TYPE.SigninWithGoogleError]:
    "toast_failed_signin_with_google_error",
  [AUTH_ERROR_TYPE.NetworkError]: "toast_network_unknown_error",
  [AUTH_ERROR_TYPE.UnknownAuthError]: "toast_network_unknown_error",
};

function LoginSection(): JSX.Element {
  const { t } = useTranslation("authentication", {
    keyPrefix: "forgot_password"
  });
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  async function handlePasswordLogin(): Promise<void> {
    try {
      setIsLoading(true);
      const { userInfo } = await login({
        loginWith: "password",
        postBody: { email: email?.toLowerCase(), password },
      });
      await userTracking(userInfo);
      await setSentryUser(userInfo);
      navigate((location as any).state?.from?.pathname ?? "/");
    } catch (err) {
      if (err.name === "AuthError") {         
        const errMessage: string = i18n.t(errorTranslations[err.errorType]);
        toasti18n.error({ message: errMessage, excludeDefaultTitle: true });
      }
      setIsLoading(false);
    }
  }

  async function handleGoogleLogin(
    googleObject: GoogleCredentialResponse
  ): Promise<void> {
    try {
      const { userInfo } = await login({
        loginWith: "google",
        postBody: {
          idToken: googleObject.credential,
        },
      });
      await userTracking(userInfo);
      await setSentryUser(userInfo);
      navigate((location as any).state?.from?.pathname ?? "/");
    } catch (err) {
      if (err.name === "AuthError") {     
        const errMessage: string = i18n.t(errorTranslations[err.errorType]);
        toasti18n.error({ message: errMessage, excludeDefaultTitle: true });
      }
    }
  }

  return (
    <div className="flex flex-col items-center h-screen bg-navigation">
      <div className="z-10 mt-16 mx-5 flex flex-col items-center w-full box-border sm:mt-5">
        <div className="w-36 mb-8 sm:80 sm:mb-5">
          <Icon name="Vendii" className="w-full h-auto block text-white" />
        </div>
        <div className="flex flex-col max-w-md w-full bg-surface rounded-lg p-10 mx-5 box-border shadow-lg sm:p-5">
          <Typography
            type="headline-6"
            translationKey="login_title"
            className="mb-5"
          />
          {config.GOOGLE_CLIENT_ID && (
            <>
              <div className="flex justify-center">
                <GoogleLogin
                  onSuccess={handleGoogleLogin}
                  onError={() => alert(JSON.stringify("Something went wrong"))}
                />
              </div>
              <div className="flex items-center mb-5">
                <div className="flex-1 border-b border-outline"></div>
                <Typography
                  type="body-1"
                  translationKey="label_or"
                  className="mx-3 text-on-surface-disabled"
                />
                <div className="flex-1 border-b border-outline"></div>
              </div>
            </>
          )}
          <TextField
            value={email}
            onChange={e => setEmail(e.target.value)}
            type="text"
            label="login_email_placeholder"
            className="mb-5"
          />
          <TextField
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
            label="login_password_placeholder"
            className="mb-5"
          />
          <Button
            className="mt-1 mb-2"
            translationKey="login_signin"
            type="primary"
            onClick={handlePasswordLogin}
            disabled={email === "" || password === ""}
            loading={isLoading}
          />
          <hr className="h-px my-3 bg-outline border-0 w-full" />
          <Link to="/forgot-password" className="text-primary font-med hover:underline text-center">{t("forgot_password_title")}</Link>
        </div>
      </div>
      <img
        alt="vending city splash screen"
        className="fixed object-contain bottom-0 w-full tablet:w-auto tablet:left-0 tablet:h-[90%] mobile:h-[50%]"
        src="/images/vendingcity.png"
      />
    </div>
  );
}

export default LoginSection;
