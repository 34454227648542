// extracted by mini-css-extract-plugin
var _1 = "ErrorText--oXHH1SJJbm";
var _2 = "active--Z7kMU907c2";
var _3 = "#f3f3fd";
var _4 = "box--K2EJdyEavu";
var _5 = "#b3b3b3";
var _6 = "#b0b0b0";
var _7 = "#a38d80";
var _8 = "#698cc2";
var _9 = "#33568c";
var _a = "#b5c7d5";
var _b = "#f5dcb3";
var _c = "#f18989";
var _d = "#e95d50";
var _e = "#d7d7d7";
var _f = "error--emJ6Rnhmlv";
var _10 = "#f89389";
var _11 = "errorMessage--zYMZKaD6EA";
var _12 = "field--gRKVtfrTG7";
var _13 = "icon--W19h_gTYd0";
var _14 = "#d36b0b";
var _15 = "label--FmX0BMl4wc";
var _16 = "line--fdBnIfwI0A";
var _17 = "noLineBox--vIkT_RjwrW";
var _18 = "none--c96oh8xZI9";
var _19 = "#1c1c1c";
var _1a = "#fff";
var _1b = "hsla(0,0%,100%,.74)";
var _1c = "rgba(0,0,0,.38)";
var _1d = "rgba(0,0,0,.87)";
var _1e = "rgba(0,0,0,.6)";
var _1f = "1px solid rgba(0,0,0,.12)";
var _20 = "rgba(0,0,0,.12)";
var _21 = "#4b4d9b";
var _22 = "#eeeef7";
var _23 = "#cbcce6";
var _24 = "#8191d9";
var _25 = "#8688c5";
var _26 = "#6466b4";
var _27 = "#4b4d9b";
var _28 = "#3a3c79";
var _29 = "#292b56";
var _2a = "#191a34";
var _2b = "#080911";
var _2c = "#292b56";
var _2d = "#b8e7e3";
var _2e = "#3da49f";
var _2f = "#4cbdb7";
var _30 = "#27b6ba";
var _31 = "#b2d3d3";
var _32 = "#fff";
var _33 = "unit--WDVNBwgbUv";
var _34 = "#ffb025";
var _35 = "#ffedcc";
export { _1 as "ErrorText", _2 as "active", _3 as "background", _4 as "box", _5 as "brainStormingBlackTint300", _6 as "captionDisable", _7 as "chartCoffeeBrown", _8 as "chartCornflowerBlue", _9 as "chartIndigo", _a as "chartLightBlue", _b as "chartPeanutBrown", _c as "chartPink", _d as "critical", _e as "disabled", _f as "error", _10 as "error2", _11 as "errorMessage", _12 as "field", _13 as "icon", _14 as "investigate", _15 as "label", _16 as "line", _17 as "noLineBox", _18 as "none", _19 as "onBackground", _1a as "onPrimaryHigh", _1b as "onPrimaryMedium", _1c as "onSurfaceDisabled", _1d as "onSurfaceHigh", _1e as "onSurfaceMedium", _1f as "outline", _20 as "outlineColor", _21 as "primary", _22 as "primary050", _23 as "primary100", _24 as "primary200", _25 as "primary300", _26 as "primary400", _27 as "primary500", _28 as "primary600", _29 as "primary700", _2a as "primary800", _2b as "primary900", _2c as "primaryVariant", _2d as "secondary", _2e as "secondary700", _2f as "secondaryVariant", _30 as "success", _31 as "successOverlay", _32 as "surface", _33 as "unit", _34 as "warning", _35 as "warningOverlay" }
