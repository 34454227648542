import * as React from "react";
import { RealtimeFailBoardEventType } from "gql/generated";
import { useTranslation } from "react-i18next";

import { Event } from "./useRealTimeQuery";

export function EventStatus({ event }: { event: Event }) {
  const { t, i18n } = useTranslation("maintenance", {
    keyPrefix: "rtFail",
  });

  switch (event.type) {
    case RealtimeFailBoardEventType.Online: {
      return (
        <div className="text-subtitle2 leading-5 text-on-surface-high">
          {t("eventType.online")}
        </div>
      );
    }

    case RealtimeFailBoardEventType.MaintenanceExit: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-on-surface-high">
            {t("eventType.maintenanceExit")}
          </div>

          <div className="text-body2 leading-4 text-on-surface-high mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.ActiveCommand: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-success">
            {t("eventType.activeCommand")}
          </div>

          <div className="text-body2 leading-4 text-success mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.DeactiveCommand: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-error">
            {t("eventType.deactiveCommand")}
          </div>

          <div className="text-body2 leading-4 text-error mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.Open:
    case RealtimeFailBoardEventType.ArriveBy:
    case RealtimeFailBoardEventType.OpenRefill:
    case RealtimeFailBoardEventType.OpenService:
    case RealtimeFailBoardEventType.OpenTank: {
      return (
        <>
          <div className="text-subtitle2 leading-5 text-primary-500">
            {t("eventType.arriveBy")}
          </div>

          <div className="text-body2 leading-4 text-primary-500 mt-[4px]">
            {event.staffName ? event.staffName : t("unknownUser")}
          </div>
        </>
      );
    }

    case RealtimeFailBoardEventType.Information: {
      return !event.description ? (
        <div className="text-subtitle2 leading-5 text-on-surface-high">
          {t("eventType.information")}
        </div>
      ) : (
        <div className="text-body2 leading-5 text-on-surface-high">
          {event.description}
        </div>
      );
    }

    case RealtimeFailBoardEventType.Alarm: {
      let description =
        i18n.language === "th"
          ? event.errorDetail?.descriptionTh
          : event.errorDetail?.descriptionEn;
      if (!description) {
        description = event.errorDetail?.raw;
      }

      return !description ? (
        <div className="text-subtitle2 leading-5 text-on-surface-high">
          {t("eventType.alarm")} {`(${event.errorCode})`}
        </div>
      ) : (
        <>
          <div className="text-subtitle2 leading-5 text-on-surface-high">
            {t("eventType.alarm")} {`(${event.errorCode})`}
          </div>
          <div className="text-body2 leading-4 text-on-surface-high mt-[4px]">
            {description} {event.lastRemain ? `(R-${event.lastRemain})` : null}
          </div>
        </>
      );
    }
    default: {
      return (
        <div className="text-subtitle2 leading-5 text-on-surface-high">
          {String(event.type)}
        </div>
      );
    }
  }
}
