import { Font } from "@react-pdf/renderer";

// Register fonts for usage
Font.register({ family: "Kanit-regular", src: "/fonts/Kanit-Regular.ttf" });
Font.register({ family: "Kanit-medium", src: "/fonts/Kanit-Medium.ttf" });
Font.register({ family: "Kanit-light", src: "/fonts/Kanit-Light.ttf" });

// Load fonts when app starts
Font.load({fontFamily: "Kanit-regular"});
Font.load({fontFamily: "Kanit-medium"});
Font.load({fontFamily: "Kanit-light"});

export const COLORS = {
  onSurfaceMedium: "rgba(0,0,0,0.6)",
  onSurfaceHigh: "rgba(0,0,0,0.87)",
  onSurfaceDisabled: "rgba(0, 0, 0, 0.38)",
  primary500: "rgba(75,77,155,1)",
  outline: "rgba(224,224,224,1)"
};
