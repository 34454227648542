import * as React from "react";

interface IDropDownChevronProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

function DropDownChevron({
  color = "currentcolor",
  ...props
}: IDropDownChevronProps): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.2929 7.29285L20.7071 8.70706L12 17.4142L3.29289 8.70706L4.70711 7.29285L12 14.5857L19.2929 7.29285Z"
        fill={color}
      />
    </svg>
  );
}

export default DropDownChevron;
