import { fetcher } from "gql/fetcher";
import {
  GetMaintenanceBoardErrorCountsDocument,
  GetMaintenanceBoardErrorCountsQuery,
  GetMaintenanceBoardErrorCountsQueryVariables,
} from "gql/generated";

export async function getSummary(serviceZoneIds?: string[], refillZoneIds?: string[]) {
  return await fetcher<
    GetMaintenanceBoardErrorCountsQuery,
    GetMaintenanceBoardErrorCountsQueryVariables
  >(GetMaintenanceBoardErrorCountsDocument, {
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
  })();
}
