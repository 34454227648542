import * as React from "react";

interface IWarehouseProps {
  color?: string,
  className?: string
}

function Warehouse ({
  color = "currentcolor",
  className
}: IWarehouseProps): JSX.Element {
  return (
    <svg className={className} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M17.7594 22.9993H21.3958C22.2804 22.9993 23 22.2797 23 21.3951V8.79095C23 8.56453 22.8882 8.35278 22.703 8.22445L12.3905 1.12028C12.1558 0.958034 11.8451 0.958034 11.6104 1.12028L1.29792 8.22445C1.11183 8.35278 1 8.56453 1 8.79095V21.3951C1 22.2797 1.71958 22.9993 2.60417 22.9993H6.24045C6.2505 22.9997 6.26061 22.9999 6.27078 22.9999H17.7291C17.7393 22.9999 17.7494 22.9997 17.7594 22.9993ZM18.4166 21.6243H21.3958C21.5223 21.6243 21.625 21.5216 21.625 21.3951V9.15212L12 2.52187L2.375 9.15212V21.3951C2.375 21.5216 2.47767 21.6243 2.60417 21.6243H5.58328V18.6458V14.9791V11.7708C5.58328 11.3913 5.89128 11.0833 6.27078 11.0833H17.7291C18.1086 11.0833 18.4166 11.3913 18.4166 11.7708V14.9791V18.6458V21.6243ZM17.0416 17.9583V15.6666H6.95828V17.9583H17.0416ZM6.95828 19.3333H17.0416V21.6243H6.95828V19.3333ZM6.95828 14.2916H17.0416V12.4583H6.95828V14.2916Z' fill={color} />
    </svg>
  );
}

export default Warehouse;
