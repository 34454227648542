import * as React from "react";

interface IReactionsSmiledProps {
  color?: string;
  className?: string;
}

function ReactionsSmiled({
  color = "currentcolor",
  className,
}: IReactionsSmiledProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.99996 15.3334C3.94987 15.3334 0.666626 12.0502 0.666626 8.00008C0.666626 3.94999 3.94987 0.666748 7.99996 0.666748C12.05 0.666748 15.3333 3.94999 15.3333 8.00008C15.3333 12.0502 12.05 15.3334 7.99996 15.3334ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM11.2737 10.2989L10.1831 9.5319C9.68622 10.2384 8.87969 10.6667 8.00003 10.6667C7.12036 10.6667 6.31383 10.2384 5.81692 9.5319L4.72632 10.2989C5.47033 11.3568 6.68166 12 8.00003 12C9.3184 12 10.5297 11.3568 11.2737 10.2989ZM9.33333 7.33333V6H10.6667V7.33333H9.33333ZM5.33333 6V7.33333H6.66667V6H5.33333Z"
        fill={color}
      />
    </svg>
  );
}

export default ReactionsSmiled;
