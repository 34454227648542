import * as React from "react";
import { Avatar, Dialog, DialogContent, DialogTitle } from "@mui/material";
import { clsx } from "clsx";
import TextField from "components/TextField";
import { useResponsive } from "hooks/useResponsive";
import { useTranslation } from "react-i18next";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import { useGetOrganisations } from "userandauth/hooks/useGetOrganisations";
import { patchUser } from "userandauth/servicesUser";
import analytics from "utils/analytics";

import Icon from "../icon/Icon";

type OrgSwitcherModalProps = {
  open: boolean;
  onClose?: () => void;
};

export function OrgSwitcherModal({ open, onClose }: OrgSwitcherModalProps) {
  const { t } = useTranslation("translation", {
    keyPrefix: "OrganisationSwitcher"
  });
  const { isMobile } = useResponsive();
  const { data } = useGetOrganisations();
  const { data: userInfo } = useGetCurrentUser();
  const [isPatchLoading, setIsPatchLoading] = React.useState<null | string>(
    null
  );
  const [search, setSearch] = React.useState("");

  const allOrganisation = React.useMemo(() => {
    return (data || [])
      ?.filter((org) => org?.name?.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase()))
      ?.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
  }, [data, search]);

  async function onChangeUserDetails(value: string) {
    try {
      setIsPatchLoading(value);
      analytics.track({
        name: "Organisation Patch Start",
        properties: {
          organisationId: value,
          organisationName: data?.find(org => org.id === value).name
        }
      });
      await patchUser(
        {
          organisationId: value,
        },
        userInfo.userId
      );
      analytics.track({
        name: "Organisation Patch Success",
        properties: {
          organisationId: value,
          organisationName: data?.find(org => org.id === value).name
        }
      });
      location.reload();
    } catch {
      setIsPatchLoading(null);
      analytics.track({
        name: "Organisation Patch Request Fail",
        properties: {
          organisationId: value,
          organisationName: data?.find(org => org.id === value).name
        }
      });
    }
  }

  return (
    <Dialog fullScreen={isMobile} scroll="paper" onClose={onClose} open={open}>
      <div onClick={() => onClose?.()} className="absolute w-7 h-7 flex items-center justify-center top-6 right-6 cursor-pointer hover:bg-background rounded-lg">
        <Icon name="Close" className="w-6 h-6" color="onSurfaceHigh" />
      </div>
      <DialogTitle className="px-6 py-4" component="div">
        <p className="font-kanit mb-6 text-h6">{t("label_title")}</p>
        <div>
          <TextField
            label={t("label_search")}
            variant="outlined"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
            autoFocus
          />
        </div>
      </DialogTitle>
      <DialogContent dividers className="min-w-[400px]">
        <div className="min-h-[300px]">
          {allOrganisation.length === 0 && (
            <div>
              <p className="text-on-surface-medium text-body1 p-6 text-center">{t("label_not_found")}</p>
            </div>
          )}
          {allOrganisation?.map((org) => (
            <div
              key={org.id}
              className={clsx(
                "p-2 h-6 flex gap-3 items-center hover:bg-background rounded-lg",
                isPatchLoading ? "cursor-none" : "cursor-pointer"
              )}
              onClick={() => onChangeUserDetails(org.id)}
            >
              <Avatar
                src={org.logoUrl}
                className="w-6 h-6 bg-overlay-surface-08"
              >
                <Icon
                  name="Building"
                  className="w-[10px] h-[10px]"
                  color="onSurfaceDisabled"
                />
              </Avatar>
              <p className="font-kanit text-body2 font-light flex-1">
                {org.name}
              </p>
              {isPatchLoading === org.id && (
                <Icon
                  name="loader"
                  color="primary"
                  className="w-4 h-4 animate-spin"
                />
              )}
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
