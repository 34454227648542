import { AxiosError } from "axios";

type GraphQLErrorResponse = { errors: Array<{ message: string }> };

export function serializeError(error: AxiosError | Error): string {
  if ((error as AxiosError<GraphQLErrorResponse>).response?.data?.errors)
    return (error as AxiosError<GraphQLErrorResponse>).response.data.errors[0]
      .message;

  return (
    (error as AxiosError).response?.data?.message ||
    (error as AxiosError).response?.data ||
    error.message
  );
}
