import * as React from "react";

interface ILightningProps {
  color?: string;
  className?: string;
}

function Lightning ({
  color = "currentcolor",
  className
}: ILightningProps): JSX.Element {
  return (
    <svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.919373 14H6V21.8508L17.0806 8.00001H12V0.149231L0.919373 14ZM8 12H5.08062L10 5.85079V10H12.9194L8 16.1492V12Z"
        fill={color}
      />
    </svg>
  );
}

export default Lightning;
