import * as React from "react";
import { clsx } from "clsx";
import Icon from "common/components/icon/Icon";

import * as styles from "./ScrollContainer.module.scss";

export function ScrollContainer({ children }: { children: React.ReactNode }) {
  const endRef = React.useRef<HTMLDivElement>();
  const startRef = React.useRef<HTMLDivElement>();
  const [isEnd, setIsEnd] = React.useState(true);
  const [isStart, setIsStart] = React.useState(true);
  const scrollContainer = React.useRef<HTMLDivElement>();

  function scrollToEnd() {
    endRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }

  function scrollToStart() {
    startRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }

  React.useEffect(function observeEndRef() {
    const endObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsEnd(true);
        } else {
          setIsEnd(false);
        }
      },
      { threshold: 1, root: scrollContainer.current, rootMargin: "30px" }
    );
    const startObserver = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsStart(true);
        } else {
          setIsStart(false);
        }
      },
      { threshold: 1, root: scrollContainer.current, rootMargin: "30px" }
    );
    startObserver.observe(startRef.current);
    endObserver.observe(endRef.current);

    return () => {
      if(endRef.current){
        endObserver.unobserve(endRef.current);
      }
      if(startRef.current){
        startObserver.unobserve(endRef.current);
      }
      endObserver.disconnect();
      startObserver.disconnect();
    };
  }, []);

  return (
    <div className={clsx(styles.ScrollContainer, "relative")}>
      <div
        className={clsx(styles.ErrorBoardTableTabsContainer)}
        ref={scrollContainer}
      >
        <div ref={startRef} />

        {children}

        <div ref={endRef} />
      </div>
      <button
        onClick={scrollToStart}
        disabled={isStart}
        className={clsx(styles.ScrollButton, "w-8 left-0 rounded-br-lg rotate-180 absolute bg-white h-full")}
        style={{
          cursor: isStart ? "default" : "pointer",
          visibility: isStart ? "hidden": "visible"
        }}
      >
        <Icon
          style={{
            opacity: isStart ? 0 : 1,
            transform: isStart ? "translateX(-5px)" : "translateX(0)",
            transition: "all 0.1s",
            transitionTimingFunction: isStart ? "ease-in" : "ease-out",
          }}
          name="ChevronsRight"
          color="primary500"
          className={styles.ScrollIcon}
        />
      </button>
      <button
        onClick={scrollToEnd}
        disabled={isEnd}
        className={clsx(styles.ScrollButton,"w-8 right-0 rounded-tr-lg absolute bg-white h-full")}
        style={{
          cursor: isEnd ? "default" : "pointer",
          visibility: isEnd ? "hidden": "visible"
        }}
      >
        <Icon
          style={{
            opacity: isEnd ? 0 : 1,
            transform: isEnd ? "translateX(-5px)" : "translateX(0)",
            transition: "all 0.1s",
            transitionTimingFunction: isEnd ? "ease-in" : "ease-out",
          }}
          name="ChevronsRight"
          color="primary500"
          className={styles.ScrollIcon}
        />
      </button>
    </div>
  );
}
