import { fetcher } from "gql/fetcher";
import { GetMaintenanceBoardP5ErrorDocument, GetMaintenanceBoardP5ErrorQuery, GetMaintenanceBoardP5ErrorQueryVariables, MaintenanceBoardSortInput } from "gql/generated";

export async function getP5({
  serviceZoneIds,
  refillZoneIds,
  cursor,
  sort,
}: {
  serviceZoneIds?: string[];
  refillZoneIds?: string[];
  cursor?: string;
  sort?: MaintenanceBoardSortInput;
}) {
  return fetcher<
    GetMaintenanceBoardP5ErrorQuery,
    GetMaintenanceBoardP5ErrorQueryVariables
  >(GetMaintenanceBoardP5ErrorDocument, {
    after: cursor,
    serviceZoneIds: serviceZoneIds,
    refillZoneIds: refillZoneIds,
    sort: sort,
  })();
}
