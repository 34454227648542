import { IAction } from "common/interfaces";

interface IPendingRequestState {
  [request: string]: boolean
}

const initialState: IPendingRequestState = {};

function pendingRequestReducer (
  state: IPendingRequestState = initialState,
  action: IAction
): IPendingRequestState {
  const [ domain, request, result ] = action.type.split("/");

  if (result === "REQUEST") {
    return {
      ...state,
      [`${domain}/${request}`]: action.payload
    };
  }

  return state;
}

export default pendingRequestReducer;
