import * as React from "react";

interface IReactionsSadProps {
  color?: string;
  className?: string;
}

function ReactionsSad({
  color = "currentcolor",
  className,
}: IReactionsSadProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.99996 15.3334C3.94987 15.3334 0.666626 12.0502 0.666626 8.00008C0.666626 3.94999 3.94987 0.666748 7.99996 0.666748C12.05 0.666748 15.3333 3.94999 15.3333 8.00008C15.3333 12.0502 12.05 15.3334 7.99996 15.3334ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM8.00003 10.8652C8.87969 10.8652 9.68622 11.2935 10.1831 12L11.2737 11.233C10.5297 10.1751 9.3184 9.5319 8.00003 9.5319C6.68166 9.5319 5.47033 10.1751 4.72632 11.233L5.81692 12C6.31383 11.2935 7.12036 10.8652 8.00003 10.8652ZM9.33333 8V6.66667H10.6667V8H9.33333ZM5.33333 6.66667V8H6.66667V6.66667H5.33333Z"
        fill={color}
      />
    </svg>
  );
}

export default ReactionsSad;
