import * as React from "react";
import {
  Button as MuiButton,
  ButtonTypeMap,
  CircularProgress,
  ExtendButtonBaseTypeMap,
} from "@mui/material";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import Icon from "common/components/icon/Icon";
import Typography from "components/Typography";

import * as styles from "./Button.module.scss";

interface IButton
  extends Omit<
    DefaultComponentProps<ExtendButtonBaseTypeMap<ButtonTypeMap<object, "button">>>,
    "type" | "endIcon" | "startIcon"
  > {
  type: "primary" | "secondary" | "status";
  iconName?: string;
  loading?: boolean;
  children?: string | React.ReactNode;
  nowrap?: boolean;
}

export default function Button({
  type,
  className = "",
  loading = false,
  disabled,
  iconName,
  children,
  nowrap = false,
  size = "medium",
  ...rest
}: IButton): JSX.Element {
  return (
    <>
      <MuiButton
        {...rest}
        disabled={disabled || loading}
        style={{
          display: "flex",
          alignItems: "center",
          ...(rest?.style ?? {}),
        }}
        className={[
          disabled && !loading
            ? styles.Disabled
            : type === "primary"
              ? styles.Primary
              : type === "secondary"
                ? styles.Secondary
                : type === "status"
                  ? styles.Status
                  : "",
          styles[size],
          className,
        ].join(" ")}
      >
        {iconName && (
          <div style={{ flexShrink: 0, display: "flex", alignItems: "center" }}>
            <Icon
              name={iconName}
              color={
                type === "primary" || type === "status"
                  ? "onPrimaryHigh"
                  : "primary500"
              }
              className={[loading ? styles.IconLoading : "", styles.Icon].join(
                " "
              )}
            />
          </div>
        )}
        <div
          style={
            nowrap
              ? {
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }
              : undefined
          }
        >
          <Typography
            translate
            type="button"
            className={[styles.Label, loading ? styles.LabelLoading : ""].join(
              " "
            )}
          >
            {children}
          </Typography>
        </div>

        {loading && (
          <CircularProgress size={24} className={styles.LoadingIcon} />
        )}
      </MuiButton>
    </>
  );
}
