import * as React from "react";

interface ICashProps {
  color?: string;
  className?: string;
}

function Cash({ color = "currentcolor", className }: ICashProps): JSX.Element {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1853_6510)">
        <path
          d="M15.75 0.6875C11.751 0.6875 7.5 1.892 7.5 4.125V6.875C7.5 7.2545 7.836 7.5625 8.25 7.5625C8.664 7.5625 9 7.2545 9 6.875V6.15863C10.5915 7.0785 13.2195 7.5625 15.75 7.5625C18.2805 7.5625 20.9085 7.0785 22.5 6.15863V6.875C22.5 7.54737 20.655 8.61987 17.187 8.88525C16.776 8.9155 16.4685 9.24962 16.503 9.62775C16.5345 9.98662 16.863 10.2575 17.2485 10.2575C17.271 10.2575 17.2905 10.2561 17.313 10.2548C19.266 10.1049 21.2115 9.6415 22.5 8.88938V9.625C22.5 10.2121 21.141 11.0564 18.6195 11.4648C18.213 11.5308 17.94 11.8882 18.012 12.2609C18.0765 12.5936 18.393 12.8287 18.7485 12.8287C18.792 12.8287 18.837 12.826 18.8805 12.8177C20.406 12.5716 21.627 12.1687 22.5 11.6572V12.375C22.5 12.9621 21.141 13.8064 18.6195 14.2148C18.213 14.2808 17.94 14.6382 18.012 15.0109C18.0765 15.3436 18.393 15.5787 18.7485 15.5787C18.792 15.5787 18.837 15.576 18.8805 15.5677C20.406 15.3216 21.627 14.9174 22.5 14.4072V15.125C22.5 15.7121 21.141 16.5564 18.6195 16.9648C18.213 17.0308 17.94 17.3882 18.012 17.7609C18.0765 18.0936 18.393 18.3287 18.7485 18.3287C18.792 18.3287 18.837 18.326 18.8805 18.3177C20.406 18.0716 21.627 17.6674 22.5 17.1572V17.875C22.5 18.5474 20.655 19.6199 17.187 19.8852C16.776 19.9155 16.4685 20.2496 16.503 20.6277C16.5345 20.9866 16.863 21.2575 17.2485 21.2575C17.271 21.2575 17.2905 21.2561 17.313 21.2548C20.6415 20.999 24 19.8564 24 17.875V4.125C24 1.892 19.749 0.6875 15.75 0.6875ZM15.75 6.1875C11.6295 6.1875 9 4.96513 9 4.125C9 3.28487 11.6295 2.0625 15.75 2.0625C19.8705 2.0625 22.5 3.28487 22.5 4.125C22.5 4.96513 19.8705 6.1875 15.75 6.1875Z"
          fill={color}
          fillOpacity="1"
        />
        <path
          d="M8.25 8.9375C4.251 8.9375 0 10.142 0 12.375V17.875C0 20.108 4.251 21.3125 8.25 21.3125C12.249 21.3125 16.5 20.108 16.5 17.875V12.375C16.5 10.142 12.249 8.9375 8.25 8.9375ZM15 17.875C15 18.7151 12.3705 19.9375 8.25 19.9375C4.1295 19.9375 1.5 18.7151 1.5 17.875V17.1586C3.0915 18.0785 5.7195 18.5625 8.25 18.5625C10.7805 18.5625 13.4085 18.0785 15 17.1586V17.875ZM15 15.125C15 15.9651 12.3705 17.1875 8.25 17.1875C4.1295 17.1875 1.5 15.9651 1.5 15.125V14.4086C3.0915 15.3285 5.7195 15.8125 8.25 15.8125C10.7805 15.8125 13.4085 15.3285 15 14.4086V15.125ZM8.25 14.4375C4.1295 14.4375 1.5 13.2151 1.5 12.375C1.5 11.5349 4.1295 10.3125 8.25 10.3125C12.3705 10.3125 15 11.5349 15 12.375C15 13.2151 12.3705 14.4375 8.25 14.4375Z"
          fill={color}
          fillOpacity="1"
        />
      </g>
      <defs>
        <clipPath id="clip0_1853_6510">
          <rect width="24" height="22" fill="red" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Cash;
