import * as React from "react";
import withSuspense from "common/hoc/withSuspense";
import * as SCSScolors from "common/styles/colors.module.scss";
import { useTranslation } from "react-i18next";

import * as styles from "./Typography.module.scss";

interface ITypography {
  children: React.ReactNode;
  type:
    | "headline-1"
    | "headline-2"
    | "headline-3"
    | "headline-4"
    | "headline-5"
    | "headline-6"
    | "subtitle-1"
    | "subtitle-2"
    | "body-1"
    | "body-2"
    | "body-3"
    | "button"
    | "caption"
    | "overline"
    | "emphasized";
  color?: string;
  translate?: boolean;
  className?: string;
}

function Typography({
  type,
  color = "",
  translate = false,
  className = "",
  children,
}: ITypography) {
  const { t } = useTranslation();

  function recursiveTranslate(children: React.ReactNode) {
    if (typeof children === "string" && children.includes("_")) {
      return t(children);
    }
    if (Array.isArray(children)) {
      return children.map((child) => {
        return recursiveTranslate(child);
      });
    }
    return children;
  }

  return (
    <span
      className={[
        type === "headline-1" ? styles.Headline_1 : "",
        type === "headline-2" ? styles.Headline_2 : "",
        type === "headline-3" ? styles.Headline_3 : "",
        type === "headline-4" ? styles.Headline_4 : "",
        type === "headline-5" ? styles.Headline_5 : "",
        type === "headline-6" ? styles.Headline_6 : "",
        type === "subtitle-1" ? styles.Subtitle_1 : "",
        type === "subtitle-2" ? styles.Subtitle_2 : "",
        type === "body-1" ? styles.Body_1 : "",
        type === "body-2" ? styles.Body_2 : "",
        type === "body-3" ? styles.Body_3 : "",
        type === "button" ? styles.Button : "",
        type === "caption" ? styles.Caption : "",
        type === "overline" ? styles.Overline : "",
        type === "emphasized" ? styles.Emphasized : "",
        className,
      ].join(" ")}
      style={{
        color: SCSScolors[color] ?? SCSScolors["onSurfaceHigh"],
      }}
    >
      {translate ? recursiveTranslate(children) : children}
    </span>
  );
}

export default React.memo(withSuspense(Typography));
