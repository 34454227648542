import * as React from "react";

type IChevronsRightProps = {
  color?: string;
} & React.SVGProps<SVGSVGElement>;

function ChevronsRight({ color = "currentcolor", ...rest }: IChevronsRightProps): JSX.Element {
  return (
    <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M2.29297 4.70712L5.58586 8.00001L2.29297 11.2929L3.70718 12.7071L8.41429 8.00001L3.70718 3.29291L2.29297 4.70712ZM7.29297 4.70712L10.5859 8.00001L7.29297 11.2929L8.70718 12.7071L13.4143 8.00001L8.70718 3.29291L7.29297 4.70712Z"
      fill={color}
    />
  </svg>
  );
}


export default ChevronsRight;
