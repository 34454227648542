import * as React from "react";
import { Dialog } from "@mui/material";
import Button from "components/Button";
import { useRebootMachineMutation } from "gql/generated";
import { useTranslation } from "react-i18next";
import { toasti18n } from "utils/toast";
import Icon from "../icon/Icon";

export function RestartMachineConfirmationModal({
  isOpen,
  setIsOpen,
  machineId,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  machineId: string;
}) {
  const { t } = useTranslation();
  const reboot = useRebootMachineMutation({
    onError: (error: Error) => {
      toasti18n.error(error);
    },
    onSuccess: () => {
      toasti18n.success();
      setIsOpen(false);
    },
  });

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
      <div className="bg-white px-[30px] pb-[22px] pt-6 w-[80vw] max-w-[420px] box-border">
        <div className="flex gap-[5px] items-center">
          <Icon
            name="RotateCW"
            color="currentColor"
            className="text-primary-500 size-6"
          />
          <span className="text-h6 leading-6">
            {t("label_restart_machine")} : {machineId}
          </span>
        </div>
        <div className="flex items-center justify-between mt-5">
          <Button
            type="secondary"
            onClick={() => setIsOpen(false)}
            disabled={reboot.isLoading}
            size="small"
          >
            {t("action_cancel")}
          </Button>
          <Button
            type="primary"
            size="small"
            loading={reboot.isLoading}
            onClick={() =>
              reboot.mutate({
                machineId,
              })
            }
          >
            {t("action_confirm")}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
