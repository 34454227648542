import * as React from "react";
import Typography from "components/Typography";

import * as styles from "./Fallback.module.scss";


export function Fallback({ error }: { error: Error; }) {
  return (
    <div className={styles.Fallback}>
      <div style={{ textAlign: "center", maxWidth: "80%" }}>
        <Typography color="error" translate type="headline-6">
          label_an_error_has_occured
        </Typography>

        <div>
          <Typography color="error" translate type="caption">
            {error.message}
          </Typography>
        </div>
      </div>
    </div>
  );
}
