import { IAction } from "common/interfaces";
import { IProduct } from "product/interfaces";

interface IProductState {
  [productId: string]: IProduct
}

const initialState: IProductState = {};

function productReducer (
  state: IProductState = initialState,
  action: IAction
): IProductState {
  const [ domain, request, result ] = action.type.split("/");

  if (domain === "PRODUCTS") {
    if (request.includes("GET_") && result === "SUCCESS") {
      return { ...state, ...action.payload };
    }
    if (request.includes("CREATE") && result === "SUCCESS") {
      return { ...state, ...action.payload };
    }
    if (request.includes("EDIT") && result === "SUCCESS") {
      return { ...state, ...action.payload };
    }
  }

  return state;
}

export default productReducer;
