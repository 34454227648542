import * as React from "react";

interface IArrowPriorityHighestProps {
  color?: string;
  className?: string;
}

function ArrowPriorityHighest({ className }: IArrowPriorityHighestProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Priority / highest 1">
        <path
          id="Vector"
          d="M3.47876 7.8998C2.97876 8.1998 2.37876 7.9998 2.07876 7.4998C1.77876 6.9998 1.97876 6.4998 2.47876 6.1998L7.47876 3.1998C7.77876 2.9998 8.17876 2.9998 8.47876 3.1998L13.4788 6.1998C13.9788 6.4998 14.0788 7.0998 13.7788 7.5998C13.5788 7.9998 12.9788 8.1998 12.4788 7.8998L7.97876 5.1998L3.47876 7.8998Z"
          fill="#E95D50"
        />
        <path
          id="Vector_2"
          d="M3.47876 12.2001C2.97876 12.5001 2.37876 12.4001 2.07876 11.9001C1.77876 11.4001 1.97876 10.8001 2.47876 10.5001L7.47876 7.5001C7.77876 7.3001 8.17876 7.3001 8.47876 7.5001L13.4788 10.5001C13.9788 10.8001 14.0788 11.4001 13.7788 11.9001C13.4788 12.4001 12.8788 12.5001 12.3788 12.2001L7.97876 9.5001L3.47876 12.2001Z"
          fill="#F89389"
        />
      </g>
    </svg>
  );
}

export default ArrowPriorityHighest;
