import { IAction } from "common/interfaces";
import { omit } from "lodash";
import { IRefillOrder } from "refillorders/interfaces";

interface IRefillOrderState {
  [orderNumber: string]: IRefillOrder
}

const initialState: IRefillOrderState = {};

function refillOrderReducer (
  state: IRefillOrderState = initialState,
  action: IAction
): IRefillOrderState {
  if (action.type === "refillOrder/modify") {
    return { ...state, ...action.payload };
  }

  const [ domain, request, result ] = action.type.split("/");

  if (domain === "REFILL_ORDER") {
    if (request.includes("GET_") && result === "SUCCESS") {
      return { ...state, ...action.payload };
    }
    if (request.includes("CREATE") && result === "SUCCESS") {
      return { ...state, ...action.payload };
    }
    if (request.includes("PATCH_") && result === "SUCCESS") {
      return { ...state, ...action.payload };
    }
    if (request.includes("DISMISS") && result === "SUCCESS") {
      return omit(state, [action.payload.id]);
    }
    if (request.includes("CANCEL") && result === "SUCCESS") {
      return omit(state, [action.payload.id]);
    }
  }

  return state;
}

export default refillOrderReducer;
