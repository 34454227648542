export const SERVICE_ZONE_QUERY_PARAM = "id";

const BASE_ROOT = "/maintenance";
const ERROR_BOARD_BASE = `${BASE_ROOT}/error-board`;
const RT_FAIL = `${BASE_ROOT}/rt-fail`;
const MAJOR_TROUBLE_BASE = `${ERROR_BOARD_BASE}/major-trouble`;
const INVENTORY_BASE = `${ERROR_BOARD_BASE}/inventory`;
const TRANSACTIONS_BASE = `${ERROR_BOARD_BASE}/transactions`;

export const routes = {
  root: `${BASE_ROOT}`,
  errorBoard: `${ERROR_BOARD_BASE}`,
  rtFail: `${RT_FAIL}`,
  majorTrouble: {
    root: `${MAJOR_TROUBLE_BASE}`,
    machineUnsellable: `${MAJOR_TROUBLE_BASE}/machine-unsellable`,
    outOfService: `${MAJOR_TROUBLE_BASE}/out-of-service`,
    offline: `${MAJOR_TROUBLE_BASE}/offline`,
    iceError: `${MAJOR_TROUBLE_BASE}/ice-error`,
    coffeeError: `${MAJOR_TROUBLE_BASE}/coffee-error`,
    iceRateLow: `${MAJOR_TROUBLE_BASE}/ice-rate-low`,
    sodaError: `${MAJOR_TROUBLE_BASE}/soda-error`,
    whipper: `${MAJOR_TROUBLE_BASE}/whipper`,
    spiralUnsellable: `${MAJOR_TROUBLE_BASE}/spiral-unsellable`,
  },
  inventory: {
    root: `${INVENTORY_BASE}`,
    milk: `${INVENTORY_BASE}/milk`,
    sugar: `${INVENTORY_BASE}/sugar`,
    lid: `${INVENTORY_BASE}/lid`,
    other: `${INVENTORY_BASE}/other`,
    coffeeBeans: `${INVENTORY_BASE}/coffee-beans`,
    plasticCups: `${INVENTORY_BASE}/plastic-cups`,
    paperCups: `${INVENTORY_BASE}/paper-cups`,
    espressoCups: `${INVENTORY_BASE}/espresso-cups`,
    co2: `${INVENTORY_BASE}/co2`,
    p1: `${INVENTORY_BASE}/p1`,
    p4: `${INVENTORY_BASE}/p4`,
    p5: `${INVENTORY_BASE}/p5`,
    p10: `${INVENTORY_BASE}/p10`,
    p11: `${INVENTORY_BASE}/p11`,
    s2: `${INVENTORY_BASE}/s2`,
    s3: `${INVENTORY_BASE}/s3`,
    s5: `${INVENTORY_BASE}/s5`,
    sm1: `${INVENTORY_BASE}/sm1`,
    sm2: `${INVENTORY_BASE}/sm2`,
    otherPowder: `${INVENTORY_BASE}/other-powder`,
    otherSyrup: `${INVENTORY_BASE}/other-syrup`
  },
  transactions: {
    root: `${TRANSACTIONS_BASE}`,
    aaNoTransaction30Mins: `${TRANSACTIONS_BASE}/aa-no-transaction-30-mins`,
    noTransaction2Hours: `${TRANSACTIONS_BASE}/no-transaction-2-hours`,
    noTransactionToday: `${TRANSACTIONS_BASE}/no-transaction-today`,
    billAndCoin: `${TRANSACTIONS_BASE}/bill-and-coin`,
  },
};
